// targetNode: c'est l'element qui contient le drawer ---> ID du drawer
// open: c'est une fonction qui permet de changer l'etat du drawer
// cette fonction permet de detecter la position du drawer et de changer l'etat du drawer en fonction de sa position
import { isPayzenModule } from './Validator';
import { getOrderAmount } from './PriceUtils';
import { formatPriceWithEuro } from './StringUtils';

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export const PositionDetector = async (targetNode, open, store, cart) => {
  if (!targetNode) return;

  const setFormConfigDebounced = debounce(async (config) => {
    if(isPayzenModule(store)){
      if (config === 'paymentToken') {
      // await window?.KR?.setFormConfig({
      //                                   formToken: JSON.parse(localStorage.getItem('token'))?.paymentToken,
      //                                   'kr-language': 'fr-FR',
      //
      //                                 });
      window?.KR?.setFormToken(JSON.parse(localStorage.getItem('token'))?.paymentToken)


    } else {

      // await window?.KR?.setFormToken(JSON.parse(localStorage.getItem('token'))?.formToken)
      // window?.KR.onFormReady(() => {
      //   const paymentButton = document.querySelector('#cbpopup .kr-payment-button');
      //   const container = document.querySelector('#cbpopup .kr-embedded');
      //   const orderAmount = formatPriceWithEuro(getOrderAmount(cart));
      //   if (container && !container.querySelector('#labelCheckbox')) {
      //
      //   // Création du label
      //   const label = document.createElement('label');
      //   label.id= "labelCheckbox"
      //   label.htmlFor = 'cbcheckbox';
      //   const checkbox = document.createElement('input');
      //   checkbox.type = 'checkbox';
      //   checkbox.id = 'cbcheckbox';
      //   // Création du span pour le style
      //   const span = document.createElement('span');
      //   span.className = 'checkbox';
      //   const text = document.createElement('p');
      //   text.textContent = 'Enregistrer pour la prochaine fois';
      //   // Ajou.. du checkbox
      //   label.appendChild(checkbox);
      //   label.appendChild(span);
      //   label.appendChild(text);
      //       container.appendChild(label);
      //   }
      //
      //   if (paymentButton) {
      //       paymentButton.innerHTML = `<p>Payer maintenant</p> ${orderAmount}`;
      //   }
    // }
    //   );
    

      // )

    }
    }

  }, 100);

  try {
    const observer = new MutationObserver(async (mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          const cssText = targetNode.style.cssText;
          const cssstyleObj = cssText.split(';').reduce((acc, item) => {
            const [key, value] = item.split(':');
            if (key && value) {
              acc[key.trim()] = value.trim();
            }
            return acc;
          }, {});

          if (cssstyleObj['--rsbs-overlay-h']) {
            if (parseInt(cssstyleObj['--rsbs-overlay-h'].split('px')[0]) < 100) {
              setFormConfigDebounced('paymentToken');
              setTimeout(() => {
                open(false);
              }, 100);
            } else {
              setFormConfigDebounced('formToken');
              open(true);
            }
          }
        }
      }
    });

    observer.observe(targetNode, {
      attributes: true,
      attributeFilter: ['style'],
    });

    // Cleanup the observer
    return () => {
      observer.disconnect();
    };
  } catch (e) {
    console.log(e);
  }
};
