import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NumberScreen from '../account/NumberScreen';
import { useNavigate } from 'react-router';
import { HOME, SET_ACCOUNT } from '../../constants/routes';
import FavoriteStore from '../account/setAccout/FavoriteStore';
import PushNotifications from '../account/setAccout/PushNotifications';
import PaymentConfig from './PaymentConfig';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { findUser } from '../../actions/userActions';
import useAxiosInterceptors from '../../axios/useAxios';
import { getStore } from '../../actions/storeActions';
import succesConfig from '../../styles/lottie/succesConfig.json';
import clap from '../../styles/lottie/clap.json';
import Lottie from 'lottie-react';
import PrimaryButton from '../../components/button/PrimaryButton';
import pikkoConfig from '../../styles/img/pikkoConfig.svg';
const ConfigPage = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInterceptors()
  const user = useSelector((state) => state.user);
  const { store } = useSelector((state) => state.store);
  const [activeTab, setActiveTab] = useState(1);
  const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const [userLoaded, setUserLoaded] = useState(false);
  const [storeLoaded, setStoreLoaded] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    console.log(searchParams)
    const stepFromUrl = parseInt(searchParams.get('step'), 10);
    console.log(stepFromUrl)
    if (stepFromUrl && !isNaN(stepFromUrl)) {
      setActiveTab(stepFromUrl);
    }
  }, [window.location.search]);
  useEffect(() => {
    if (user && user._id && !userLoaded) {
      dispatch(findUser(user, axiosInstance, navigate));
      setUserLoaded(true);
    }
  }, [user, axiosInstance, dispatch, navigate, userLoaded]);

  useEffect(() => {
    if (user && user.favoriteStore && !storeLoaded) {
      dispatch(getStore(user.favoriteStore, axiosInstance));
      setStoreLoaded(true);
      // handleTabChange(2);
      setActiveTab(2)
      navigate(`?step=2&method=selectPaymentMethod`, { replace: false });
    }
  }, [user, axiosInstance, dispatch, storeLoaded]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`?step=${tab}`, { replace: false });
  };

  const EndConfigPage = () => {
    return (
      <div className="flex flex-col items-center justify-start pt-[40%] appear h-full">
        <Lottie
          animationData={succesConfig}
          style={{ width: '150px', height: 'auto' }}
          loop={false}
        />
        <div className="config-title">
          Bravo!
          <Lottie
            animationData={clap}
            style={{ width: '50px', height: 'auto' }}
            loop={true}
          />

        </div>
        <div 
        className="config-subtitle text-center max-w-[300px]"
        >Tu as terminé la configuration de ton compte</div>

       <div className='fixed bottom-10 w-full px-6 ' >
        <img src={pikkoConfig} alt="pikkoConfig" className="pikkoConfig" />
        <div className='w-full relative z-10'>
        <PrimaryButton onClick={() => navigate(HOME)} label={"Terminer"} />

        </div>
        </div> 
      </div>
    )
  }
  const manageRender = () => {
    if (!user || !user._id) {
      return (
        <>
          <NumberScreen allowOtherLoginMethod={false} />
          <button className="absolute top-3/4">Configurer plus tard</button>
        </>
      )
    } else {
      return (
        <div className="absolute flex flex-col w-screen h-full overflow-hidden bg-gradient2 set-account-screen">
         {activeTab<3 && <div className="tabs mb-[0.5rem]">
            <div className={activeTab >= 1 ? 'active' : ''} />
            <div className={activeTab >= 2 ? 'active' : ''} />
            <div className={activeTab >= 3 ? 'active' : ''} />
          </div>}
          <div className='config-title'>
            {activeTab === 1 && 'Choisis ton magasin'}
          </div>
          <div className='config-subtitle'>
            {activeTab === 1 && 'Sélectionne ton magasin préféré'}
          </div>
          {activeTab === 1 && <FavoriteStore setActiveTab={handleTabChange} />}
          {activeTab === 2 && store && store.paymentGateway && (
            <>
              {store.paymentGateway === 'stripe' && (
                <Elements stripe={stripe}>
                  <PaymentConfig user={user} store={store} handleTabChange={handleTabChange} />
                </Elements>
              )}
              {store.paymentGateway === 'payzen' && (
                <>
                  <PaymentConfig user={user} store={store} handleTabChange={handleTabChange} />
                </>
              )}
            </>
          )}

          {activeTab === 3 && <EndConfigPage />}
        </div>
      )

    }
    return null
  }
  return (
    <div className="flex justify-center">
      {manageRender()}

    </div>
  );
};

export default ConfigPage;