import React, { useEffect, useState } from 'react';
import { formatPriceWithEuro, getUserEmail, getUserPhoneNumber } from '../../utils/StringUtils';
import { getOrderAmount } from '../../utils/PriceUtils';
import { isTRissuerExcluded } from '../../utils/Validator';
import { notifyError } from '../../utils/Notify';
import { useCheckout } from '../../context/checkoutProvider';
import { addCreditCard } from '../../actions/userCbActions';
import { useDispatch } from 'react-redux';
import securePayment from '../../styles/img/account/securePayment.svg';

const PayzenCreditCard = ({ user, axiosInstance, handleSelect, store, openPaymentConfig }) => {
  // const { dispatchCheckout } = useCheckout();
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState(undefined);
  const initializePayzenListeners = async (email, token) => {
    await window.KR.setFormConfig({ formToken: token, 'label-do-register': 'Save' });
    // window.KR.onError(error => {
    //   const excludedErrorCodes = ['CLIENT_101'];
    //   if (error.errorCode && !excludedErrorCodes.includes(error.errorCode)) {
    //     if (error.errorCode === 'CLIENT_309' && error.field === 'cardHolderMail') onPayzenError('Informations email incorrectes')
    //     else if (error?.paymentMethod !== 'APPLE_PAY') onPayzenError(error.detailedErrorMessage);
    //     else if (error?.metadata?.answer?.clientAnswer?.transactions?.[0]?.transactionDetails?.cardDetails?.issuerName && isTRissuerExcluded(error?.metadata?.answer?.clientAnswer?.transactions?.[0]?.transactionDetails?.cardDetails?.issuerName)) setTRpopup(true)
    //     else notifyError(error.errorMessage);
    //   }
    // })
    window.KR.smartForm.onClick(() => {
      // onPaymentLoading(true)
      document.querySelector('.kr-payment-button').innerHTML = `<p>Chargement...</p>`;

    })
    window.KR.onError((event) => {
      console.log(event)
      document.querySelector('.kr-payment-button').innerHTML = `<p>Enregistrer</p>`;
      setErrorMessage(event.errorMessage || 'Erreur lors de l\'enregistrement de la carte)')
      initializePayzen(false)
    })

    window.KR.onSubmit(async (response) => {
      console.log('onSubmit', response);
      const transaction = response.clientAnswer.transactions[0];
      if (transaction.detailedStatus === 'REFUSED') {
        document.querySelector('.kr-payment-button').innerHTML = `<p>Enregistrer</p>`;
        setErrorMessage(transaction.errorMessage || 'Erreur lors de l\'enregistrement de la carte)')
      } else {
        const paymentMethodToken = transaction.paymentMethodToken;
        const cardHolderPan = transaction.transactionDetails.cardDetails.cardHolderPan;
        const effectiveBrand = transaction.transactionDetails.cardDetails.effectiveBrand;
        const last4digit = cardHolderPan.slice(-4);
        const creditCard = {
          brand: effectiveBrand,
          last4: last4digit,
          pm: paymentMethodToken,
        }
        const result = await dispatch(addCreditCard(user, axiosInstance, creditCard, store))
        if (result) {
          handleSelect('selectPaymentMethod')
          openPaymentConfig(false)
        } else {
          document.querySelector('.kr-payment-button').innerHTML = `<p>Enregistrer</p>`;
        }
      }

    })
  }
  useEffect(() => {
    if (document.querySelector('.kr-payment-button')) {
      document.querySelector('.kr-payment-button').innerHTML = `<p>Enregistrer</p> `;
    }
  })
  const initLib = (token) => {
    return new Promise(async (resolve, reject) => {
      const existingScript = document.querySelector(`script[src="https://static.payzen.eu/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js"]`);

      if (existingScript) {
        await existingScript.parentNode.removeChild(existingScript);
      }

      const script = document.createElement('script');
      script.src = 'https://static.payzen.eu/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js';
      script.type = 'text/javascript';
      // script.async = true;
      // script.defer = true;
      script.setAttribute('kr-public-key', process.env.REACT_APP_PAYZEN_PUBLIC_KEY);
      script.setAttribute('kr-language', 'fr-FR');
      script.setAttribute('kr-label-do-register', 'Save');
      // script.setAttribute('kr-hide-debug-toolbar', true);
      script.onload = async (event) => {
        console.log('Payzen script loaded:', event);
        await initializePayzenListeners(user?.email, token)
        resolve();
      };
      script.onerror = (error) => {
        console.error('Error loading Payzen script:', error);
        reject(error);
      };
      document.body.appendChild(script);
    });
  };
  const [loading, setLoading] = useState(false);
  const initializePayzen = (loadingState) => {
    if(loadingState) setLoading(true);
    const email = getUserEmail(user);
    const phoneNumber = getUserPhoneNumber(user)
    axiosInstance.post('/payzen/getToken',
                       {
                         phoneNumber,
                         email
                       })
                 .then(async (response) => {
                   const token = response.data;
                   localStorage.setItem('token', JSON.stringify(token));
                   // setPayzenToken(token)
                   // dispatchCheckout({ type: 'PAYZEN_SET_TOKEN', payload: token })
                   await initLib(token.formToken);
                   setLoading(false);
                 })
                 .catch((error) => {
                   setLoading(false);
                   console.log({ error })
                   // window.location.reload();
                 });
  };
  useEffect(() => {
    initializePayzen(true)
  }, [])
  return (
    <>

      <div className="secure-payment-card">
        <img src={securePayment} alt="securePayment" className="secure-payment-icon" />
        <div className="secure-payment-text">
          <div className="secure-payment-title">
            Enregistrer ma carte pour les prochaines fois
          </div>
          <div className="secure-payment-description">
            Nous accordons une grande importance à ta vie privée. C'est pourquoi nous ne conservons aucune de tes
            informations à moins que tu nous le demandes.
          </div>
        </div>
      </div>
      {
        loading && (
          <div className="containair-loader-wave">
            <div class="dot-wave">
              <div class="dot-wave__dot"></div>
              <div class="dot-wave__dot"></div>
              <div class="dot-wave__dot"></div>
              <div class="dot-wave__dot"></div>
            </div>
          </div>
        )
      }

      <div id="cbpopup" className="config-payzen">
        <div className="kr-smart-form"></div>

        {errorMessage && (
          <div className="flex justify-center bg-red-100 text-red-500 p-2 rounded-xl mt-2">
            <span className="text-red-500 font-bold text-xl text-center ">{errorMessage}</span>
          </div>
        )}
      </div>
    </>

  );
};

export default PayzenCreditCard;