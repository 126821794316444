import React from "react";

const PageLoader = ({small}) => {
  return (
    <>
      <div
        className={" z-50 flex items-center justify-center absolute w-full " + (small ? "h-Full" : "h-screen")}
        style={{ background: "linear-gradient(180deg, rgba(219, 255, 0, 0.1) 0%, rgba(41, 221, 81, 0.1) 100%), var(--color-primary-white, #FFF)" }}
      >
        <div className="pageLoader"/>
      </div>
    </>
  );
};

export default PageLoader;
