import { useDispatch, useSelector } from "react-redux";
import { firebaseMessaging } from "../firebase/firebaseConfig";
import { deleteToken, getToken } from "firebase/messaging";
import useAxiosInterceptors from "../axios/useAxios";
import { updateUserProfile } from "../actions/userActions";
import { useEffect, useState } from "react";

export const useUserLoginStatus = () => {
  const user = useSelector((state) => state.user);
  return user && user._id;
};

export const useFcmTokenManager = () => {
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);

  // redux
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { store } = useSelector((state) => state.store);

  // axios
  const axiosInstance = useAxiosInterceptors();

  useEffect(() => {
    if (user && user._id) {
      setIsNotificationEnabled(user.isNotificationEnabled);
    }
  }, [user]);

  /**
   * Registers the Firebase Cloud Messaging (FCM) token for the current user.
   *
   * @param {boolean} [saveInDB=true] - Whether to save the FCM token in the database. Defaults to true.
   * @return {Promise<{fcmToken: string, apiResponse: any}>} - A promise that resolves to an object containing the FCM token and the API response (if saved in DB).
   */
  const registerFcmToken = async (saveInDB = true) => {
    setIsLoading(true);
    const fcmToken = await getToken(firebaseMessaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });

    let apiResponse = null;

    if (saveInDB) {
      apiResponse = await axiosInstance
        .post("/user/token", {
          storeId: store?.id || user?.favoriteStore || "",
          userId: user._id,
          fbToken: fcmToken,
        })
        .then((res) => res.data);

      await dispatch(updateUserProfile(user._id, axiosInstance));
    }
    setIsLoading(false);

    return { fcmToken, apiResponse };
  };

  /**
   * Deletes the Firebase Cloud Messaging (FCM) token for the current user.
   *
   * @param {boolean} [removeFromDB=true] - Whether to remove the FCM token from the database. Defaults to true.
   * @return {Promise<{isDeleted: boolean, apiResponse: any}>} - A promise that resolves to an object containing the deletion status and the API response (if removed from DB).
   */
  const deleteFCMToken = async (removeFromDB = true) => {
    setIsLoading(true);

    const isDeleted = await deleteToken(firebaseMessaging);

    let apiResponse = null;

    if (removeFromDB) {
      apiResponse = await axiosInstance
        .post("/user/remove-token", {
          userId: user._id,
        })
        .then((res) => res.data);

      await dispatch(updateUserProfile(user._id, axiosInstance));
    }

    setIsLoading(false);

    return {
      isDeleted,
      apiResponse,
    };
  };

  return {
    isLoading,
    isNotificationEnabled,
    registerFcmToken,
    deleteFCMToken,
  };
};
