import { getAdditionalUserInfo, getRedirectResult } from "firebase/auth";

const GetUserPhoneInfo = async (auth, result, password) => {
  const user = JSON.parse(localStorage.getItem("user")) || {}
  try {
    const newResult = {};
    console.log("newResult", newResult);
    console.log(auth)
    newResult.profile = { ...auth.currentUser };
    newResult.profile.email = "";
    newResult.profile.password = password;
    if (newResult.profile && Object.keys(newResult.profile).length > 0) {
      // Copier les informations d'utilisateur supplémentaires de l'utilisateur actuel
      if (
        user.creditCard &&
        user.creditCard[0] &&
        Object.keys(user.creditCard[0]).length > 0
      ) {
        const newCreditCard = {
          id: user.creditCard[0].id,
          brand: user.creditCard[0].brand,
          last4: user.creditCard[0].last4,
          pm: user.creditCard[0].pm,
          img: user.creditCard[0].img,
        };
        newResult.profile.creditCard = newCreditCard;
      }

      if (
        user.payzenCreditCards &&
        user.payzenCreditCards[0] &&
        Object.keys(user.payzenCreditCards[0]).length > 0
      ) {
        const newPayzenCreditCard = {
          brand: user.payzenCreditCards[0].brand,
          last4: user.payzenCreditCards[0].last4,
          pm: user.payzenCreditCards[0].pm,
          img: user.payzenCreditCards[0].img,
        };
        newResult.profile.payzenCreditCards = newPayzenCreditCard;
      }

      if (user.currentPromo && Object.keys(user.currentPromo).length > 0) {
        newResult.profile.currentPromo = user.currentPromo;
        console.log(newResult.profile.currentPromo, "premier if");
      } else {
        newResult.profile.currentPromo = [];
        console.log(newResult.profile.currentPromo, "deuxieme if");
      }

      if (user.appliedPromo && Object.keys(user.appliedPromo).length > 0) {
        newResult.profile.appliedPromo = user.appliedPromo;
      }

      if (user.conecs && Object.keys(user.conecs).length > 0) {
        newResult.profile.conecs = user.conecs;
      }

      if (user.usedPromo && Object.keys(user.usedPromo).length > 0) {
        console.log(user.usedPromo);
        console.log(Object.keys(user.usedPromo).length);
        newResult.profile.usedPromo = user.usedPromo;
      }

      if (user.edenred && Object.keys(user.edenred).length > 0) {
        newResult.profile.edenred = user.edenred;
      }
      newResult.profile.fidCard = user?.fidCard || []

      console.log({ newResult: newResult, result: result });
      return { newResult: newResult, result: result };
    } else {
      console.log("No profile found");
    }
  } catch (error) {
    console.error(error);
  }
};

export default GetUserPhoneInfo;
