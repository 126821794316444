import React, { useEffect, useState } from "react";
import { getStoreList } from "../actions/storeActions";
import { useDispatch } from "react-redux";
import PageLoader from "./loader/PageLoader";
import Search from "./search";
import packageInfo from "../../package.json";
import StoreList from "./StoresList";
import CompanyTile from "./tile/CompanyTile";

const CompaniesList = (props) => {
  const dispatch = useDispatch();
  const [storeList, setStoreList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchStoreList = async () => {
      try {
        const response = await getStoreList(props.axiosInstance);
        console.log(response);
        setStoreList(response);
      } catch (error) {
        console.error("Error fetching store list : ", error);
      }
    };

    fetchStoreList();
  }, []);

  const filteredStoreList = storeList
    ? storeList.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const [magasinChoice, setMagasinChoice] = useState("");
  const [actifStores, setActifStores] = useState("");
  const [actifId, setActifId] = useState("");
  const [actifLogo, setActifLogo] = useState("");
  const [activeCompanies, setActiveCompanies] = useState(false);

  const handleSelectCompany = (id, logo, stores, name) => {
    setActifId(id);
    setActifLogo(logo);
    setActifStores(stores);
    setMagasinChoice(name);
    setActiveCompanies(true);
  };

  return (
    <>
      {storeList && storeList.length > 0 ? (
        <>
          <div
            className={
              activeCompanies
                ? "companies_page active_companies_page bg-gradient1"
                : "companies_page hidden_companies_page bg-gradient1"
            }
          >
            <StoreList
              _id={actifId}
              image={actifLogo}
              stores={actifStores}
              name={magasinChoice}
              setActive={setActiveCompanies}
              axiosInstance={props.axiosInstance}
            />
          </div>
          <div className="store_list_container_principale bg-gradient1">
            <div className="store_list_title">Choix de l'enseigne</div>
            <Search handleSearch={handleSearch} />
            <ul className="store_list_container_secondary">
              {filteredStoreList.map((item) => (
                <CompanyTile
                  key={item._id.slice(0, -1)}
                  id={item._id}
                  name={item.name}
                  image={item.image}
                  onClick={() => {
                    handleSelectCompany(
                      item._id,
                      item.image,
                      item.stores,
                      item.name
                    );
                  }}
                />
              ))}
              <span className="app-version">v{packageInfo.version}</span>
            </ul>
          </div>
        </>
      ) : (
        <>
          {" "}
          <PageLoader />
        </>
      )}
    </>
  );
};

export default CompaniesList;
