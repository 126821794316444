import React, { useEffect } from "react";
import pikko from "../styles/img/logostatic.svg";
import croix from "../styles/img/croix.svg";

const listStyle={
    listStyle: "disc",
   

}



const PopupSample = (data) => {
    
const [popupinscrit, setPopupinscrit] = React.useState(localStorage.getItem('popupinscription'))

const closepopup = () => {
    setPopupinscrit(localStorage.setItem('popupinscription', false))
   
}
useEffect(() => {
  setPopupinscrit(localStorage.getItem('popupinscription'))
}
, [React.useState(localStorage.getItem('popupinscription'))])


    return (
   <>
      <div  className="absolute w-[100vw] h-[100vh] transform translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] overflow-y-auto z-40  flex items-center justify-center bg-black/80">
       <div className="w-[100%] relative   m-4 text-2xl flex flex-col items-center justify-center py-8 px-4 shadow-md rounded-[2em] bg-white">
       <div onClick={data.close} className="closepopup transform absolute top-4 right-6 ">
            <img className="rotate-[45deg]" src={croix} alt="" srcset="" />
          </div>
            <div>
                <img className="w-20 mb-6" src={pikko} alt="ananas" />
            </div>

            <div className="flex flex-col items-center justify-center gap-4 w-full text-black">
                <div className="text-5xl font-light" style={{fontFamily: "geomanistmedium"}}>
                    {data.Title}
                </div>
                <div className="text-center font light text-2xl" style={{fontFamily: "Open Sans"}}>
               {data.Text}
                </div>
                <ul className="" style={{fontFamily: "Open Sans"}}>
                  {data.List.map((item, index) => (
                    <li className="text-start my-4" key={index} style={listStyle}>{item}</li>
                  ))}
                </ul>
                <button onClick={data.click} className="payer mt-6 w-full p-6 font-light text-3xl w-11/12" style={{fontFamily: "geomanistmedium"}}> 
                    {data.Button}
                </button>

            </div>




        </div>
        </div>
        </> 

    )

}

export default PopupSample;