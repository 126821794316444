import { SCAN_DISPLAY } from "../constants/scanConstants";

export const scanDisplay = (display) => async(dispatch, getState) => {

    dispatch({type: SCAN_DISPLAY, payload: display});
    localStorage.setItem('scanner', JSON.stringify(getState().scanner));

}


