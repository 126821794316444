import {  signInWithPhoneNumber,RecaptchaVerifier, PhoneAuthProvider, signInWithCredential  } from "firebase/auth";
import { notifyError, notifySuccess } from "../../../utils/Notify";
// export const SendOtp = async (Auth,phoneNumber) => {
//     try{
//         const appVerifier = new RecaptchaVerifier(Auth, 'recaptcha',{})
//         const confirmationResult = await signInWithPhoneNumber(Auth, phoneNumber, appVerifier);
//         return confirmationResult;
//     }
//     catch(e){
//         console.log(e)
//     }
// }

export default async function onNumberSignUp(auth, number, setStep, type, id) {
  try {
      if (window.recaptchaVerifier) {
          window.recaptchaVerifier.clear(); 
          delete window.recaptchaVerifier; 
      }

      window.recaptchaVerifier = new RecaptchaVerifier(
          id, {
              size: type,
              callback: (response) => {
                  console.log("Recaptcha solved");
              },
              "expired-callback": () => {
                  console.log("CAPTCHA expired, please complete it again.");
              },
          },
          auth
      );

      const widgetId = await window.recaptchaVerifier.render();

      try {
          const confirmationResult = await signInWithPhoneNumber(auth, number, window.recaptchaVerifier);
          window.confirmationResult = confirmationResult;
          return {
              success: true,
          };
      } catch (error) {
          console.error("Error during sign-in:", error);

          if (error.code === 'auth/too-many-requests') {
              // Specific error handling for too many requests
              notifyError("Too many requests. Please try again later.");
          }

          try {
              if (window.grecaptcha) {
                  window.grecaptcha.reset(widgetId);
              }
          } catch (resetError) {
              console.error("Failed to reset reCAPTCHA:", resetError);
          }
          return {
              success: false,
              error: error.message,
          };
      }
  } catch (e) {
      console.error("Error initializing reCAPTCHA:", e);
      return {
          success: false,
          error: e.message,
      };
  }
}

export async function onOTPVerify(auth,otp) {

  try {

      const credentials = PhoneAuthProvider.credential(window.confirmationResult.verificationId, otp);
      credentials.user = await signInWithCredential(auth, credentials);
      return {
          success: true,
          user: credentials.user || null,
      };
  } catch (err) {
      console.log(err);
      return {
          success: false,
          error: err,
      };
  }
}