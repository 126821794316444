export const EDENRED_USERINFO_REQUEST = "EDENRED_USERINFO_REQUEST";
export const EDENRED_USERINFO_SUCCESS = "EDENRED_USERINFO_SUCCESS";
export const EDENRED_USERINFO_FAIL = "EDENRED_USERINFO_FAIL";

export const EDENRED_REMOVE_REQUEST = "EDENRED_REMOVE_REQUEST";
export const EDENRED_REMOVE_SUCCESS = "EDENRED_REMOVE_SUCCESS";
export const EDENRED_REMOVE_FAIL = "EDENRED_REMOVE_FAIL";

export const EDENRED_BALANCE_REQUEST = "EDENRED_BALANCE_REQUEST";
export const EDENRED_BALANCE_SUCCESS = "EDENRED_BALANCE_SUCCESS";
export const EDENRED_BALANCE_FAIL = "EDENRED_BALANCE_FAIL";

export const EDENRED_CAPTURE_REQUEST = "EDENRED_CAPTURE_REQUEST";
export const EDENRED_CAPTURE_SUCCESS = "EDENRED_CAPTURE_SUCCESS";
export const EDENRED_CAPTURE_FAIL = "EDENRED_CAPTURE_FAIL";

export const EDENRED_CONFIRM_REQUEST = "EDENRED_CONFIRM_REQUEST";
export const EDENRED_CONFIRM_SUCCESS = "EDENRED_CONFIRM_SUCCESS";
export const EDENRED_CONFIRM_FAIL = "EDENRED_CONFIRM_FAIL";


export const EDENRED_CANCEL_REQUEST = "EDENRED_CANCEL_REQUEST";
export const EDENRED_CANCEL_SUCCESS = "EDENRED_CANCEL_SUCCESS";
export const EDENRED_CANCEL_FAIL = "EDENRED_CANCEL_FAIL";