import 
{ 
  PAY_SELECT_METHOD_FAIL, 
  PAY_SELECT_METHOD_REQUEST, 
  PAY_SELECT_METHOD_SUCCESS,

}
from "../constants/payMethodConstants";


export const payMethodReducer = (state = {}, action) => {
    switch (action.type) {

      case PAY_SELECT_METHOD_REQUEST:
        return { ...state };
      case PAY_SELECT_METHOD_SUCCESS:

        return action.payload;
      case PAY_SELECT_METHOD_FAIL:
        return { ...state };

      

      default:
        return state;
    }
  };


  