import React, { useEffect, useState } from "react";
import { getCartPromo } from "../../actions/cartActions";
import { useDispatch, useSelector } from "react-redux";
import baguetteMagique from "../../styles/img/baguetteMagique.svg";
import fire from "../../styles/img/fire.svg";
import disapointface from "../../styles/img/disapointface.svg";
import refresh from "../../styles/img/refresh.svg";
const CalculatePromoButton = ({
  store,
  cartItems,
  axiosInstance,
  showCart,
  isCartChanged,
  handleCartChange,
}) => {
  const availableStores = ["MATCH"];
  const [buttonStatus, setButtonStatus] = useState("recherche");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    setButtonStatus("recherche");
  }, [showCart]);

  const calculateOrderPromo = async (event) => {
    event.stopPropagation();
    const result = await dispatch(
      getCartPromo(cartItems._id, user, axiosInstance)
    );
    if (result.type === "CART_INFO_SUCCESS")
      setButtonStatus("recherche success");
    if (result.type === "CART_INFO_FAIL") setButtonStatus("recherche fail");
    handleCartChange();
  };
  useEffect(() => {
    if (isCartChanged) setButtonStatus("recherche");
    // else setButtonStatus('buttonStatus')
  }, [isCartChanged]);
  // const handleCart = () => {
  console.log(handleCartChange);
  // }
  console.log(isCartChanged);

  return (
    <>
      {showCart &&
        availableStores.some((storeName) =>
          store.name.toUpperCase().includes(storeName.toUpperCase())
        ) && (
          <>
            {buttonStatus === "recherche" && (
              <button
                className="recherchePromos z-10 relative"
                onClick={(event) => calculateOrderPromo(event)}
              >
                <img src={baguetteMagique} alt="baguetteMagique" />
                Rechercher des promos
              </button>
            )}
            {buttonStatus === "recherche success z-10 relative" && (
              <div className="promosResult">
                <img src={fire} alt="fire" />
                {cartItems.orderItems.filter(
                  (item) =>
                    (item.promotion && item.promotion > 0) || item.promoLibelle
                ).length + " "}
                promos disponibles
              </div>
            )}
            {buttonStatus === "recherche fail" && (
              <div
                className="w-[50%] gap-2 flex"
                onClick={(event) => calculateOrderPromo(event)}
              >
                <button className="service_indisponible">
                  <img src={disapointface} alt="disapoint" />
                  indisponible
                </button>
                <div className="refresh_promos">
                  <img src={refresh} alt="" />
                </div>
              </div>
            )}
          </>
        )}
    </>
  );
};

export default CalculatePromoButton;
