import React from 'react';
import pikkocoin from '../../styles/img/Pikkocoin.svg';
import BrandConversionLabel from './BrandConversionLabel';
const BrandTile = ({ onClick, brand, showDetail, showPromoText }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="brand-tile" onClick={handleClick}>
      <div className="brand-logo-container">
        <img
          className="brand-logo-img"
          src={brand.logoUrl}
          alt={brand.name}
          style={{ background: brand.color }}
        />
        {/* <BrandConversionLabel brand={brand} /> */}
      </div>
      {/* {showDetail && (
        <div className="brand-name">
          {brand.name}
        </div>
      )} */}
      {showPromoText && (
        <div className="brand-promo-label">
          <span className="promo-highlight">1€</span>
          <span className='bold-05-opacity'>dépensé =</span>
          <span className="promo-highlight">{brand.fidelityConversionRate}</span>
          <img className="coin-img" src={pikkocoin} alt="coin" />
        </div>
      )}
    </div>
  );
};

export default BrandTile;
