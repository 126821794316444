import React, { useState } from 'react';

const Menu = ({onCheckboxChange, store, product}) => {
    
    const [checkMenu, setCheckMenu] = useState(false)
    const handleCheckBoxChange = () => {
        setCheckMenu(!checkMenu)
        onCheckboxChange(!checkMenu)
    }
    console.log(store)
    return (
        <>
        {store && store.id === '65b8bde65a0ef81ff30473bf' && product.category === 'PLAT' ? (
            <button onClick={() => handleCheckBoxChange()} className={`${checkMenu?'border-solid':'border-solid border-[#0000000e]'} mt-4 border-[2px] rounded-[10px] w-full flex items-center justify-between p-4`}>
                <p className='geomanistBold text-[1.7rem]'>
                    Je prends le menu
                </p>
                    <div className={`w-10 h-10 bg-white rounded-[6px] flex items-center border-solid border-[2px] justify-center ${checkMenu?'bg-[#04b404] jelly border-[#0000001e] ':''}`}>
                        {checkMenu && (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-full w-full text-white" fill="none" viewBox="0 0 24 24" stroke="white">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                        )}
                    </div>
            </button>
        ) : (
            <></>
        )}
        </>
    );
};

export default Menu;