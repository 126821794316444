
export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";
export const ADD_USER_APPLEPAY_EMAIL_REQUEST = "ADD_USER_APPLEPAY_EMAIL_REQUEST";
export const ADD_USER_APPLEPAY_EMAIL_SUCCESS = "ADD_USER_APPLEPAY_EMAIL_SUCCESS";
export const ADD_USER_APPLEPAY_EMAIL_FAIL = "ADD_USER_APPLEPAY_EMAIL_FAIL";
export const ADD_USER_ID_REQUEST = "ADD_USER_ID_REQUEST";
export const ADD_USER_ID_SUCCESS = "ADD_USER_ID_SUCCESS";
export const ADD_USER_ID_FAIL = "ADD_USER_ID_FAIL";
export const ADD_USER_COUNT_REQUEST = "ADD_USER_COUNT_REQUEST";
export const ADD_USER_COUNT_SUCCESS = "ADD_USER_COUNT_SUCCESS";
export const ADD_USER_COUNT_FAIL = "ADD_USER_COUNT_FAIL";
export const FIND_USER_REQUEST = "FIND_USER_REQUEST";
export const FIND_USER_SUCCESS = "FIND_USER_SUCCESS";
export const FIND_USER_FAIL = "FIND_USER_FAIL";
export const SAVE_USER_REQUEST = "SAVE_USER_REQUEST";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_FAIL = "SAVE_USER_FAIL";
export const SEND_USER_PROMOCODE_REQUEST = "SEND_USER_PROMOCODE_REQUEST";
export const SEND_USER_PROMOCODE_SUCCESS = "SEND_USER_PROMOCODE_SUCCESS";
export const SEND_USER_PROMOCODE_FAIL = "SEND_USER_PROMOCODE_FAIL";
export const DISCONNECT_USER_REQUEST = "DISCONNECT_USER_REQUEST";
export const DISCONNECT_USER_SUCCESS = "DISCONNECT_USER_SUCCESS";
export const DISCONNECT_USER_FAIL = "DISCONNECT_USER_FAIL";
export const MODIFY_USER_REQUEST = "MODIFY_USER_REQUEST";
export const MODIFY_USER_SUCCESS = "MODIFY_USER_SUCCESS";
export const MODIFY_USER_FAIL = "MODIFY_USER_FAIL";
// CB
export const USER_CB_CARD_REQUEST = "USER_CB_CARD_REQUEST";
export const USER_CB_CARD_SUCCESS = "USER_CB_CARD_SUCCESS";
export const USER_CB_PAYZEN_CARD_SUCCESS = "USER_CB_PAYZEN_CARD_SUCCESS";
export const USER_CB_CARD_FAIL = "USER_CB_CARD_FAIL";
export const USER_CB_REMOVE_CARD_REQUEST = "USER_CB_REMOVE_CARD_REQUEST";
export const USER_CB_REMOVE_CARD_SUCCESS = "USER_CB_REMOVE_CARD_SUCCESS";
export const USER_CB_REMOVE_CARD_FAIL = "USER_CB_REMOVE_CARD_FAIL";
// EDENRED
export const USER_EDENRED_USERINFO_REQUEST = "USER_EDENRED_USERINFO_REQUEST";
export const USER_EDENRED_USERINFO_SUCCESS = "USER_EDENRED_USERINFO_SUCCESS";
export const USER_EDENRED_USERINFO_FAIL = "USER_EDENRED_USERINFO_FAIL";
export const USER_EDENRED_REMOVE_REQUEST = "USER_EDENRED_REMOVE_REQUEST";
export const USER_EDENRED_REMOVE_SUCCESS = "USER_EDENRED_REMOVE_SUCCESS";
export const USER_EDENRED_REMOVE_FAIL = "USER_EDENRED_REMOVE_FAIL";
export const USER_EDENRED_BALANCE_REQUEST = "USER_EDENRED_BALANCE_REQUEST";
export const USER_EDENRED_BALANCE_SUCCESS = "USER_EDENRED_BALANCE_SUCCESS";
export const USER_EDENRED_BALANCE_TIMEOUT = "USER_EDENRED_BALANCE_TIMEOUT";
export const USER_EDENRED_BALANCE_FAIL = "USER_EDENRED_BALANCE_FAIL";
export const USER_EDENRED_TOKEN_REFRESH = "USER_EDENRED_TOKEN_REFRESH";
export const USER_EDENRED_CAPTURE_REQUEST = "USER_EDENRED_CAPTURE_REQUEST";
export const USER_EDENRED_CAPTURE_SUCCESS = "USER_EDENRED_CAPTURE_SUCCESS";
export const USER_EDENRED_CAPTURE_FAIL = "USER_EDENRED_CAPTURE_FAIL";
export const USER_EDENRED_CONFIRM_REQUEST = "USER_EDENRED_CONFIRM_REQUEST";
export const USER_EDENRED_CONFIRM_SUCCESS = "USER_EDENRED_CONFIRM_SUCCESS";
export const USER_EDENRED_CONFIRM_FAIL = "USER_EDENRED_CONFIRM_FAIL";
export const USER_EDENRED_CANCEL_REQUEST = "USER_EDENRED_CANCEL_REQUEST";
export const USER_EDENRED_CANCEL_SUCCESS = "USER_EDENRED_CANCEL_SUCCESS";
export const USER_EDENRED_CANCEL_FAIL = "USER_EDENRED_CANCEL_FAIL";
//CONECS
export const USER_CONECS_USERINFO_REQUEST = "USER_CONECS_USERINFO_REQUEST";
export const USER_CONECS_USERINFO_SUCCESS = "USER_CONECS_USERINFO_SUCCESS";
export const USER_CONECS_USERINFO_FAIL = "USER_CONECS_USERINFO_FAIL";
export const USER_CONECS_REMOVE_REQUEST = "USER_CONECS_REMOVE_REQUEST";
export const USER_CONECS_REMOVE_SUCCESS = "USER_CONECS_REMOVE_SUCCESS";
export const USER_CONECS_REMOVE_FAIL = "USER_CONECS_REMOVE_FAIL";
export const USER_CONECS_BALANCE_REQUEST = "USER_CONECS_BALANCE_REQUEST";
export const USER_CONECS_BALANCE_SUCCESS = "USER_CONECS_BALANCE_SUCCESS";
export const USER_CONECS_BALANCE_FAIL = "USER_CONECS_BALANCE_FAIL";
export const USER_CONECS_CAPTURE_REQUEST = "USER_CONECS_CAPTURE_REQUEST";
export const USER_CONECS_CAPTURE_SUCCESS = "USER_CONECS_CAPTURE_SUCCESS";
export const USER_CONECS_CAPTURE_FAIL = "USER_CONECS_CAPTURE_FAIL";
export const USER_CONECS_CONFIRM_REQUEST = "USER_CONECS_CONFIRM_REQUEST";
export const USER_CONECS_CONFIRM_SUCCESS = "USER_CONECS_CONFIRM_SUCCESS";
export const USER_CONECS_CONFIRM_FAIL = "USER_CONECS_CONFIRM_FAIL";
export const USER_CONECS_CANCEL_REQUEST = "USER_CONECS_CANCEL_REQUEST";
export const USER_CONECS_CANCEL_SUCCESS = "USER_CONECS_CANCEL_SUCCESS";
export const USER_CONECS_CANCEL_FAIL = "USER_CONECS_CANCEL_FAIL";
//GIFT
export const USER_INITIAL_PROMO_REQUEST = "USER_INITIAL_PROMO_REQUEST";
export const USER_INITIAL_PROMO_SUCCESS = "USER_INITIAL_PROMO_SUCCESS";
export const USER_INITIAL_PROMO_FAIL = "USER_INITIAL_PROMO_FAIL";
export const USER_GET_GIFT_REQUEST = "USER_GET_GIFT_REQUEST";
export const USER_GET_GIFT_SUCCESS = "USER_GET_GIFT_SUCCESS";
export const USER_GET_GIFT_FAIL = "USER_GET_GIFT_FAIL";
export const USER_PURCHASE_PROMO = "USER_PURCHASE_PROMO";
export const USER_GET_GIFT_RESET = "USER_GET_GIFT_RESET";


export const USER_CLAIM_GIFT_REQUEST = "USER_CLAIM_GIFT_REQUEST";
export const USER_CLAIM_GIFT_SUCCESS = "USER_CLAIM_GIFT_SUCCESS";
export const USER_CLAIM_GIFT_FAIL = "USER_CLAIM_GIFT_FAIL";
export const USER_CLAIM_GIFT_RESET = "USER_CLAIM_GIFT_RESET";
export const USER_PURCHASE_GIFT_CARD_REQUEST = "USER_PURCHASE_GIFT_CARD_REQUEST";
export const USER_PURCHASE_GIFT_CARD_SUCCESS = "USER_PURCHASE_GIFT_CARD_SUCCESS";
export const USER_PURCHASE_GIFT_CARD_FAIL = "USER_PURCHASE_GIFT_CARD_FAIL";

export const ADD_USER_USEDPROMO_REQUEST = "ADD_USER_USEDPROMO_REQUEST";
export const ADD_USER_USEDPROMO_SUCCESS = "ADD_USER_USEDPROMO_SUCCESS";
export const ADD_USER_USEDPROMO_FAIL = "ADD_USER_USEDPROMO_FAIL";






