import confetti from "canvas-confetti";

export const themeDefault = {
        name: 'default',
        confettis:'🍍',
        background: {
            homeBackground: '',
            accountBackground: '',
            signInBackground: '',
            successScreenBackground: '',
            cartBackground: '', 
        },
        logos:{
            loginLogo:{
                image:'https://storage.googleapis.com/sb_pikkopay_webapp/themes/logos/default/pikkoProfile2.svg',
                style:undefined,
            },
            
        },
        screenDecorations: {
            homeDecorationTop: {
                image:'https://storage.googleapis.com/sb_pikkopay_webapp/themes/logos/default/feuillecocotier.svg',
                style: undefined
            },
            footerCartTitleIcon:undefined,
            footerCartTopDecoration:undefined,
            successScreenDecoration:undefined,
        },
        buttonDecorations: {
            primaryButtonDecoration: undefined,
            primaryButtonDecorationAccount:undefined,
            changeStoreButtonDecoration:undefined,
            secondaryButtonDecoration:undefined
        },
    }
