import { CB_CARD_FAIL, CB_CARD_REQUEST, CB_CARD_SUCCESS, CB_REMOVE_CARD_REQUEST, CB_REMOVE_CARD_SUCCESS } from "../constants/cbConstants";


export const cbReducers = (state = {}, action) => {

    switch (action.type) {

        case CB_CARD_REQUEST: 
            return { loading: true}
        case CB_CARD_SUCCESS:
            console.log(action.payload)
            action.payload.loading = false
            action.payload.success = true
            return action.payload
        case CB_CARD_FAIL: 
            return {...state, error: true, loading: false}

        case CB_REMOVE_CARD_REQUEST: 
            return {...state}
        case CB_REMOVE_CARD_SUCCESS:
            return {}
        case CB_CARD_FAIL:
            return {}   

        default:
            return state;
    }
  };

  