import React from "react";
import { getStore } from "../actions/storeActions";
import { useDispatch } from "react-redux";
import PageLoader from "./loader/PageLoader";
import v from "../styles/img/arrow/v.svg";
import packageInfo from "../../package.json";
import BackButton from "./button/BackButton";

const StoreList = (props) => {
  const dispatch = useDispatch();

  return (
    <>
      {props.stores && props.stores.length > 0 ? (
        <>
          <div className="fixed z-50 flex items-center justify-between w-full h-16 px-4 top-4 left-4">
            <BackButton
              onClick={() => {
                props.setActive(false);
              }}
            />
          </div>
          <div className="store_list_container_principale bg-gradient1">
            <div className="store_list_title">Choix du magasin</div>

            <ul className="store_list_container_secondary">
              <>
                <li key={props._id} className="store_list_tuile select_state">
                  <div className="store_list_tuile_logo_box">
                    <img src={props.image} alt="logo" />
                  </div>
                  <div className="store_list_tuile_name">
                    {props.name.split(" ")[0]}
                    <span className="store_list_tuile_emplacement">
                      {props.name.split(" ").slice(1).join(" ")}
                    </span>
                  </div>
                  <div className="tuile_right_part">
                    <div className="select_store_span">Sélectionné</div>
                    <img
                      className="store_list_tuile_arrow arrow_active"
                      src={v}
                      alt=""
                    />
                  </div>
                </li>
                <div className="store_list_tuile_children_container children_active">
                  {props.stores.map((store, index) => (
                    <li
                      key={index}
                      className="store_list_tuile children"
                      onClick={() => {
                        dispatch(getStore(store.id, props.axiosInstance));
                      }}
                    >
                      <div className="store_list_tuile_logo_box">
                        <img src={store.logo} alt="logo" />
                      </div>
                      <div className="store_list_tuile_name">
                        {store.city}
                        {/* <span className="store_list_tuile_emplacement">
                          {store.city}
                        </span> */}
                      </div>
                      {/* <img className='store_list_tuile_arrow' src={v} alt="" /> */}
                    </li>
                  ))}
                </div>
              </>

              <span className="app-version">v{packageInfo.version}</span>
            </ul>
          </div>
        </>
      ) : (
        <>
          {" "}
          <PageLoader />
        </>
      )}
    </>
  );
};

export default StoreList;
