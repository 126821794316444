import {
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_SUCCESS,
  ORDER_COM_REQUEST,
  ORDER_COM_SUCCESS,
  ORDER_COM_FAIL,
  ORDER_DETAILS_RESET,
} from "../constants/orderConstants";


export const orderDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return { loading: true };
    case ORDER_DETAILS_SUCCESS:
      return { loading: false, order: action.payload, success: true };
    case ORDER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};


export const orderComReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_COM_REQUEST:
      return { loading: true };
    case ORDER_COM_SUCCESS:
      return { loading: false, success: true };
    case ORDER_COM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

