import React, { useEffect, useState } from "react";
import RedStoreIcon from "../../../styles/img/account/red-store.svg";
import PrimaryButton from "../../../components/button/PrimaryButton";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import CustomLoader, {
  LoaderSize,
} from "../../../components/loader/CustomLoader";
import { getStore, getStoreList } from "../../../actions/storeActions";
import useAxiosInterceptors from "../../../axios/useAxios";
import { useDispatch, useSelector } from "react-redux";
import { modifyUserStore } from "../../../actions/userActions";
import { createCart } from "../../../actions/cartActions";
import storeLocationIcon from '../../../styles/img/account/storeLocation.svg';
import configBackButton from '../../../styles/img/configBackButton.svg';
const FavoriteStore = ({ setActiveTab }) => {
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStore, setSelectedStore] = useState("");
  const [selectStoreName, setSelectStoreName] = useState("");
  const [storeList, setStoreList] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);


  // redux
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  // axios
  const axiosInstance = useAxiosInterceptors();

  useEffect(() => {
    fetchStoreList();
  }, []);

  const fetchStoreList = async () => {
    try {
      setIsLoading(true);
      const response = await getStoreList(axiosInstance);
      // console.log("--> store list", response);
      setStoreList(response);
    } catch (error) {
      console.error("Error fetching store list : ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectStore = (id, name) => {
    console.log("id", id, "name", name);
    setSelectedStore(id);
    setSelectStoreName(name);
    submitFavoriteStore(id, name);
  };

  const handleFilterStore = (value, stores) => {
    console.log("value", value);
    if (value === "") {
      setFilteredStores(stores);
    } else {
      const filtered = stores.filter((store) => store.name.toLowerCase().includes(value.toLowerCase()));
      setFilteredStores(filtered);
    }
  };

  const submitFavoriteStore = async (id) => {
    await dispatch(modifyUserStore(user, axiosInstance, id));
    const orderCreate = { storeId: id, clientId: user?._id || "" };
    await dispatch(createCart(orderCreate, axiosInstance));
    await dispatch(getStore(id, axiosInstance));

    setActiveTab(2);
  };

  return (
    <>
        {/* <img src={RedStoreIcon} className="w-20 h-20" alt="store_icon" /> */}
        <div className="flex flex-col flex-grow mt-5 h-full overflow-y-auto">
          {isLoading ? (
            <CustomLoader loaderSize={LoaderSize.SMALL} />
          ) : (
            <>
         {!selectedCompany? <div className="grid grid-cols-2 gap-5 mt-5">
          {storeList.map((store,index) => (
                <div className="store-box" key={store._id} onClick={() => {setSelectedCompany(store);
                  setFilteredStores(store.stores);
                }}>
                   <img
                      className="object-contain"
                      src={store.image}
                      alt={store.name}
                    />
                </div>
              ))}
          </div>
          :
          <>
              <div className="searchBar appear">
                <CiSearch fontSize={20} />{" "}
                <input
                  onChange={(e) => handleFilterStore(e.target.value, selectedCompany.stores)}
                  className="flex-grow"
                  placeholder="Recherche ton magasin"
                />
              </div>
              <img className="config-back-button" onClick={() => setSelectedCompany(null)} src={configBackButton} alt="back" />
            <div className="selected-company-card scaleAppear">
                
              {
                selectedCompany && (
                  <div className="selected-company-tile">
                    <div className="company-image">
                        <img src={selectedCompany.image} alt={selectedCompany.name} />
                    </div>
                    <div className="company-name">
                      {selectedCompany.name}
                    </div>
                  </div>

                )
              }
              <ul className="config-store-list">
              
              {
                filteredStores && filteredStores.map((store) => (
                      <li onClick={() => handleSelectStore(store.id, store.name)} key={store.id}>
                        <img src={storeLocationIcon} alt="store" />
                        {store.name}
                      </li>
                  
                ))
              }
              </ul>
            </div>
          </>}
            </>
          )}
          
            
          {isOpen && (
            <div className="flex-grow max-h-[250px] px-5 py-4 mt-5 overflow-scroll bg-white border border-solid rounded-xl">
          
            </div>
          )}
        </div>
    </>
  );
};

export default FavoriteStore;
