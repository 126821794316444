import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import React from 'react';
import Swal from 'sweetalert2';

const HandleCreateEmail = async(auth, user, email, password) => {
    try{
        const result = await createUserWithEmailAndPassword(auth, email, password)
        var newResult = {
            email: result.user.email
        }
        console.log(result)
        if(user.creditCard && user.creditCard[0] && Object.keys(user.creditCard[0]).length > 0){
            const newCreditCard = {
            id: user.creditCard[0].id,
            brand: user.creditCard[0].brand,
            last4: user.creditCard[0].last4,
            pm: user.creditCard[0].pm,
            img: user.creditCard[0].img
            }
            newResult.creditCard = newCreditCard
        }
        if(user.payzenCreditCards && user.payzenCreditCards[0] && Object.keys(user.payzenCreditCards[0]).length > 0){
            const newPayzenCreditCard = {
                brand: user.payzenCreditCards[0].brand,
                last4: user.payzenCreditCards[0].last4,
                pm: user.payzenCreditCards[0].pm,
                img: user.payzenCreditCards[0].img
            }
            newResult.payzenCreditCards = newPayzenCreditCard
        }
        if(user.currentPromo && Object.keys(user.currentPromo).length > 0){
            newResult.currentPromo = user.currentPromo
        }
        newResult.currentPromo = user.currentPromo || []
        if(user.appliedPromo && Object.keys(user.appliedPromo).length > 0){
            newResult.appliedPromo = user.appliedPromo
        }
        if(user.conecs && Object.keys(user.conecs).length > 0){
            newResult.conecs = user.conecs
        }
        if(user.usedPromo && Object.keys(user.usedPromo).length > 0){
            console.log(user.usedPromo)
            console.log(Object.keys(user.usedPromo).length)
            newResult.usedPromo = user.usedPromo

        }
        if(user.edenred && Object.keys(user.edenred).length > 0){
            newResult.edenred = user.edenred
        }
        console.log(newResult)
        return {result, newResult}
    }catch(e){
        console.log(e.message)
        switch(e.message){
          case 'Firebase: Error (auth/email-already-in-use).':
            Swal.fire({
                title: 'Email déjà utilisé',

                icon: 'error',
                closeButtonHtml: 'X',
                confirmButtonColor: '#FFD700',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    title: 'title-class',
                    confirmButton: 'bg-yellow-400 payer w-[100px]   text-center h-16 geomanistBold text-[1.7rem] rounded-md',

                }
                })
            break;
          case 'Firebase: Password should be at least 6 characters (auth/weak-password).':
            alert('Mot de passe de 6 caractères minimum')
            break;
          default :
            alert('Une erreur est survenue, veuillez réessayer ')
        }

       }


};

export default HandleCreateEmail;
