import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardInfoForUser from "../styles/img/CardInfoForUser.png";
import PointsInfoForUser from "../styles/img/PointsInfoForUser.svg";
import carteCadeauInfoForUser from "../styles/img/carteCadeauInfoForUser.svg";
import mystereInfoForUser from "../styles/img/mystereInfoForUser.svg";
import donInfoForUser from "../styles/img/donInfoForUser.svg";
import virementInfoForUser from "../styles/img/virementInfoForUser.svg";
import CrossInfoForUser from "../styles/img/CrossInfoForUser.svg";
import pikkoLogoWelcome from "../styles/img/account/pikkoLogoWelcome.svg";

import { useNavigate } from "react-router-dom";
import { findUser } from "../actions/userActions";
import useAxiosInterceptors from "../axios/useAxios";
import PrimaryButton from "./button/PrimaryButton";

const UserInfo = ({ title }) => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInterceptors();
  const [brands, setBrands] = useState([]);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const popupUser = localStorage.getItem("popupUser");
    if (user && user._id && !popupUser) {
      setOpen(true);
      setOpen2(true);
      localStorage.setItem("popupUser", true);
    }
  }, [user]);

  const Jcompris = () => {
    setOpen2(false);
    setTimeout(() => {
      setOpen(false);
    }, 600);
  };
  const varstyle = {
    boxShadow: open ? "0 0 0 100vmax rgb(0 0 0 / 80%)" : "",
    background:
      "linear-gradient(180deg, rgba(219, 255, 0, 0.10) 0%, rgba(41, 221, 81, 0.10) 100%), var(--color-primary-white, #FFF)",
  };

  return open2 ? (
    <div
      className={`transition-all duration-700 h-[100dvh] fixed w-[100vw] z-40 left-0 top-0 overflow-x-visible`}
      onClick={Jcompris}
    >
      <div
        className={`${
          open2
            ? "z-[50] bg-gradient3 sliderNumberConnect  w-[100%] transform overflow-x-visible translate-x-[-50%]  left-[50%] gap-8 mx-auto flex flex-col  items-center bottom-0 transition-all h-full fixed  shadow-lg duration-700 slideuppopupXY "
            : "z-[50] p-8 w-[100%] transform translate-x-[-50%] left-[50%] gap-8 mx-auto flex flex-col  items-center rounded-tr-[20px] bottom-0 transition-all  rounded-tl-[20px] fixed  shadow-lg duration-700 "
        }`}
      >
        <h1>{title}</h1>
        <PrimaryButton label={"Continuer"} onClick={Jcompris} />
        <img
          src={pikkoLogoWelcome}
          alt="pikkoLogo"
          className="pikkoLogo-phone-screen animatedLogo"
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default UserInfo;
