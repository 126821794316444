import "./styles.scss";

import React from "react";
import LottieAnimation from "./animation.json";
import Lottie from "lottie-react";

const AnimatedLoader = ({ onComplete = () => {} }) => {
  return (
    <div className="animated-loader">
        <Lottie
        loop={true}
        autoplay={true}
        style={{ maxWidth: '100px', maxHeight: '100px', transform: "translate(0, -50%)" }}
        animationData={LottieAnimation}
        onComplete={onComplete}
      />
    </div>
  );
};

export default AnimatedLoader;
