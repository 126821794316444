export const authenticate = (axiosInstance, email, externalToken) => {
  return axiosInstance.post(
    '/authenticate',
    { email },
    { headers: { 'Authorization': externalToken }}
  );
};

export const authenticateNumberPhone = (axiosInstance, phoneNumber) => {
  let token
  getCookie('_session') ? token = getCookie('_session') : token = '';
  return axiosInstance.post(
    'user/PhoneNumberauth',
    { phoneNumber },
    { headers: { 'Authorization': token }}
  );
}

function getCookie(name) {
  // Ajoute "=" à la fin du nom pour faciliter la recherche dans la chaîne des cookies
  let nameEQ = name + "=";
  // Divise la chaîne des cookies en un tableau de cookies individuels
  let ca = document.cookie.split(';');

  // Parcourt chaque cookie
  for(let i=0; i < ca.length; i++) {
      let c = ca[i];
      // Supprime les espaces blancs en trop au début du cookie
      while (c.charAt(0) === ' ') c = c.substring(1,c.length);
      // Vérifie si le cookie commence par le nom recherché et renvoie la valeur
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null; // Renvoie null si le cookie n'est pas trouvé
}

