
export const checkIfUserPhoneExists = async (axiosInstance,phoneNumber) => {
    try {
        const response = await axiosInstance.post('/user/phoneNumberCheck', 
        {phoneNumber: phoneNumber}
        );
        if(response.status === 201) {
            return {
                success: true
            }
        }
        else if(response.status === 200) {
            return {
                success: false
            }
        }
  
    } catch (error) {
        console.log(error)
        return {
            serverError: 'Veuillez réessayer plus tard'
        }
    }
}

export const changePassword = async (axiosInstance,phoneNumber,password,setlockUntil) => {
    try {
        const response = await axiosInstance.post('/user/changePassword', 
        {phoneNumber: phoneNumber,  newPassword: password}
        );
        if(response.status === 201) {
            if(localStorage.getItem('lockUntil')){
                localStorage.removeItem('lockUntil')
            }
            setlockUntil(false)
            return {
                success: true
            }
        }
        else if(response.status === 200) {
            return {
                success: false
            }
        }
  
    } catch (error) {
        console.log(error)
        return {
            serverError: 'Erreur serveur'
        }
    }
}