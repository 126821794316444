import v from '../../styles/img/arrow/v.svg';
import React from 'react';

const CompanyTile = ({ id, name, image, onClick }) => {

  return (
    <div
      key={id}
      className="store_list_tuile"
      onClick={onClick}
    >
      <div className="store_list_tuile_logo_box">
        <img src={image} alt="logo" />
      </div>
      <div className="store_list_tuile_name">
        {name.split(' ')[0]}
        <span className="store_list_tuile_emplacement">
          {name.split(' ').slice(1).join(' ')}
        </span>
      </div>
      <img className='store_list_tuile_arrow' src={v} alt="" />
    </div>
  );
};

export default CompanyTile;
