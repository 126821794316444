import React, { useEffect, useState } from 'react';
import useAxiosInterceptors from '../../axios/useAxios';
import { useDispatch } from 'react-redux';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Lottie from 'lottie-react';
import loadingPayment from '../../styles/lottie/loadingPayment.json'
import { getCardInfo } from '../../actions/userCbActions';
import securePayment from '../../styles/img/account/securePayment.svg';
import { USER_CB_CARD_FAIL } from '../../constants/userConstants';

const StripeCreditCard = ({ user, axiosInstance, handleSelect, store, openPaymentConfig }) => {

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [cardNbComplete, setCardNbComplete] = useState(false);
  const [cardExpComplete, setCardExpComplete] = useState(false);
  const [cardCVCComplete, setCardCVCComplete] = useState(false);
  const [userName, setUserName] = useState(undefined);
  const [focusedElement, setFocusedElement] = useState(null);

  const dispatch = useDispatch()
  const elements = useElements();
  const stripe = useStripe()

  function hasFirstAndLastName(str) {
    return str.split(/\s+/).filter(function (word) {
      return word.length > 0;
    }).length > 1;
  };

  const handleInputChange = (e) => {
    setUserName(e.target.value);
  };
  const handleFocus = (element) => {
    setFocusedElement(element);
  };
  const createPaymentMethod = async (userName) => {
    setLoading(true)
    const cardElement = elements.getElement(CardNumberElement);
    const data = await stripe.createPaymentMethod({
                                                    type: 'card',
                                                    card: cardElement,
                                                    billing_details: {
                                                      name: userName,
                                                      email: user.email,
                                                      phone: user.phoneNumber
                                                    }
                                                  })
    const result = await dispatch(getCardInfo(
                                    data.paymentMethod.id,
                                    user,
                                    axiosInstance,
                                    setErrorMessage,
                                    store
                                  )
    );
    console.log(result)
    if (!result) {
      setLoading(false);
    } else {
      setLoading(false);
      handleSelect('selectPaymentMethod')
      openPaymentConfig(false)
    }

  }

  useEffect(() => {
    if (elements && elements.getElement(CardNumberElement)) {
      elements.getElement(CardNumberElement).on('change', function (event) {
        const { complete } = event;
        if (complete) {
          setCardNbComplete(true);

        } else {
          setCardNbComplete(false);
        }
      });
      elements.getElement(CardExpiryElement).on('change', function (event) {
        const { complete } = event;
        if (complete) {
          setCardExpComplete(true);
        } else {
          setCardExpComplete(false);
        }
      });
      elements.getElement(CardCvcElement).on('change', function (event) {
        const { complete } = event;
        if (complete) {
          setCardCVCComplete(true);
        } else {
          setCardCVCComplete(false);
        }
      });
      elements.getElement(CardNumberElement).on('focus', function (event) {
        setFocusedElement(1);
      });
      elements.getElement(CardExpiryElement).on('focus', function (event) {
        setFocusedElement(2);
      });
      elements.getElement(CardCvcElement).on('focus', function (event) {
        setFocusedElement(3);
      });
      elements.getElement(CardNumberElement).on('blur', function (event) {
        setFocusedElement(false);
      });
      elements.getElement(CardExpiryElement).on('blur', function (event) {
        setFocusedElement(false);
      });
      elements.getElement(CardCvcElement).on('blur', function (event) {
        setFocusedElement(false);
      });
      //setFormCompletion(tempForm);
      //formComplete(tempForm);
    }
  }, [elements]);

  useEffect(() => {
    if (cardNbComplete) {
      const expiryElement = elements.getElement(CardExpiryElement);
      if (expiryElement) {
        expiryElement.focus();
      }
    }
    if (cardExpComplete) {
      const cvcElement = elements.getElement(CardCvcElement);
      if (cvcElement) {
        cvcElement.focus();

      }
    }
  }, [cardNbComplete, cardExpComplete, elements]);

  return (
    <>
      <div className="saving-card-screen">


        <div className="secure-payment-card">
          <img src={securePayment} alt="securePayment" className="secure-payment-icon" />
          <div className="secure-payment-text">
            <div className="secure-payment-title">
              Enregistrer ma carte pour les prochaines fois
            </div>
            <div className="secure-payment-description">
            Nous accordons une grande importance à ta vie privée. C'est pourquoi nous ne conservons aucune de tes
            informations à moins que tu nous le demandes.
            </div>
          </div>
        </div>

        <>
          <div
            id="cbpopup" className="!p-0 w-full" style={{ fontFamily: 'Open Sans' }}
            // className={` slide-down-transition ${
            //   isContentVisible
            //     ? 'slide-down-content visible '
            //     : 'slide-down-content'
            // }`}
          >
            <div className="mailformpayment rounded-[1em] margin-top">
              <label htmlFor="">
                <div className="text-[1.5rem] geomanistMedium mb-2 ml-2">
                  Nom Prénom
                </div>
              </label>
              <input placeholder="Nom et prénom sur la carte" maxLength="20" type="text" required name="name"
                     id="nomprenom" value={userName === ' ' ? '' : userName}
                     onChange={handleInputChange}
                     autoComplete="name" />
            </div>
            <div id="stripe">
              <div className="flex flex-col w-full ">
                <label htmlFor="">
                  <div className="text-[1.5rem] geomanistMedium mb-2 ml-2">
                    Numéro de carte
                  </div>
                </label>
                <div
                  onClick={() => handleFocus(1)}
                  className={`child1 `}>

                  <CardNumberElement
                    className={`stripe_card ${focusedElement === 1 ? 'focused' : ''}`}
                    // options={{ style: { base: inputStyle } }}

                  />
                </div>
              </div>


              <div className="line w-full flex justify-between ">
                <div className="w-[46%] flex flex-col ">
                  <label htmlFor="">
                    <div className="text-[1.5rem] geomanistMedium mb-2 ml-2">
                      Date d'expiration
                    </div>
                  </label>
                  <div
                    onClick={() => handleFocus(2)}
                    className={`child2 `}>

                    <CardExpiryElement
                      className={`stripe_card ${focusedElement === 2 ? 'focused' : ''}`}
                      id="expiryId"
                      // options={{ style: { base: inputStyle } }}

                    />
                  </div>
                </div>
                <div className="w-[46%]  flex flex-col">
                  <label htmlFor="">
                    <div className="text-[1.5rem] geomanistMedium mb-2 ml-2">
                      CVC
                    </div>
                  </label>
                  <div
                    onClick={() => handleFocus(3)}
                    className={`child3`}>

                    <CardCvcElement
                      className={`stripe_card ${focusedElement === 3 ? 'focused' : ''}`}
                      // options={{ style: { base: inputStyle } }}

                    />
                  </div>
                </div>
              </div>
            </div>


            <input className="mr-4" id="cbcheckbox" type="checkbox"
              // checked={saveCard} onChange={handleSaveCard}
            />

            <div className={'flex flex-col w-full'}>
              {loading ? (
                <div className="payer_maintenant_btn flex items-center bottom-btn-shadow">
                  <Lottie
                    animationData={loadingPayment}
                    loop={true}
                    autoplay={true}
                    style={{
                      width: '20px',
                      height: '20px',
                      top: '2.5px',
                      position: 'relative'
                    }} // Adjust the width and height as needed
                  />
                </div>
              ) : (
                <>
                  <button
                    className={`payer_maintenant_btn boxShadow flex items-center  ${
                      !cardCVCComplete || !cardExpComplete || !cardNbComplete
                      || !hasFirstAndLastName(userName)
                        ? 'pointer-events-none backgray2 opacity-35'
                        : ''
                    } ${errorMessage ? 'error-message' : ''}`}
                    onClick={() => createPaymentMethod(userName)}
                    disabled={!cardCVCComplete || !cardExpComplete || !cardNbComplete || loading
                      || !hasFirstAndLastName(userName)
                    }
                  >
                    <p className="font-light " style={{ fontFamily: 'geomanistmedium' }}>Enregistrer</p>
                  </button>
                  {errorMessage && (
                    <div className="flex justify-center bg-red-100 text-red-500 p-2 rounded-xl mt-2">
                      <span className="text-red-500 font-bold text-xl text-center ">{errorMessage}</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      </div>

    </>
  );
};

export default StripeCreditCard;