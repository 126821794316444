import { GET_THEME_REQUEST,GET_THEME_SUCCESS,GET_THEME_FAIL } from "../constants/themeConstants";
import { themeDefault } from "../utils/theme_default";


export const themeReducer = (state = { theme: {} }, action) => {
  switch (action.type) {
    case GET_THEME_REQUEST:
      return { loading: true, theme: themeDefault };
    case GET_THEME_SUCCESS:
      return { loading: false, theme: action.payload };
    case GET_THEME_FAIL:
      return { loading: false, theme: themeDefault };
    default:
      return state;
  }
};