import React, { useEffect, useState } from 'react';
import PaygreenBankCardForm from '../../payments/Paygreen/bank-card/PaygreenBankCardForm';
import { formatPriceWithEuro } from '../../utils/StringUtils';
import { getOrderAmount } from '../../utils/PriceUtils';
import { WsError } from '../../constants/wsError';
import { getConecsUserinfo, paygreenCreateBuyer } from '../../actions/userConecsActions';
import { getErrorMessage } from '../../utils/WsUtils';
import { useDispatch } from 'react-redux';
import securePayment from '../../styles/img/account/securePayment.svg';
import Lottie from 'lottie-react';
import loadingPayment from '../../styles/lottie/loadingPayment.json';

const PaygreenConecsCard = ({ user, axiosInstance, store, handleSelect }) => {

  const paygreen_endpoint = process.env.REACT_APP_PAYGREEN_API_ENDPOINT;
  const paygreenJs = process.env.REACT_APP_PAYGREEN_JS_ENDPOINT;
  const paygreen = process.env.REACT_APP_PAYGREEN_PUBLIC_KEY;
  const dispatch = useDispatch()
  const [cardNbComplete, setCardNbComplete] = useState();
  const [cardExpComplete, setCardExpComplete] = useState();
  const [cardCVCComplete, setCardCVCComplete] = useState();
  const [paygreenShop, setPaygreenShop] = useState('')
  const [showPaymentFields, setShowPaymentFields] = useState(false);
  const [buyerConecs, setBuyerConecs] = useState('')

  const [paymentInputLoading, setPaymentInputLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState(user?.email || '');

  const handleInputChange = (e) => {
    setUserName(e.target.value);
  };
  const handleInputChange2 = (e) => {
    setUserEmail(e.target.value);
  };
  const handleError = (message) => {
    setErrorMessage(message || 'Enregistrement échoué');
    setLoading(false);
    window.paygreenjs.unmount(true);
    initPaygreen();
    onInitPaygreenEventListeners();
  };
  const onInitPaygreenEventListeners = () => {
    window.paygreenjs.attachEventListener(window.paygreenjs.Events.PAN_FIELD_FULFILLED, (event) => {
      // document.getElementById('pan-check')?.classList.remove('invisible')
      setCardNbComplete(true)
    });

    window.paygreenjs.attachEventListener(window.paygreenjs.Events.EXP_FIELD_FULFILLED, (event) => {
      // document.getElementById('exp-check')?.classList.remove('invisible')
      setCardExpComplete(true)

    });

    window.paygreenjs.attachEventListener(window.paygreenjs.Events.CVV_FIELD_ONCHANGE, (event) => {
      if (event.detail.valid) {
        document.getElementById('cvv-check')?.classList.remove('invisible')
        setCardCVCComplete(true)
      } else {
        setCardCVCComplete(false)
      }
    });
    window.paygreenjs.attachEventListener(
      window.paygreenjs.Events.INSTRUMENT_READY,
      async (event) => {
        console.log(event)
      })
    window.paygreenjs.attachEventListener(window.paygreenjs.Events.TOKEN_READY, (event) => {
      console.log('TOKEN_READY', event.detail);
    });
  }
  const styless = {
    input: {
      base: {
        color: 'red',
        fontSize: '10px',
        background: 'white',
      },
      hover: {
        color: 'grey',
      },
      focus: {
        color: 'grey',
      },
      invalid: {
        color: 'red',
      },
      placeholder: {
        base: {
          color: 'grey',
        },
      },
    },
    checkbox: {
      label: {
        base: {
          color: 'black',
        },
        unchecked: {
          color: 'red',
        },
      },
      box: {
        base: {
          color: 'black',
          hover: {
            color: 'red',
          },
        },
        unchecked: {
          color: 'red',
        },
      },
    },
  }
  
  const paygreenjsInit = async (result) => {
    try {
      const ok = await window.paygreenjs.init({
                                                publicKey: paygreen,
                                                buyer: undefined,
                                                mode: 'instrument',
                                                paymentMethod: 'conecs',
                                                modeOptions: {
                                                  shopId: result.data.paygreenShop,
                                                },
                                                styless,
                                              });
      console.log('PayGreen init response:', ok);
    } catch (error) {
      console.error('Error initializing PayGreen:', error);
    }
  };

  function countWords(str) {
    return str.split(/\s+/).filter(function (word) {
      return word.length > 0;
    }).length;
  }

  function validateEmail(email) {
    // Expression régulière basique pour valider une adresse e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.trim());
  }

  const handleSubmit = async () => {

    console.log('handleSubmit');

    setErrorMessage('');
    if (countWords(userName) <= 1 || validateEmail(userEmail) <= 0) {
      setErrorMessage(WsError.PAYGREEN_INFORMATION_MISSING);
      return;
    }
    let userConecs;
    const userInfo = {
      name: userName,
      email: userEmail.trim()
    }

    console.log('PAYE window.paygreenjs', window.paygreenjs);

    try {
      if (window.paygreenjs) {
        setLoading(true);
        await window.paygreenjs.updateConsent(true)
        console.log('window.paygreenjs ---> 1', window.paygreenjs);

        await window.paygreenjs.submitPayment();

        console.log('window.paygreenjs ---> 2', window.paygreenjs);

        window.paygreenjs.attachEventListener(window.paygreenjs.Events.TOKEN_READY, (event) => {
          console.log('TOKEN_READY event', event);
        });

        window.paygreenjs.attachEventListener(
          window.paygreenjs.Events.FULL_PAYMENT_DONE,
          (event) => console.log('Payment success'),
        );

        window.paygreenjs.attachEventListener(
          window.paygreenjs.Events.INSTRUMENT_READY,
          async (event) => {

            console.log('handleSubmit event INSTRUMENT_READY', event);

            paygreenCreateBuyer(userInfo, axiosInstance)
                  .then(async (response) => {

                    console.log('handleSubmit paygreenCreateBuyer response', response);

                    userConecs = {
                      buyerId: response.data.data.id,
                      email: response.data.data.email,
                      firstName: response.data.data.firstName, //prénom
                      lastName: response.data.data.lastName, //nom
                      instrument: event.detail.instrument.id,
                      balance: event.detail.instrument.daily_balance,
                      brand: event.detail.instrument.issuer,
                      reuse: event.detail.instrument.reuse_allowed,
                      last4: event.detail.instrument.last4
                    }
                    await window.paygreenjs.unmount(true);
                    setShowPaymentFields(false);
                  })
                  .catch((error) => {
                    const errorMessage = getErrorMessage(error);
                    handleError(errorMessage);
                  })
                  .then(async () => {
                    //NEXT STEP
                    dispatch(getConecsUserinfo(userConecs, user, axiosInstance))
                    handleSelect('selectPaymentMethod')
                  })
          }
        );
        window.paygreenjs.attachEventListener(window.paygreenjs.Events.TOKEN_FAIL, (event) => {
          console.log(event.detail.error)
          handleError(event.detail.error || WsError.PAYGREEN_TOKEN_FAIL);
        })
      }
    } catch (e) {
      console.error(WsError.PAYGREEN_GLOBAL_FAIL, e);
      handleError(WsError.PAYGREEN_GLOBAL_FAIL);
    }
  }
  const initPaygreen = () => {
    setShowPaymentFields(true);
    setLoading(true);
    axiosInstance.get(`store/conecsId/${store.id}`)
                 .then(async (result) => {
                   setPaygreenShop(result.data.paygreenShop)
                   await paygreenjsInit(result);
                   setLoading(false);
                 })
                 .catch(() => setLoading(false))
  }
  useEffect(() => {
    const script = document.createElement('script');
    script.src = paygreen_endpoint;
    script.async = true;
    script.onload = () => {
      onInitPaygreenEventListeners();
      // if (!user.conecs || !user.conecs.buyerId) {
      initPaygreen();
      // }
    }
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="saving-card-screen">
      <script defer type="text/javascript" src={paygreen_endpoint} />
      <link href={paygreenJs} type="text/css" rel="stylesheet" />
      <div className='secure-payment-card'>
              <img src={securePayment} alt="securePayment" className='secure-payment-icon' />
            <div className="secure-payment-text">
              <div className="secure-payment-title">
                Enregistrer ma carte pour les prochaines fois
              </div>
              <div className="secure-payment-description">
              Nous accordons une grande importance à ta vie privée. C'est pourquoi nous ne conservons aucune de tes
              informations à moins que tu nous le demandes.
              </div>
            </div>
      </div>
      <div
        className="mailformpayment mailformpayment--name shadow-[0px 1px 2px rgba(0, 0, 0, 0.25)] rounded-[1em] mt-8">
           <label htmlFor="">
                <div className="text-[1.5rem] geomanistMedium mb-2 ml-2">
                  Nom Prénom
                </div>
            </label>
        <input className="shadow-md" maxLength="20" placeholder="Nom et prénom sur la carte" type="text"
               name="name" id="nomprenom" value={userName === ' ' ? '' : userName}
               onChange={handleInputChange}
               autoComplete="name" />
      </div>
      {!user.email && (
        <div className="mailformpayment shadow-[0px 1px 2px rgba(0, 0, 0, 0.25)] rounded-[1em]t">
           <label htmlFor="">
                <div className="text-[1.5rem] geomanistMedium my-2 ml-2">
                  Email
                </div>
            </label>
          <input className="shadow-md" placeholder="Email" type="text" name="email" id="nomprenom"
                 autoComplete="email"
                 value={userEmail === '' ? '' : userEmail} onChange={handleInputChange2} />
        </div>)}
      <div id="paygreen-container" style={{ display: '' }} />
      <div id="paygreen-methods-container" style={{ visibility: 'hidden' }} />

      {/*{paymentInputLoading && <CustomLoader/>}*/}
      {showPaymentFields && (
        <div className="paygreen-form">
          <div className="child1">
          <label className='flex w-full text-start'>
                <div className="text-[1.5rem] geomanistMedium mb-2 ml-2">
                  Numéro de carte
                </div>
            </label>
            <div className="mt-2" id="paygreen-pan-frame">
              {/*<img id="pan-check" className="absolute right-0 bottom-0 h-6 invisible"*/}
              {/*     src="/images/check-2.svg" />*/}
            </div>
          </div>
          <div className="date-and-crypto-container">
            <div className="child2">
            <label className='flex w-full text-start'>
                <div className="text-[1.5rem] geomanistMedium mb-2 ml-2">
                  Date d'expiration
                </div>
            </label>
              <div className="mt-2" id="paygreen-exp-frame">
                {/*<img*/}
                {/*  id="exp-check"*/}
                {/*  className="absolute right-0 bottom-0 h-6 invisible"*/}
                {/*  src="/images/check-2.svg"*/}
                {/*/>*/}
              </div>
            </div>
            <div className="paygreen-cvv-container child3 ">
            <label className='flex w-full text-start'>
                <div className="text-[1.5rem] geomanistMedium mb-2 ml-2">
                  Cryptogramme
                </div>
            </label>
              <div className="mt-2" id="paygreen-cvv-frame"></div>
              <img id="cvv-check" className="absolute right-0 bottom-0 h-6 invisible"
                   src="/images/check-2.svg" />
              <i data-feather="help-circle"></i>
              <img id="cvv-check" className="absolute right-0 bottom-0 h-6 invisible"
                   src="/images/check-2.svg" />
              <i data-feather="help-circle"></i>
            </div>
          </div>
          <div className={'flex flex-col w-full'}>
            <button
              className={`payer_maintenant_btn boxShadow flex items-center ${
                !cardCVCComplete || !cardExpComplete || !cardNbComplete
                  ? 'pointer-events-none opacity-20'
                  : ''
              }`}
              disabled={!cardCVCComplete || !cardExpComplete || !cardNbComplete}
              onClick={handleSubmit}
            >
              {loading ? (
                <>
                    <Lottie
                      animationData={loadingPayment}
                      loop={true}
                      autoplay={true}
                      style={{
                        width: '20px',
                        height: '20px',
                        top: '2.5px',
                        position: 'relative'
                      }} // Adjust the width and height as needed
                    />
                </>
              ) : (
                <>
                  <p className="font-light" style={{ fontFamily: 'geomanistmedium' }}>Enregistrer</p>
                </>
              )}
            </button>
            {errorMessage && (
              <div className="flex justify-center bg-red-100 text-red-500 p-2 rounded-xl mt-2">
                <span className="text-red-500 font-bold text-xl text-center ">{errorMessage}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PaygreenConecsCard;