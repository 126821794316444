import React from 'react';
import closeArrow from '../../styles/img/account/closeArrow.svg';
import errorApplepayTRlogo from '../../styles/img/account/passwordLogo.svg';
import PrimaryButton from '../button/PrimaryButton';

const WrongPaymentMethod = ({ onClose, onClickPrimary }) => {
  return (
    <div className="applepay-tr-error">
      <button onClick={onClose} className="close-button">
        <img src={closeArrow} alt="close" className="close" />
      </button>
      <div>
        <img
          src={errorApplepayTRlogo}
          alt="pikkoLogo"
          className="pikkoLogo-phone-screen"
        />
      </div>
      <div>
        <h1 style={{ fontSize: "3em" }}>Mauvaise méthode de paiement choisie</h1>
        <h2 style={{ fontFamily: 'geomanistregular', color: '#353535', fontWeight: '400' }}>Choisi le titre restaurant
          directement !</h2>
      </div>

      <PrimaryButton
        onClick={onClickPrimary}
        label="Changer de moyen de paiemnt"
      />
    </div>
  );
};

export default WrongPaymentMethod;