import React, { useState } from 'react';
import scanIcon from '../../styles/img/Scan_icon.svg';
import PrimaryButton from './PrimaryButton';
import useAxiosInterceptors from '../../axios/useAxios';
import { notifyWarning } from '../../utils/Notify';
import { isAppPWA } from '../../utils/helper-functions';

const ScanButton = ({ onClick, label, store, editSelectedStore }) => {
  const axiosInstance = useAxiosInterceptors();

  const [isStoreLoading, setIsStoreLoading] = useState(false);

  const handleClick = () => {
    setIsStoreLoading(true);

    if (!store || !store.id) {
      editSelectedStore();
      return;
    }

    axiosInstance.get(`/store/${store.id}`)
      .then((response) => {
        if (response.data.live || !isAppPWA()) {
          onClick();
        } else {
          notifyWarning(
            'Magasin indisponible',
            'Ton magasin est momentanément indisponible. Réessaie plus tard ou sélectionne-en un autre depuis ton menu.',
            'Fermer',
          );
        }
      })
      .finally(() => setIsStoreLoading(false));
  };

  return (
    <PrimaryButton
      onClick={handleClick}
      label={label}
      icon={scanIcon}
      isLoading={isStoreLoading}
    />
  );
};

export default ScanButton;
