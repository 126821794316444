import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const StepBar = (step) => {
    const navigate = useNavigate()
    useEffect(() => {
      setTimeout( ()=>{ try{
            const element1=document.querySelector('.stepPanier')
            const element2=document.querySelector('.stepPromos')
            const element3=document.querySelector('.stepCommande')
            if(element1 && element2 && element3){
                if(step.step===1){
                    if(element1.classList.contains('backgray')){  element1.classList.remove('backgray')}
                    if(element2.classList.contains('backgray')){  element2.classList.remove('backgray')}
                   if(element3.classList.contains('pview')){  element3.classList.remove('pview')}
                     if(element2.classList.contains('pview')){  element2.classList.remove('pview')}
                    element1.classList.add('pview')

                    // document.querySelector('.stepPanier').classList.add('bouncess')
                    // setTimeout(() => {
                    //     document.querySelector('.stepPanier').classList.remove('bouncess')

                    // }, 1000);
                //    element1.classList.add('bouncess')

                //     setTimeout(() => {
                //        element1.classList.remove('bouncess')

                //     }, 1000);

                }
                if(step.step===2){

                    if(element2.classList.contains('backgray')){  element2.classList.remove('backgray')}

                    element1.classList.remove('pview')
                   element2.classList.add("pview")
                   element3.classList.remove('pview')





                //   element2.classList.add('bouncess')
                //     setTimeout(() => {
                //        element2.classList.remove('bouncess')

                //     }, 1000);

                }
                if(step.step===3){
                    element3.classList.remove('backgray')
                    element3.classList.add('pview')
                    element2.classList.remove('pview')
                    element1.classList.remove('pview')



                    // element3.classList.add('bouncess')
                    // setTimeout(() => {
                    //    element3.classList.remove('bouncess')

                    // }, 1000);
                   console.log("finish")
                }}
        }catch(e){
            console.log(e)
        }}, 1000);

    }, [step])

  const goTo= (step)=>{
    if(step===1){
        navigate('/promo')
    }
    if(step===2){
        navigate('/cart')
    }
}

   return (

    <div  className="stepContainer">
        <div  className=" containerStep stepPanier pview">

            <div className={step.step>=1?" stepbar active ":"stepbar pview"}></div>
            <p>Promos</p>
        </div>
        <div  className="containerStep stepPromos pview">

            <div className={step.step>=2?" stepbar active":"stepbar"}></div>
            <p>Paiement</p>
        </div>
        <div className="containerStep stepCommande pview">

            <div className={step.step>=3?" stepbar active":"stepbar"}></div>
            <p>Sortie</p>
        </div>



    </div>
   )

}
export default StepBar;
