import HandleConnectEmail from '../pages/account/login/HandleConnectEmail';
import { auth } from '../firebase/firebaseConfig';
import { sendEmailVerification } from 'firebase/auth';
import { authenticate } from '../actions/authenticateActions';
import { saveUser, saveUserWithFid } from '../actions/userActions';
import { confirmWarning, notifySuccess, notifyWarning } from './Notify';

const sendEmail = (result, auth) => {
  if (result.isConfirmed) {
    sendEmailVerification(auth.currentUser)
      .then(() => {
        notifySuccess(
          'Un mail de vérification a été envoyé  !',
          'Vérifie ta boite mail et clique sur le lien pour valider ton compte.',
          'Continuer'
        );
      })
  }
};

export const handleLogin = async (setLoading, email, password, user, cartItems, axiosInstance, dispatch, navigate, fidCardId) => {
  const domains = ['pikkopay.fr', 'elee.fr', 'ijo.tech']
  if (localStorage.getItem('newuser')) {
    localStorage.removeItem('newuser')
  }
  setLoading(true);

  try {
    const data = await HandleConnectEmail(auth, email, password, user)
    const emailParts = data.newResult.email.split('@');
    const emailDomain = emailParts[emailParts.length - 1];

      authenticate(axiosInstance, email, data.result.user.accessToken)
        .then(() => {
          if (fidCardId) {
            dispatch(saveUserWithFid(data.newResult, axiosInstance, cartItems, fidCardId, navigate));
          } else {
            dispatch(saveUser(data.newResult, axiosInstance, cartItems, navigate));
          }
        })
        .catch((error) => {
          if(error.response.data.errorCode === "USER_FID_CARD_ALREADY_USED"){
            notifyWarning('Oups', 'L\'email ne correspond à la carte de fidélité', 'Ok');
          }else{
            notifyWarning('Oups', 'Une erreur est survenue lors de ta connexion.', 'Ok');
          }
          console.error('Authenticate', error);
        })
        .finally(() => setLoading(false))
    // }
  } catch (e) {
    setLoading(false);
    console.error('handleSignIn', e);
  }
}
