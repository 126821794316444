import React, { useEffect, useState } from "react";
import fidCard from "../../styles/img/menu/fid-card.png";
import newsPaper from "../../styles/img/menu/news-paper.png";
import { useNavigate } from "react-router";
import {
  ACCOUNT,
  HOME,
  NUMBER_SCREEN,
  STORE_LIST,
  TICKETS,
  USER_FID_CARD,
} from "../../constants/routes";
import StoreSelectionButton from "../button/StoreSelectionButton";
import BackButton from "../button/BackButton";
import ProfilBox from "../card/ProfilBox";
import { disconnectUser } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebaseConfig";
import { notifySuccess } from "../../utils/Notify";
import { isAppPWA } from "../../utils/helper-functions";
import { FaRegBell, FaRegBellSlash } from "react-icons/fa";
import { useFcmTokenManager } from "../../utils/hooks";
import CustomLoader, { LoaderSize } from "../loader/CustomLoader";
import disconnectsvg from "../../styles/img/account/disconnect.svg";
import menuBurger from "../../styles/img/menu-burger.png";
import fusionLogo from "../../styles/img/account/fusionLogo.svg";
import changeStoreLogo from "../../styles/img/account/changeStoreLogo.svg";
import notif from "../../styles/img/account/notif.svg";
import notifOff from "../../styles/img/account/notifOff.svg";
import { isAllowToChangeStore } from "../../utils/Validator";
import { Synchronisation } from "../synchronisation";
import InstallPWA from "../InstallPwa";

const NavigationMenu = ({ isOpen, onClose, user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {store} = useSelector((state) => state.store);
  const [isVisible, setIsVisible] = useState(false);
  // hooks
  const isPWA = isAppPWA();
  const {
    isLoading: isLoadingFcm,
    isNotificationEnabled,
    registerFcmToken,
    deleteFCMToken,
  } = useFcmTokenManager();
  useEffect(() => {
    console.log(user.email, user.phoneNumber);
  }, [user]);
  const handleSignOut = async () => {
    dispatch(disconnectUser());
    await signOut(auth);
    localStorage.removeItem("popupUser");
    notifySuccess(
      "Tu as été déconnecté ...",
      "Nous espérons te revoir très vite !",
      "D'accord",
      () => {
        if (isPWA) {
          navigate(NUMBER_SCREEN);
        } else {
          navigate(HOME);
        }
      }
    );
  };

  const goToProfile = () => {
    // TODO
  };
  const onClickChangeStore = () => {
    if(store && store.name){
    if (isAllowToChangeStore(store)) navigate(STORE_LIST, { state: { prevPage: ACCOUNT } });}

  };
  return (
    <>
      {isOpen && <div className="mask" onClick={onClose} />}
      <div className={`navigation-menu ${isOpen ? "open" : ""}`}>
        <div className="menu">
          <div className="flex items-center justify-between"> 
            <img src={menuBurger} alt="menu" className="menu-burger w-12" onClick={onClose} />
            <div className="flex items-center gap-3">
            {
               store && store.name && isAllowToChangeStore(store) && (
                  <div className="navIcons" onClick={onClickChangeStore}>
                    <img src={changeStoreLogo} alt="changeStore" />
                  </div>
                )

              }
            {isPWA &&(isLoadingFcm ? (
                <div  className="navIcons">
                  <CustomLoader loaderSize={LoaderSize.SMALL} />
                </div>
              ) : isNotificationEnabled ? (
                <div  className="navIcons" onClick={deleteFCMToken}>
                    <img src={notif} alt="notif" />  
                </div>
               
              ) : (
                <div className="navIcons" onClick={registerFcmToken}>
                    <img src={notifOff} alt="notifOff" />
                </div>
              ))}
              <button
                className="deconnect-button"
                onClick={() => handleSignOut()}
              >
                <img src={disconnectsvg} alt="disconnect" />
              </button>
              
            </div>
          </div>
          <ProfilBox user={user} click={goToProfile} />
          <div className="navigation-liste">
            <div
              className="navigation-item"
              onClick={() => navigate(USER_FID_CARD, { state: {prevPage: ACCOUNT} })}
            >
              <img className="nav-icon" src={fidCard} alt="" />
              <div className="navigation-label">Cartes fidélités</div>
            </div>
            <div className="navigation-item" onClick={() => navigate(TICKETS)}>
              <img className="nav-icon" src={newsPaper} alt="" />
              <div className="navigation-label">Mes tickets</div>
            </div>
            {/*<div className="navigation-item"  onClick={() => navigate(WALLET)}>*/}
            {/*  <img className="nav-icon" src={wallet} alt="" />*/}
            {/*  <div className="navigation-label">My wallet</div>*/}
            {/*</div>*/}
          </div>
          {/*<div className="navigation-item-help">*/}
          {/*  <img className="nav-icon" src={question} alt="" />*/}
          {/*  <div*/}
          {/*    className="navigation-label"*/}
          {/*  >*/}
          {/*    Besoin d'aide ?*/}
          {/*  </div>*/}
          {/*</div>*/}
         <div className={`navigation-btn ${isPWA ? "pwa" : ""}`}>
            {!isPWA && (
                <InstallPWA/>
              )}
            {user && user.phoneNumber && user.email ? <></>:

              <>
                <Synchronisation
                user={user}
                auth={auth}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
              />
              <div className="synchroBtn" onClick={() => setIsVisible(true)}>

                <img src={fusionLogo} alt="synchro" />
                Synchroniser avec un ancien compte
              </div>
              </>
            }
          </div>
          {/* <StoreSelectionButton originPath={ACCOUNT} /> */}
     
        </div>
      </div>
    </>
  );
};

export default NavigationMenu;
