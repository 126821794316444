import {
    PAY_SELECT_METHOD_FAIL,
    PAY_SELECT_METHOD_REQUEST,
    PAY_SELECT_METHOD_SUCCESS,
} from "../constants/payMethodConstants";

export const selectPayment = (payMethod) => async (dispatch, getState) => {
    dispatch({ type: PAY_SELECT_METHOD_REQUEST });
    try {
      dispatch({ type: PAY_SELECT_METHOD_SUCCESS, payload: payMethod });
      localStorage.setItem("payMethod", JSON.stringify(getState().payMethod));
    } catch (error) {
      console.log(error);
      dispatch({ type: PAY_SELECT_METHOD_FAIL });
    }
  };

