import React from 'react';
import star from '../styles/img/star.svg';
import staractive from '../styles/img/staractive.svg';
import halfStar from '../styles/img/halfStar.svg';
// Star component
const Star = ({ selected, starClassName, onClick, isHalf }) => {
  return (
    <span onClick={onClick} style={{ cursor: 'pointer' }} className='h-fit'>
    {
      isHalf ? <img src={halfStar} alt="star" className={` ${starClassName?starClassName:'w-14 m-2'}`} />
      :selected ? <img src={staractive} alt="star" className={` ${starClassName?starClassName:'w-14 m-2'}`} /> : <img src={star} alt="star" className={` ${starClassName?starClassName:'w-14 m-2'}`} />}
  </span>
  );
};

const RatingStarFixe = ({ starClassName, rating }) => {
  // Calculate the number of full stars and check for half star
  const fullStars = Math.floor(rating); // Get the full stars
  const hasHalfStar = rating % 1 >= 0.5; // Check for half star

  const stars = Array.from({ length: 5 }, (_, index) => {
    const isFullStar = index < fullStars;
    const isHalfStar = hasHalfStar && index === fullStars; 
    return (
      <Star
        key={index}
        selected={isFullStar || isHalfStar} // Mark it as selected if it's a full or half star
        starClassName={starClassName}
        isHalf={isHalfStar} // Pass isHalf prop to indicate half star
      />
    );
  });

  return (
    <div className=' text-5xl flex items-center justify-center'>
    <div
      style={{ display: 'flex', flexDirection: 'row' }}
    >
      {stars}
    </div>
    {/* <p>{rating} étoile(s) sélectionnée(s)</p> */}
  </div>
  );
};



export default RatingStarFixe;
