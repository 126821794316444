import gift from '../styles/img/account/Gift.svg'
import home_pikko from '../styles/img/account/home_pikko.png'
import tickets from '../styles/img/account/tickets.svg'
import changeStoreLogo from '../styles/img/account/changeStoreLogo.svg'
import wallet from '../styles/img/account/wallet.svg'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ACCOUNT, HOME, REWARD, TICKETS, USER_FID_CARD } from '../constants/routes'
import { isAppPWA } from '../utils/helper-functions'
import { useSelector } from 'react-redux'
export const FooterAccount = ({selectedItem, setSelectedItem}) => {
  
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const isPWA = isAppPWA();
    useEffect(() => {
      if(isPWA){
        switch (selectedItem) {
            case 0:
                navigate(TICKETS)
                break;
            case 1:
                navigate(USER_FID_CARD , {state: {prevPage: ACCOUNT}})
                break;
            case 2:
                navigate(HOME)
                break;
            case 3:
                navigate(REWARD)
                break;
            case 4:

                navigate(ACCOUNT)
                break;
            default:
                break;
        }
      }
    }, [selectedItem])

    return (
       isPWA && user && user._id ? <div className="footer-account">
         <div className={`logo-footer ${selectedItem === 0 ? 'selected' : ''}`} onClick={() => setSelectedItem(0)}>
            <img src={tickets} alt="tickets" />
            <div className={`item-name ${selectedItem === 0 ? 'selected' : ''}`} >
                Tickets
            </div>
         </div>
         <div className={`logo-footer ${selectedItem === 1 ? 'selected' : ''}`} onClick={() => setSelectedItem(1)}>
                <img src={wallet} alt="wallet" />
                <div className={`item-name ${selectedItem === 1 ? 'selected' : ''}`} >
                    Carte
                </div>
        </div>
         <div className={`logo-footer ${selectedItem === 2 ? 'selected' : ''}`} onClick={() => setSelectedItem(2)}>
            <img src={changeStoreLogo} alt="scanner_bar" />
            <div className={`item-name ${selectedItem === 2 ? 'selected' : ''}`} >
                Magasins
            </div>
         </div>
         <div className={`logo-footer ${selectedItem === 3? 'selected' : ''}`} onClick={() => setSelectedItem(3)}>
            <img src={gift} alt="gift" />
            <div className={`item-name ${selectedItem === 3 ? 'selected' : ''}`} >
                Cadeaux
            </div>
         </div>
         <div className={`logo-footer big ${selectedItem === 4 ? 'selected' : ''}`} onClick={() => setSelectedItem(4)}>
            <img src={home_pikko} alt="gift" />
            <div className={`item-name ${selectedItem === 4 ? 'selected' : ''}`} >
                Profil
            </div>
         </div>
      
        </div>:<></>

    );
    }

