import {
  ADD_USER_APPLEPAY_EMAIL_FAIL,
  ADD_USER_APPLEPAY_EMAIL_REQUEST,
  ADD_USER_APPLEPAY_EMAIL_SUCCESS,
  ADD_USER_COUNT_FAIL,
  ADD_USER_COUNT_REQUEST,
  ADD_USER_COUNT_SUCCESS,
  ADD_USER_FAIL,
  ADD_USER_ID_FAIL,
  ADD_USER_ID_REQUEST,
  ADD_USER_ID_SUCCESS,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_USEDPROMO_FAIL,
  ADD_USER_USEDPROMO_REQUEST,
  ADD_USER_USEDPROMO_SUCCESS,
  DISCONNECT_USER_FAIL,
  DISCONNECT_USER_REQUEST,
  DISCONNECT_USER_SUCCESS,
  FIND_USER_FAIL,
  FIND_USER_REQUEST,
  FIND_USER_SUCCESS, MODIFY_USER_FAIL, MODIFY_USER_REQUEST, MODIFY_USER_SUCCESS,
  SAVE_USER_FAIL,
  SAVE_USER_REQUEST,
  SAVE_USER_SUCCESS,
  SEND_USER_PROMOCODE_FAIL,
  SEND_USER_PROMOCODE_REQUEST,
  SEND_USER_PROMOCODE_SUCCESS,
  USER_CB_CARD_FAIL,
  USER_CB_CARD_REQUEST,
  USER_CB_CARD_SUCCESS, USER_CB_PAYZEN_CARD_SUCCESS,
  USER_CB_REMOVE_CARD_FAIL,
  USER_CB_REMOVE_CARD_REQUEST,
  USER_CB_REMOVE_CARD_SUCCESS,
  USER_CLAIM_GIFT_FAIL,
  USER_CLAIM_GIFT_REQUEST,
  USER_CLAIM_GIFT_RESET,
  USER_CLAIM_GIFT_SUCCESS,
  USER_CONECS_BALANCE_FAIL,
  USER_CONECS_BALANCE_REQUEST,
  USER_CONECS_BALANCE_SUCCESS,
  USER_CONECS_REMOVE_FAIL,
  USER_CONECS_REMOVE_REQUEST,
  USER_CONECS_REMOVE_SUCCESS,
  USER_CONECS_USERINFO_FAIL,
  USER_CONECS_USERINFO_REQUEST,
  USER_CONECS_USERINFO_SUCCESS,
  USER_EDENRED_BALANCE_FAIL,
  USER_EDENRED_BALANCE_REQUEST,
  USER_EDENRED_BALANCE_SUCCESS,
  USER_EDENRED_BALANCE_TIMEOUT,
  USER_EDENRED_REMOVE_FAIL,
  USER_EDENRED_REMOVE_REQUEST,
  USER_EDENRED_REMOVE_SUCCESS,
  USER_EDENRED_TOKEN_REFRESH,
  USER_EDENRED_USERINFO_FAIL,
  USER_EDENRED_USERINFO_REQUEST,
  USER_EDENRED_USERINFO_SUCCESS,
  USER_GET_GIFT_FAIL,
  USER_GET_GIFT_REQUEST,
  USER_GET_GIFT_RESET,
  USER_GET_GIFT_SUCCESS,
  USER_INITIAL_PROMO_FAIL,
  USER_INITIAL_PROMO_REQUEST,
  USER_INITIAL_PROMO_SUCCESS,
  USER_PURCHASE_GIFT_CARD_SUCCESS, USER_PURCHASE_PROMO
} from '../constants/userConstants';


export const addUserReducer = (state = {}, action) => {
  const loadingTrue = {loading: true}
  const loadingFalse = {loading: false}
    switch (action.type) {
      case ADD_USER_REQUEST:
        return { ...state };
      case ADD_USER_SUCCESS:
        return { ...state, nom: action.payload.nom, prenom: action.payload.prenom ,email: action.payload.email, usedPromo: []};
      case ADD_USER_FAIL:
        return { ...state };
      case ADD_USER_APPLEPAY_EMAIL_REQUEST:
        return { ...state };
      case ADD_USER_APPLEPAY_EMAIL_SUCCESS:
        return { ...state, email: action.payload.email};
      case ADD_USER_APPLEPAY_EMAIL_FAIL:
        return { ...state };
      case ADD_USER_ID_REQUEST:
        return { ...state };
      case ADD_USER_ID_SUCCESS:
        return {...state, id: action.payload.id};
      case ADD_USER_ID_FAIL:
        return { ...state };
      case ADD_USER_COUNT_REQUEST:
        return {...state}
      case ADD_USER_COUNT_SUCCESS:
          return {...state, count: action.payload}
      case ADD_USER_COUNT_FAIL:
        return {...state}
      case SAVE_USER_REQUEST:
        return {...state, loading: true}
      case SAVE_USER_SUCCESS:
        return {...action.payload, loading: false, success: true, exist : true}
      case SAVE_USER_FAIL:
        return {...state, loading: false, success: false, exist: false}
      case FIND_USER_REQUEST:
        return {...state, userLoading: true}
      case FIND_USER_SUCCESS:
        return {...action.payload , userLoading: false}
      case FIND_USER_FAIL:
        return {...state, exist: false, userLoading: false}
      case SEND_USER_PROMOCODE_REQUEST:
        return {...state, loading: true}
      case SEND_USER_PROMOCODE_SUCCESS:
        return {...state, exist: true, loading: false, success: true}
      case SEND_USER_PROMOCODE_FAIL:
        return {...state, loading: false, success: true}
      case DISCONNECT_USER_REQUEST:
        return {...state}
      case DISCONNECT_USER_SUCCESS:
        return {usedPromo: action.payload.usedPromo || {}, appliedPromo: action.payload.appliedPromo || {}, currentPromo: action.payload.currentPromo || {}}
      case DISCONNECT_USER_FAIL:
        return {}
      case MODIFY_USER_REQUEST:
        return {...state, userLoading: true}
      case MODIFY_USER_SUCCESS:
        return { ...action.payload, userLoading: false}
      case MODIFY_USER_FAIL:
        return { ...state, userLoading: false}
      //CB
      case USER_CB_CARD_REQUEST:
        return { ...state,loading:true, error:undefined}
      case USER_CB_CARD_SUCCESS:
        action.payload.loading = false
        action.payload.success = true
        return {...state, loading:false, error:false, creditCard: action.payload}
      case USER_CB_PAYZEN_CARD_SUCCESS:
        return {...state, payzenCreditCards: action.payload}
      case USER_CB_CARD_FAIL:
          return {...state, error: true, loading: false}
      case USER_CB_REMOVE_CARD_REQUEST:
        return {...state , loading: true}
      case USER_CB_REMOVE_CARD_SUCCESS:
          return {...state, creditCard: {}, loading: false}
      case USER_CB_REMOVE_CARD_FAIL:
          return {...state , creditCard: {}, loading: false}
      // EDENRED
      case USER_EDENRED_USERINFO_REQUEST:
        return { ...state, edenred: {...state.edenred, loading: true}}
      case USER_EDENRED_USERINFO_SUCCESS:
        action.payload.loading = false
        return {...state, edenred: action.payload}
      case USER_EDENRED_USERINFO_FAIL:
      return { ...state, edenred : loadingFalse}
      case USER_EDENRED_REMOVE_REQUEST:
        return { ...state, edenred : loadingTrue}
      case USER_EDENRED_REMOVE_SUCCESS:
        return {...state, edenred: {}}
      case USER_EDENRED_REMOVE_FAIL:
        return {...state, edenred: {}}
      case USER_EDENRED_BALANCE_REQUEST:
        console.log(state.edenred)
        return { ...state, edenred: {...state.edenred, loading: true}}
      case USER_EDENRED_BALANCE_SUCCESS:
        return {
          ...state,
          edenred: {
            ...state.edenred,
            balance: action.payload.balance,
            access_token: action.payload.accessToken,
            refresh: action.payload.refreshToken,
            loading: false,
          }
        };
      case USER_EDENRED_BALANCE_TIMEOUT:
        return {...state, edenred: { ...state.edenred, loading: false}}
      case USER_EDENRED_BALANCE_FAIL:
        return {...state, edenred: {}}
      case USER_EDENRED_TOKEN_REFRESH:
        return { ...state, edenred: { ...state.edenred, access_token: action.payload.accessToken, refresh: action.payload.refreshToken } };
      //CONECS
      case USER_CONECS_USERINFO_REQUEST:
          return { ...state, conecs: {...state.conecs, loading: true}}
      case USER_CONECS_USERINFO_SUCCESS:
          action.payload.loading = false
          return {...state, conecs: action.payload}
      case USER_CONECS_USERINFO_FAIL:
          return { ...state, conecs: {...state.conecs, loading: true}}
      case USER_CONECS_BALANCE_REQUEST:
          return { ...state, conecs: {...state.conecs, loading: false}}
      case USER_CONECS_BALANCE_SUCCESS:
        if(state.conecs.buyerId && state.conecs.buyerId.length > 0){
            return { ...state, conecs: {...state.conecs, balance: action.payload, loading: false}}
          }
          else {
              localStorage.removeItem('Conecs')
          }
      case USER_CONECS_BALANCE_FAIL:
          return {...state, conecs: {}}
      case USER_CONECS_REMOVE_REQUEST:
          return{...state, conecs: {...state.conecs, loading: true}}
      case USER_CONECS_REMOVE_SUCCESS:
          return {...state, conecs: {}}
      case USER_CONECS_REMOVE_FAIL:
          return {...state, conecs: {}}
      //PROMO
      case USER_INITIAL_PROMO_REQUEST:
          return {...state}
      case USER_INITIAL_PROMO_SUCCESS:
      return {...state, currentPromo: action.payload.currentPromo, appliedPromo: action.payload.appliedPromo, usedPromo: action.payload.usedPromo}
      case USER_INITIAL_PROMO_FAIL:
          return {...state}
      case ADD_USER_USEDPROMO_REQUEST:
          return {...state}
      case ADD_USER_USEDPROMO_SUCCESS:
          console.log(action)
            return {...state, currentPromo: action.payload.currentPromo, appliedPromo: action.payload.appliedPromo, usedPromo: action.payload.usedPromo}
            return action.payload
      case ADD_USER_USEDPROMO_FAIL:
          return {...state}
      case USER_GET_GIFT_REQUEST:
          return {...state, userLoading: true}
      case USER_GET_GIFT_SUCCESS:
        return {...action.payload, userLoading: false, userGetGift: true}
      case USER_GET_GIFT_FAIL:
        return {...state, userLoading: false}
      case USER_PURCHASE_PROMO:
        return {...action.payload}
      case USER_GET_GIFT_RESET:
        return {...state, userGetGift: false, userClaimGift: false}
      case USER_CLAIM_GIFT_REQUEST:
        return {...state, userLoading: true}
      case USER_CLAIM_GIFT_SUCCESS:
        return {...action.payload, userLoading: false, userClaimGift: true}
      case USER_CLAIM_GIFT_FAIL:
        return {...state, userLoading: false, userClaimGift: false}
      case USER_CLAIM_GIFT_RESET:
        return {...state, userClaimGift: false}
      case USER_PURCHASE_GIFT_CARD_SUCCESS:
        return {...action.payload}

      default:
        return state
    }
  };
