import { SCAN_DISPLAY } from "../constants/scanConstants";

export const scanReducers = (state = {}, action) =>{
    switch(action.type){

        case SCAN_DISPLAY:
            return {display: action.payload};
        default: 
            return state
    }
}