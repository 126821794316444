import { useDispatch, useSelector } from 'react-redux';
import useAxiosInterceptors from '../axios/useAxios';
import ComapniesList from '../components/ComapniesList';
import React, { useEffect, useState } from 'react';
import { STORE_RESET, STORE_UNAVAILABLE_ALERT_TRIGGER } from '../constants/storeConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { createCart } from '../actions/cartActions';
import { CART_CREATE_RESET } from '../constants/cartConstants';
import PageLoader from '../components/loader/PageLoader';
import { HOME } from '../constants/routes';
import { notifyWarning } from '../utils/Notify';
import { isAppPWA } from '../utils/helper-functions';

const StoreListScreen = () => {
    const axiosInstance = useAxiosInterceptors();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { store } = useSelector((state) => state.store);
    const user = useSelector(state => state.user);

    const {cartItems, success, loading} = useSelector((state) => state.store)
    const [reset, setReset] = useState(false);

    const storeLocked = location.state?.storeLocked;
    const prevPage = location.state?.prevPage;

    useEffect(() => {
      setReset(true);
      const storeAlert = JSON.parse(localStorage.getItem(STORE_UNAVAILABLE_ALERT_TRIGGER));
      if (storeLocked && storeAlert && !storeAlert.isTriggered) {
        localStorage.removeItem('store');
        notifyWarning(
          'Magasin indisponible',
          'Le magasin auquel tu souhaites accéder est indisponible pour le moment. Choisis-en un autre.',
          'Fermer',
          () => localStorage.setItem(STORE_UNAVAILABLE_ALERT_TRIGGER, JSON.stringify({ isTriggered: true }))
        );
      }
    }, []);


  useEffect(() => {
      if (store && store.live && reset) {
        const orderCreate = { storeId: store.id, clientId: user?._id || "" };
        try {
          dispatch(createCart(orderCreate, axiosInstance));
        } catch (e) {
          console.log(e);
        }
      }
    }, [store]);

  useEffect(() => {
    console.log(success);
    if (!success || !reset) {
      return;
    }
    if(success && store.live===false) {
      notifyWarning(
        'Magasin indisponible',
        'Le magasin auquel tu souhaites accéder est indisponible pour le moment. Choisis-en un autre.',
        'Fermer',
        () => localStorage.setItem(STORE_UNAVAILABLE_ALERT_TRIGGER, JSON.stringify({ isTriggered: true }))
      );
    }
    else if (prevPage) {
      navigate(prevPage);
    } else {
      navigate(HOME);
    }
  }, [success]);

    return (
        <>
          {loading
            ? (<PageLoader/>)
            : (<ComapniesList axiosInstance={axiosInstance}/>)
          }
        </>
    );
};

export default StoreListScreen;
