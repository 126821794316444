import 'react-spring-bottom-sheet/dist/style.css';
import 'react-toastify/dist/ReactToastify.css';
import { faSyncAlt, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PullToRefresh from 'pulltorefreshjs'
import ReactDOMServer from 'react-dom/server';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';
import './styles/index.scss';
import { AuthProvider } from './context/authProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as SDCCore from 'scandit-web-datacapture-core';
import * as SDCBarcode from 'scandit-web-datacapture-barcode';
import { configure } from 'scandit-sdk';
import { BrowserRouter } from 'react-router-dom';
import { isAppPWA } from './utils/helper-functions';
import { NetworkProvider } from './utils/NetworkStatusProvider';

// window.isScanditConfigured = false;
//
// // Charger la bibliothèque Scandit au préalable
// const loadScanditLibrary = async () => {
//   try {
//     await SDCCore.configure({
//       licenseKey: process.env.REACT_APP_SCANDIT_KEY,
//       libraryLocation: new URL(
//         "https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.25.0/build/engine/",
//         document.baseURI
//       ).toString(),
//       moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
//     });
//     window.isScanditConfigured = true;
//     console.log("Scandit library loaded successfully v6.x");
//   } catch (error) {
//     console.error("Error loading Scandit library:", error);
//   }
// };
//
// const loadScanner = async () => {
//   const licenseKey = process.env.REACT_APP_SCANDIT_KEY;
//   await configure(licenseKey, {
//     engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/",
//   })
//     .then(() => {
//       console.log("configure scanner success v5.x");
//     })
//     .catch((error) => {
//       alert(error);
//     });
// };
//
// const isPWA = isAppPWA();
//
// if (isPWA) {
//   // load scanner-test
//   setTimeout(async () => {
//     await loadScanditLibrary();
//   }, 500)
// } else {
//   // load scanner
//   setTimeout(async () => {
//     await loadScanner();
//   }, 500)
// }


ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <Provider store={store}>
        {/*<GoogleReCaptchaProvider reCaptchaKey="6LfjOt0pAAAAAFcEvh1DMhOaK2d-2f5o-J3Wa_cc">*/}
        <NetworkProvider>
          <App />
        </NetworkProvider>
        {/*</GoogleReCaptchaProvider>*/}
      </Provider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

navigator?.serviceWorker
         ?.getRegistrations()
         .then((registrationsArray) => {
           if (registrationsArray && registrationsArray[0]?.update())
             registrationsArray[0].update();
         })
         .catch((e) => {
           console.log(e);
         });

reportWebVitals();

serviceWorkerRegistration?.register({
                                      onUpdate: (registration) => {
                                        if (registration.waiting) {
                                          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                                          // window.location.reload();
                                        }
                                      },
                                    });

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
             .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
             .then((registration) => {
               console.log(
                 'firebase-messaging-sw.js service worker registration successful with scope: ',
                 registration.scope
               );
             })
             .catch((err) => {
               console.log(
                 'firebase-messaging-sw.js service worker registration failed: ',
                 err
               );
             });
  });
}
