import Swal from 'sweetalert2';

export const notifyInfo = (title) => {
  Swal.fire({
              title,
              icon: 'info',
              showCancelButton: false,
              confirmButtonText: `Ok`,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'w-[100px] h-[40px] payer rounded-[10px] geomanistBold text-[1.4rem] p-4',
              }
            })
}
export const notifyError = (title, text, callBack) => {

  Swal.fire({
              title,
              text,
              icon: 'error',
              closeButtonHtml: 'X',
              confirmButtonColor: '#FFD700',
              confirmButtonText: 'Ok',
              buttonsStyling: false,
              customClass: {
                title: 'title-class',
                confirmButton: 'bg-yellow-400 payer w-[100px] text-center h-16 geomanistBold text-[1.7rem] rounded-md',
              }
            })
      .then(() => {
        if (callBack) {
          callBack();
        }
      })
};

export const notifyWarning = (title, text, buttonLabel, callBack) => {
  Swal.fire({
              title,
              text,
              icon: 'warning',
              showConfirmButton: true,
              confirmButtonText: buttonLabel || 'Fermer',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'bg-[#16c72e] p-4 !rounded-md payer w-full geomanistBold text-[1.5rem]',
              },
            })
      .then(() => {
        if (callBack) {
          callBack();
        }
      })
};

export const confirmWarning = (title, text, confirmText, callBack) => {
  Swal.fire({
              title,
              text,
              icon: 'warning',
              showConfirmButton: true,
              showLoaderOnConfirm: true,
              showCancelButton: true,
              cancelButtonText: 'Fermer',
              confirmButtonText: confirmText || 'Ok',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'bg-[#16c72e] p-4 !rounded-md payer w-[90%] geomanistBold text-[1.5rem] ',
                cancelButton: 'bg-[#807f7f] p-4 rounded-md text-white w-[90%] geomanistBold my-4 text-[1.5rem] ',
              },
            })
      .then((result) => {
        if (result.isConfirmed && callBack) {
          callBack(result);
        }
      })
};

export const notifySuccess = (title, text, buttonLabel, callBack) => {
  Swal.fire(
    {
      title,
      text,
      icon: 'success',
      showConfirmButton: true,
      confirmButtonText: buttonLabel || 'Ok',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'bg-[#16c72e] p-4 !rounded-md payer w-full geomanistBold text-[1.5rem]',
      },
    }
  )
      .then(() => {
        if (callBack) {
          callBack();
        }
      })
};

export const notifyConfirmation = (title, text, confirmLabel, cancelLabel, callBack, icon) => {
  Swal.fire(
    {
      title,
      text,
      icon,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: confirmLabel,
      cancelButtonText: cancelLabel,
      buttonsStyling: false,

      customClass: {
        confirmButton: 'notify-button notify-button--confirm',
        cancelButton: 'notify-button notify-button--cancel',
        htmlContainer: 'notify-label'
      },
    }
  )
      .then((result) => {
        if (result.isConfirmed && callBack) {
          callBack();
        }
      })
};

export const notifyDoubleCallback = (title, text, icon, confirmLabel, cancelLabel, titleConfirm, textConfirm, confirmCallback, titleCancel, textCancel, cancelCallback) => {
  const swalWithBootstrapButtons = Swal.mixin({
                                                customClass: {
                                                  confirmButton: 'bg-[#16c72e] p-4 !rounded-md payer w-full geomanistBold text-[1.5rem]',
                                                  cancelButton: 'bg-[#807f7f] p-4 rounded-md text-white w-[90%] geomanistBold my-4 text-[1.5rem] ',
                                                  htmlContainer: 'notify-label'
                                                },
                                                buttonsStyling: false
                                              });
  swalWithBootstrapButtons.fire({
                                  allowOutsideClick: false,
                                  title: title,
                                  text: text,
                                  icon: icon,
                                  showCancelButton: true,
                                  confirmButtonText: confirmLabel,
                                  cancelButtonText: cancelLabel,
                                  reverseButtons: true
                                }).then((result) => {
    if (result.isConfirmed && confirmCallback) {
      confirmCallback()
      swalWithBootstrapButtons.fire({
                                      title: titleConfirm,
                                      text: textConfirm,
                                      icon: 'success'
                                    });
    } else if (
      result.dismiss === Swal.DismissReason.cancel
    ) {
      cancelCallback()
      swalWithBootstrapButtons.fire({
                                      title: titleCancel,
                                      text: textCancel,
                                      icon: 'info'
                                    });
    }
  });
}
