import { getAdditionalUserInfo, getRedirectResult } from 'firebase/auth';

const GetUserInfo = async (auth, user) => {
  try {
    console.log(auth)
    console.log(user)
    const result = await getRedirectResult(auth);
    // Obtenir les informations supplémentaires de l'utilisateur
    const additionalUserInfo = getAdditionalUserInfo(result);
    // Copier les informations d'utilisateur existantes
    const newResult = { ...additionalUserInfo };
    if (newResult.profile && Object.keys(newResult.profile).length > 0) {
      // Copier les informations d'utilisateur supplémentaires de l'utilisateur actuel
      if (user.creditCard && user.creditCard[0] && Object.keys(user.creditCard[0]).length > 0) {
        const newCreditCard = {
          id: user.creditCard[0].id,
          brand: user.creditCard[0].brand,
          last4: user.creditCard[0].last4,
          pm: user.creditCard[0].pm,
          img: user.creditCard[0].img
        };
        newResult.profile.creditCard = newCreditCard;
      }

      if (user.payzenCreditCards && user.payzenCreditCards[0] && Object.keys(user.payzenCreditCards[0]).length > 0) {
        const newPayzenCreditCard = {
          brand: user.payzenCreditCards[0].brand,
          last4: user.payzenCreditCards[0].last4,
          pm: user.payzenCreditCards[0].pm,
          img: user.payzenCreditCards[0].img
        }
        newResult.profile.payzenCreditCards = newPayzenCreditCard
      }

      if (user.currentPromo && Object.keys(user.currentPromo).length > 0) {
        newResult.profile.currentPromo = user.currentPromo;
      } else {
        newResult.profile.currentPromo = [];
      }

      if (user.appliedPromo && Object.keys(user.appliedPromo).length > 0) {
        newResult.profile.appliedPromo = user.appliedPromo;
      }

      if (user.conecs && Object.keys(user.conecs).length > 0) {
        newResult.profile.conecs = user.conecs;
      }

      if (user.usedPromo && Object.keys(user.usedPromo).length > 0) {
        console.log(user.usedPromo);
        console.log(Object.keys(user.usedPromo).length);
        newResult.profile.usedPromo = user.usedPromo;
      }

      if (user.edenred && Object.keys(user.edenred).length > 0) {
        newResult.profile.edenred = user.edenred;
      }
      newResult.profile.fidCard = user?.fidCard || []

      return { result, newResult };
    } else {
      console.log('No profile found');
    }

  } catch (error) {
    console.log(error);

  }
}

export default GetUserInfo;
