//create skeleton component 

import React from 'react';

export const Skeleton = ({width,height,backgroundType,children}) => {
    const background = backgroundType === 'light' ? '#f4f3ed' : '#e7e7e7';
    
    return (
        <div style={{width,height,background,borderRadius:'1em',overflow:'hidden',position:'relative'}} className='skeleton-anim'>
            {children}
        </div>
    )
}