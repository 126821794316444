import React, { useState } from 'react';

const closeAlert = () => {
    document.querySelector('.Alert_response_background').style.display = 'none';
}

function Alert_promo() {

  return (
    <div className='Alert_response_background'>
    <div className='Alert_response'>
      <p></p>
      <button onClick={closeAlert}> ok</button>
    </div>
    </div>
  );
}

export default Alert_promo;
