import React from 'react';
import CustomLoader, { LoaderSize } from '../loader/CustomLoader';

const SecondaryButton = ({ onClick, disabled, label, isLoading, icon }) => {
    return (
      <button
        className={`secondary-button ${disabled || isLoading ? 'disabled' : ''}`}
        onClick={onClick}
        disabled={disabled || isLoading}
      >
        {isLoading
          ? (<CustomLoader loaderSize={LoaderSize.SMALL} />)
          : (
            <>
              {icon && <img className="icon-button" src={icon} alt="" />}
              <span className="label">{label}</span>
            </>
          )
        }
      </button>
    );
};

export default SecondaryButton;
