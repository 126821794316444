import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/button/PrimaryButton";
import { ACCOUNT, HOME } from "../../../constants/routes";
import MobileIcon from "../../../styles/img/mobile-icon.svg";
import RedBellIcon from "../../../styles/img/red-bell-icon.svg";
import { useFcmTokenManager } from "../../../utils/hooks";

const PushNotifications = () => {
  // state
  const [isLoading, setIsLoading] = useState(false);

  // router
  const navigate = useNavigate();

  // custom hooks
  const { registerFcmToken, deleteFCMToken } = useFcmTokenManager();

  const handleEnableNotification = async () => {
    try {
      setIsLoading(true);
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Permission granted");
        const res = await registerFcmToken();
        console.log("token registration -->", res);
        handleNavigateAccount();
      } else {
        console.log("Permission denied");
        handleNavigateAccount();
      }
    } catch (error) {
      console.error("Error getting notification permission:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeclinedNotification = async () => {
    try {
      setIsLoading(true);
      const res = await deleteFCMToken();
      console.log("deleted fcm token", res);
      handleNavigateAccount();
    } catch (error) {
      console.log("error deleting fcm token", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNavigateAccount = () => {
    navigate(HOME);
  };

  const onSubmit = (userSelection) => {
    if (userSelection === "reject") {
      handleDeclinedNotification();
    } else {
      handleEnableNotification();
    }
  };

  return (
    <>
      <div className="flex flex-col flex-grow">
        <img src={RedBellIcon} className="w-20 h-20" alt="store_icon" />
        <h2 className="mt-2 text-3xl">Rappel de notre app !</h2>
        <p className="mt-5 text-lg">
          Pour ne pas louper de couper l’attente, on te rappellera devant ton
          magasin.
        </p>
        <div className="flex justify-center mt-14">
          <img src={MobileIcon} alt="mobile_icon" />
        </div>
      </div>
      <div className="flex flex-col gap-6 mt-4">
        <PrimaryButton
          disabled={isLoading}
          onClick={() => onSubmit("accept")}
          label={isLoading ? "Chargement..." : "Oui, me notifier"}
        />
        <div
          onClick={() => onSubmit("reject")}
          className="py-6 text-center rounded-full cursor-pointer bg-[#353535]/10 text-[16px] font-[geomanistmedium]"
        >
          Pas maintenant
        </div>
      </div>
    </>
  );
};

export default PushNotifications;
