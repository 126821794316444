import React from "react";
import Lottie from "lottie-react";
import pikkoLogo from "../../../styles/img/logostatic.svg";
import eye from "../../../styles/lottie/eye.json";
import eyeOff from "../../../styles/lottie/eye-off.json";
import PrimaryButton from "../../../components/button/PrimaryButton";
import OtpInput from "react18-input-otp";
import { notifyError } from "../../../utils/Notify";
import { HOME, NUMBER_SCREEN } from "../../../constants/routes";
import BackButton from "../../../components/button/BackButton";
import { useNavigate } from "react-router";

export const PhonePasswordSignUp = ({
  valider,
  backFunction,
  handleNewPassword,
  handleNewCheckPassword,
}) => {
  const navigate = useNavigate();
  const [password, setPassword] = React.useState("");
  const [checkPassword, setCheckPassword] = React.useState("");
  const [showButton, setShowButton] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(true);
  const [showCheckPassword, setShowCheckPassword] = React.useState(true);
  const [step, setStep] = React.useState(1);
  const handleTogglePasswordVisibility = () => setShowPassword(!showPassword);
  const handleToggleCheckPasswordVisibility = () =>
    setShowCheckPassword(!showCheckPassword);

  React.useEffect(() => {
    if (password && password.length === 6) {
      const timer = setTimeout(() => {
        setShowButton(true);
      }, 500);

      return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts or password changes
    } else {
      setShowButton(false);
    }
  }, [password]);

  const onPasswordInputChange = (value) => {
    if (/^\d*$/.test(value) && value.length <= 6) {
      setPassword(value);
      console.log(handleNewPassword);
      handleNewPassword(value);
    }
    if (value.length === 6) {
      setStep(2);
    }
  };

  const onCheckPasswordInputChange = (value) => {
    if (/^\d*$/.test(value) && value.length <= 6) {
      setCheckPassword(value);
      handleNewCheckPassword(value);
    } else {
      return;
    }
  };

  const nextStep = () => {
    setStep(2);
  };
  const setUpPassword = () => {
    if (password !== checkPassword) {
      notifyError("Les mots de passe ne correspondent pas");
    } else {
      valider();
    }
  };

  const handleStepChange = () => {
    switch (step) {
      case 1:
        backFunction();
        break;
      case 2:
        setStep(1);
        break;
      default:
        break;
    }
  };

  return (
    <div className="phonepasswordsignup">
      {/* <BackButton isLogoVisible={true} iconOnly onClick={handleStepChange} /> */}

      {step === 1 && (
        <div>
          <h1 className="mt-[2rem] text-left">
            Choisis ton nouveau mot de passe
          </h1>
          <p className="text-left !mt-6">
            Définis ton mot de passe lié à ton numéro de téléphone.
          </p>
          <div className="mt-6 mailformpayment">
            <div className="flex flex-col items-center w-full">
              <label
                className="mt-2 mb-4 text-2xl !text-center flex items-center gap-3 justify-center"
                htmlFor="passwordSignIn"
              >
                Mot de passe
                <button
                  className="cursor-pointer"
                  onClick={handleTogglePasswordVisibility}
                >
                  {showPassword ? (
                    <Lottie
                      animationData={eye}
                      loop={false}
                      autoplay={true}
                      style={{ width: "20px", height: "20px" }} // Adjust the width and height as needed
                    />
                  ) : (
                    <Lottie
                      animationData={eyeOff}
                      loop={false}
                      autoplay={true}
                      style={{ width: "20px", height: "20px" }} // Adjust the width and height as needed
                    />
                  )}
                </button>
              </label>
              {/*<input*/}
              {/*  placeholder="code à 6 chiffres"*/}
              {/*  value={password}*/}
              {/*  type={showPassword ? 'text' : 'password'} pattern="[0-9]*" inputMode="numeric"*/}
              {/*  onChange={onPasswordInputChange}*/}
              {/*/>*/}
              <OtpInput
                className="OtpInput"
                value={password}
                onChange={(value) => onPasswordInputChange(value)}
                separator={<span className="inline-block min-w-3"></span>}
                separateAfter={1}
                numInputs={6}
                isInputNum={true}
                disabled={false}
                shouldAutoFocus={true}
                inputStyle="password-input"
                isInputSecure={showPassword}
                autoComplete={true}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  type: showPassword ? "password" : "text", // Set type conditionally here
                }}
              />

              {showButton && (
                <div className="w-full mt-8">
                  <PrimaryButton onClick={() => setStep(2)} label={"Suivant"} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <div>
          <h1 className="mt-[2rem] text-left">
            Confirme ton nouveau mot de passe
          </h1>
          <p className="text-left !mt-6">
            Retape ton mot de passe lié à ton numéro de téléphone pour être
            qu’il n’y ait pas d’erreurs.
          </p>
          <div className="mt-6 mailformpayment">
            <div className="flex flex-col items-center w-full">
              {/* <label
                className="flex items-center justify-center gap-3 mt-2 mb-4 text-2xl"
                htmlFor="passwordSignIn"
              >
                Confirmation du mot de passe
                <button onClick={handleToggleCheckPasswordVisibility}>
                  {showCheckPassword ? (
                    <Lottie
                      animationData={eye}
                      loop={false}
                      autoplay={true}
                      style={{ width: "20px", height: "20px" }} // Adjust the width and height as needed
                    />
                  ) : (
                    <Lottie
                      animationData={eyeOff}
                      loop={false}
                      autoplay={true}
                      style={{ width: "20px", height: "20px" }} // Adjust the width and height as needed
                    />
                  )}
                </button>
              </label> */}
              {/*<input*/}
              {/*  placeholder="Retapez le code"*/}
              {/*  value={checkPassword}*/}
              {/*  type={showCheckPassword ? 'text' : 'password'} pattern="[0-9]*" inputmode="numeric"*/}
              {/*  onChange={onCheckPasswordInputChange}*/}
              {/*/>*/}
              <OtpInput
                className="OtpInput"
                value={checkPassword}
                onChange={(value) => onCheckPasswordInputChange(value)}
                separator={<span className="inline-block min-w-3"></span>}
                separateAfter={1}
                numInputs={6}
                isInputNum={true}
                disabled={false}
                shouldAutoFocus={true}
                inputStyle="password-input"
                isInputSecure={showCheckPassword}
                autoComplete={true}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  type: showCheckPassword ? "password" : "text", // Set type conditionally here
                }}
              />
              <div className="w-full mt-8">
                <PrimaryButton onClick={setUpPassword} label={"Continuer"} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
