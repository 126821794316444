import React from 'react';
import pikkocoin from '../../styles/img/Pikkocoin.svg';
import { formatFidelityPoints, formatFidelityPointsFromUser, showFidelityPointToEuro } from '../../utils/StringUtils';

const PikkoPointContainer = ({ user, column, earnPoints,notEuroVersion }) => {
  return (
    <div className="pikko-point-container">
      <div className="pikko-point-title">Mes Points</div>
      <div className={`pikko-point-amount ${column ? "column":""}`}>
        <div className="pikko-point">
          {formatFidelityPointsFromUser(user)}
        </div>
        <img className="picoin-img" src={pikkocoin} alt="Pikkocoin" />
        {!!earnPoints && (
          <div className="earn-point-container">
            <div className="earn-point">+{formatFidelityPoints(earnPoints)}</div>
            <div>sur cette commande</div>
          </div>
        )}
      </div>
    {notEuroVersion &&  <div className="pikkobox-conversion-container">
            <div className="horizontal-bar">
                <span className="bar" />
            </div>
            <span className="price">~{showFidelityPointToEuro(user)} €</span>
            <div className="horizontal-bar">
                <span className="bar" />
            </div>
      </div>}
    </div>
  )
};

export default PikkoPointContainer;
