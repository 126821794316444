import React, { useEffect, useState } from "react";
import OTPInput from "otp-input-react";
import passwordLogoForget from "../../../styles/img/account/passwordLogoForget.svg";
import onNumberSignUp from "./HandleConnectNumber";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../utils/Notify";
import Swal from "sweetalert2";
import OtpInput from "react18-input-otp";

export const SmsConnexion = ({
  code,
  setCode,
  number,
  auth,
  setStep,
  setLoading,
  setForgotPassword,
}) => {
  const [timeLeft, setTimeLeft] = useState(0);
  useEffect(() => {
    setCode("");
    if (localStorage.getItem("lastAttemptDate")) {
      const lastAttemptDate = localStorage.getItem("lastAttemptDate");
      const now = new Date().getTime();
      const diff = now - lastAttemptDate;
      console.log(diff);
      if (diff < 120000) {
        setTimeLeft(Math.floor((120000 - diff) / 1000));
      }
    }
  }, []);

  const captchaRef = React.useRef(null);
  const otpRef = React.useRef(null);

  useEffect(() => {
    let interval = null;
    if (timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timeLeft]);

  const onResendClick = async () => {
    setLoading(true);
    if (timeLeft > 0) {
      notifyWarning(`Attend ${timeLeft}s avant de renvoyer le code`);
      setLoading(false);
      return;
    }

    const result = await onNumberSignUp(
      auth,
      number,
      setStep,
      "invisible",
      captchaRef.current.id
    );
    if (result.success) {
      setStep(2);
      // setForgotPassword(true);
      localStorage.setItem("lastAttemptDate", new Date().getTime());
      notifySuccess("Le code a été renvoyé");
      setTimeLeft(120);
    } else {
      notifyError("Erreur lors de l'envoi du code");
      console.log(result);
    }
    setLoading(false);
  };

  const numberToShow = `${number.slice(0, 3)}${"*"
    .repeat(number.length - 3)
    .slice(0, -2)}${number.slice(-2)}`;

  return (
    <div className="reinitialisation-screen">
      <img
        src={passwordLogoForget}
        alt="pikkoLogo"
        className="pikkoLogo-phone-screen animatedLogo"
      />

      <h1>
        {new Date() - new Date(localStorage.getItem("lastAttemptDate")) > 12
          ? "On vient de t’envoyer un code par SMS."
          : "Entre le code de sécurité que nous t’avons envoyé par SMS."}
      </h1>
      <p>Entre le code de sécurité que nous t’avons envoyé au {numberToShow}</p>

      {/*<OTPInput ref={otpRef} onChange={setCode} className="otpInput" value={code} autoFocus OTPLength={6} otpType="number" disabled={false} inputClassName="otpInputSms" />*/}
      <OtpInput
        ref={otpRef}
        onChange={setCode}
        className="OtpInput"
        value={code}
        separator={<span> </span>}
        separateAfter={1}
        shouldAutoFocus={true}
        numInputs={6}
        isInputNum={true}
        disabled={false}
        isInputSecure={true}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        inputStyle="otpInputSms"
      />

      <button className="otpButton" onClick={onResendClick}>
      Pas reçu de code ?
      </button>
      {timeLeft > 0 && (
        <div className="otpTime">Tu peux renvoyer le code dans {timeLeft}s</div>
      )}

      <div ref={captchaRef} id="captcha"></div>
    </div>
  );
};
