import React, { useEffect, useState } from "react";
import arrow from "../styles/img/arrow/arrow.svg";
import logopikkopay from "../styles/img/logo_pikkopay2.svg";
import addToHome from "../styles/img/addToHome.svg";
import shareicontuto from "../styles/img/shareicon-tuto.svg";
import pikkophone from "../styles/img/pikkophone.svg";
import step1 from "../styles/img/account/step1.png";
import step2 from "../styles/img/account/step2.png";
import chrome_step1 from "../styles/img/account/chrome_step1.png";
import chrome_step2 from "../styles/img/account/chrome_step2.png";
import step3 from "../styles/img/account/step3.png";
import pikkoPhone from "../styles/img/installPwaPhone.svg";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import logoBrand from "../styles/img/logo-tuto.svg";
import tuto from "../styles/img/tuto.gif";
import android_popup from "../styles/img/android_popup.gif";
import clickHand from "../styles/img/clickHand.svg";

import "@splidejs/react-splide/css";
const InstallPWA = ({ isAnim }) => {
  const [promptInstall, setPromptInstall] = useState(null);
  const [iosPopupVisible, setIosPopupVisible] = useState(false);
  const [androidPopupVisible, setAndroidPopupVisible] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent); // Detect Safari browser
    const isAppleDevice = /iPhone|iPad|iPod|Macintosh/i.test(userAgent); // Detect Apple devices

    // Single variable to check if user is on Apple ecosystem
    const isAppleEcosystem = isAppleDevice || isSafari;
    setIsIOS(isAppleEcosystem);

    const handler = (e) => {
      e.preventDefault();
      setPromptInstall(e);
    };

    // Only add the event listener if the device is Android
    if (!isAppleEcosystem) {
      window.addEventListener("beforeinstallprompt", handler);
    }

    // Cleanup function
    return () => {
      if (!isAppleEcosystem) {
        window.removeEventListener("beforeinstallprompt", handler);
      }
    };
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!isIOS && promptInstall) {
      // Show Android PWA install prompt
      promptInstall.prompt();
    } else if (!isIOS) {
      // Show android custom popup
      setAndroidPopupVisible(true);
    } else {
      // Show iOS custom popup
      setIosPopupVisible(true);
    }
  };
  console.log("!isIOS && promptInstall  ", !isIOS && promptInstall);
  console.log("isIOS  ", isIOS);

  return (
    <>
      <div
        className={"install-pwa" + (isAnim ? " tremble" : "")}
        onClick={onClick}
      >
        <img src={clickHand} className="click-hand" alt="" />

        <span className="install-pwa-text">
          Télécharge l’application pour aller encore plus vite
        </span>
        <img src={pikkoPhone} className="install-pwa-phone" alt="" />
      </div>

      {iosPopupVisible && (
        <div className="tuto-pwa-container">
          <div className="logo-tuto">
            <div className="logo-brand">
              <img src={logoBrand} alt="logo-brand" />
            </div>
          </div>
          Installer PikkoPay
          <div className="tuto-pwa">
            <img src={tuto} alt="" />
          </div>
          <div
            className="text-tuto-pwa"
            onClick={() => setIosPopupVisible(false)}
          >
            C'est compris
          </div>
        </div>
      )}

      {androidPopupVisible && (
        <div
          className="android-overlay"
          onClick={() => setAndroidPopupVisible(false)}
        >
          <div className="android-popup-container">
            <img src={android_popup} alt="Android Install Instructions" />
            <div className="text-android-popup">C'est compris</div>
          </div>
        </div>
      )}
    </>
  );
};

export default InstallPWA;
