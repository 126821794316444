import { notifyError, notifyWarning } from '../utils/Notify';
import { getErrorMessage } from '../utils/WsUtils';
import { WsError } from '../constants/wsError';
import { MODIFY_USER_FAIL, MODIFY_USER_REQUEST, MODIFY_USER_SUCCESS } from '../constants/userConstants';

export const identify = (axiosInstance, userInfo, order, fidCardNum, recaptchaResultToken, onSuccess, onFinally) => {
  axiosInstance.post('/apiws/user/identification', {
    userInfo, order, fidCardNum, recaptchaResultToken
  })
               .then(({ data }) => {
                 onSuccess(data);
               })
               .catch((error) => {
                 console.error('Add card failed', error);
                 if (error?.response?.data?.errorCode === 'RECAPTCHA_ASSESMENT_FAIL') {
                   notifyWarning('Attention', WsError.RECAPTCHA_ASSESMENT_FAIL, 'Recharger', () => window.location.reload());
                 } else {
                   notifyError('Erreur', getErrorMessage(error));
                 }
               })
               .finally(onFinally)
}

export const addFidCard = (axiosInstance, userInfo, order, fidCardNum, recaptchaResultToken, onSuccess,setLoading) => async (dispatch, getState) => {
  dispatch({ type: MODIFY_USER_REQUEST })
  console.log('addFidCard', userInfo, order, fidCardNum, recaptchaResultToken);
  axiosInstance.post('/user/add-fidelity-card', {
    userInfo, order, fidCardNum, recaptchaResultToken
  })
               .then(({ data }) => {
                 onSuccess(data)
                 dispatch({ type: MODIFY_USER_SUCCESS, payload: data })
                 localStorage.setItem('user', JSON.stringify(getState().user));
               })
               .catch((error) => {
                 dispatch({ type: MODIFY_USER_FAIL })
                 console.error('Add card failed', error);
                 if (error?.response?.data?.errorCode === 'RECAPTCHA_ASSESMENT_FAIL') {
                   notifyWarning('Attention', WsError.RECAPTCHA_ASSESMENT_FAIL, 'Recharger', () => window.location.reload());
                 } else {
                   notifyError('Erreur', getErrorMessage(error));
                  
                 }
                 if(setLoading){
                  setLoading(false)}
               })
}
