import React from 'react';
import generatePDF from './generatePdf';
import PrimaryButton from '../button/PrimaryButton';

const InvoiceButton = ({ invoiceData }) => {
  const handleDownload = () => generatePDF(invoiceData);

  return (
    <div className="invoice-button">
    <PrimaryButton
    label={'Télécharger la facture'}
     onClick={handleDownload}/>
    </div>
  );
};

export default InvoiceButton;
