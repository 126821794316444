import {createContext, useContext, useReducer} from "react";

const initialState = {
    payzen: {
        initialized: false,
        token: {}
    }
};

export const CheckoutContext = createContext();

const CheckoutReducer = (state, action) => {
    switch (action.type) {
        case 'PAYZEN_SET_TOKEN':
            return {...state, payzen: {...state.payzen, token: action.payload}};
        case 'PAYZEN_MODULE_LOAD':
            return {...state, payzen: {...state.payzen, initialized: action.payload}};
        default:
            throw new Error(`Unknown action: ${action.type}`);
    }
};


export const CheckoutProvider = ({children}) => {
    const [checkoutState, dispatchCheckout] = useReducer(CheckoutReducer, initialState);
    return (
        <CheckoutContext.Provider value={{ checkoutState, dispatchCheckout }}>
            {children}
        </CheckoutContext.Provider>
    )
}

export const useCheckout = () => useContext(CheckoutContext);

