import React from "react";
import buttonBackBlack from "../../styles/img/button/button_back_black.svg";
import buttonBackWhite from "../../styles/img/button/button_back_white.svg";
import logo_and_pikko from "../../styles/img/logo_and_pikko.svg";
import BackBtnIcon from "../../styles/img/back_button_icon.svg";

const BackButton = ({
  onClick,
  isAbsolute,
  isWhite,
  isLogoVisible,
  iconOnly = false,
}) => {
  return (
    <div
      id="back-button"
      style={{ width: "fit-content" }}
      className={`back-button ${isAbsolute ? "absolute" : ""}`}
    >
      {iconOnly ? (
        <img
          src={BackBtnIcon}
          alt="back_btn"
          className="w-14 h-14 back-button-img"
          onClick={onClick}
        />
      ) : (
        <img
          alt="back_btn"
          onClick={onClick}
          src={isWhite ? buttonBackWhite : buttonBackBlack}
          className="back-button-img"
        />
      )}
      {/* {isLogoVisible && (
        <img src={logo_and_pikko} className="back-button-logo" />
      )} */}
    </div>
  );
};

export default BackButton;
