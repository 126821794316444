import { GET_THEME_REQUEST,GET_THEME_SUCCESS,GET_THEME_FAIL } from "../constants/themeConstants";
import { themeDefault } from "../utils/theme_default";

export const getTheme = (axiosInstance) => async(dispatch, getState) => {
    dispatch({type: GET_THEME_REQUEST});
    try {
        const {data} = await axiosInstance.get(`utils/get-themes`)
        dispatch({type: GET_THEME_SUCCESS, payload: data.themes});
        localStorage.setItem('theme', JSON.stringify(getState().theme.theme));
    } catch(err) {
        dispatch({type: GET_THEME_FAIL});
        localStorage.setItem('theme', JSON.stringify(themeDefault));    
        console.log(err)
    }
}