import React, { useEffect, useRef, useState } from "react";
import { BarcodePicker as ScanditSDKBarcodePicker } from "scandit-sdk";

let scannerInstance = null;

const Scanner = (props) => {
  // ref
  // let scanner = useRef(null);
  let reference = useRef(null);
  const scannerInstanceRef = useRef(null);

  // state
  const [scanSetting, setScanSetting] = useState(props.scanSettings);
  const [visible, setVisible] = useState(props.visible);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const createScanner = () => {
      if (!scannerInstance) {
        ScanditSDKBarcodePicker.create(reference.current, props, {})
          .then((barcodePicker) => {
            console.log("created barcode instance");
            scannerInstance = barcodePicker;

            barcodePicker.setZoom(0);
            barcodePicker.setPinchToZoomEnabled(true);

            // saving scanner in ref
            // scanner.current = barcodePicker;

            // console.log(barcodePicker);
            scannerInstanceRef.current = barcodePicker;
            barcodePicker.on("ready", () => {
              console.log("ready event fired");
              setIsLoading(false);
            });
            if (props.onScan != null) {
              barcodePicker.on("scan", props.onScan);
            }
            /*if (props.onError != null) {
            barcodePicker.on('scanError', props.onError);
          }*/
            if (props.pause === true) {
              barcodePicker.pauseScanning();
              barcodePicker.pauseCameraAccess();
            } else {
              barcodePicker.accessCamera(true);
              barcodePicker.resumeScanning();
            }
          })
          .catch((error) => {
            console.error("caught error: ", error);
            if (error.name === "NotAllowedError") {
              // catch an error from create() method
              // and execute needed functions here
              alert("Camera access denied by user!");
            }
          });
      }
    };

    createScanner();

    return () => {
      if (scannerInstance) {
        scannerInstance.pauseScanning();
        scannerInstance.pauseCameraAccess();
      }
    };
  }, [reference]);

  // useEffect(() => {
  //   return () => {
  //     if (scanner.current && scanner.current !== null) {
  //       console.log("destroying scanner");
  //       scanner.current?.destroy();
  //     }
  //   };
  // }, []);

  useEffect(() => {
    console.log("---props pause value", props.pause);
    console.log("--- scanner instance", scannerInstance);
    if (scannerInstance) {
      if (props.pause === true) {
        scannerInstance.pauseScanning();
        // scannerInstance.pauseCameraAccess();
      } else {
        scannerInstance.resumeScanning();
        scannerInstance.accessCamera(true);

        if (reference.current) {
          scannerInstance.reassignOriginElement(reference.current);

          scannerInstance.on("ready", () => {
            setIsLoading(false);
          });

          if (props.onScan != null) {
            scannerInstance.on("scan", props.onScan);
          }
        }
      }
    }
  }, [props.pause, reference]);

  useEffect(() => {
    if (props.scanSettings && scannerInstance) {
      scannerInstance.applyScanSettings(props.scanSettings);
    }

    if (JSON.stringify(scanSetting) !== JSON.stringify(props.scanSettings)) {
      scannerInstance.applyScanSettings(props.scanSettings);
      setScanSetting(props.scanSettings);
    }

    if (visible !== props.visible) {
      scannerInstance.setVisible(props.visible);
      setVisible(props.visible);
    }
  }, [scanSetting, visible, props.scanSettings]);

  return (
    <>
      {isLoading && (
        <>
          <div
            className="absolute z-50 w-screen h-screen "
            // style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
          >
            <div className="z-50 absolute left-2/4 top-[35%] bg-bLue  -translate-x-2/4 ">
              <div class="lds-spinner white">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        ref={reference}
        className="absolute"
        style={{ width: "100%", height: "100%" }}
      />
    </>
  );
};

export default Scanner;
