import React from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";
import CartLength from "./CartLength";
import v from '../styles/img/arrow/v.svg'
import { useNavigate } from "react-router-dom";

const HeaderCart = ({titre,transparent}) => {
  const {prevPage} = useLocation().state || ''
  const user = useSelector((state) => state.user);
  const countItems = CartLength();
  const navigate = useNavigate()
  const backToCart = () => {
    if(prevPage === 'account' || (prevPage ==='invitation' && user && user._id && user._id.length > 0) ) navigate('/scan')
    else if(prevPage === 'home' || prevPage === 'addcard') navigate('/home')
    else navigate(-1)


  }
  

  return (
    <header className={`${transparent? "bg-transparent":"bg-white"}`}>
      {/* <div className='w-full px-10 mt-14 flex items-center gap-20'>
                <Link to='/scan' style={{zIndex:'40'}}>
                    <img src="/images/back2.png" alt="back" className='w-14 h-auto' />
                </Link>

            </div> */}
      <div className={`text-center header_cart ${useLocation.pathname === '/account' ? 'mt-[30px]' : '' }`} >

        <div  className="relative flex justify-center items-center fixed top-10 left-[50%] transform translate-x-[-50%] flex justify-center ">

          <h2 className="text-[24px] geomanistBold">{titre} </h2>
      </div>
      </div>
    </header>)
}


export default HeaderCart;
