import React from 'react';
import search_icon from '../styles/img/search_icon.svg';

const Search = ({ handleSearch }) => {
  return (
   <div className='store_list_searchbar'>
     <div>
        <img src={search_icon} alt="search" className="search_icon" />
    </div>
     <input
     className='store_list_searchbar_input'
      type="text"
      placeholder="Nom du magasin (Carrefour, Intermarch..."
      onChange={(e) => handleSearch(e.target.value)}
    />
   

   </div>
  );
};

export default Search;