import { useRef, useState } from "react";
import HandleGoogle from "../pages/account/login/HandleGoogle";
import eye from "../styles/lottie/eye.json";
import eyeOff from "../styles/lottie/eye-off.json";
import tel from "../styles/img/account/tel.svg";
import logoGoogle from "../styles/img/account/google.svg";
import Lottie from "lottie-react";
import mailLogo from "../styles/img/account/mail.svg";
import OtpInput from "react18-input-otp";
import { CountrySelector } from "react-international-phone";
import "react-international-phone/style.css";
import PrimaryButton from "./button/PrimaryButton";
import closeArrow from "../styles/img/goback_arrow.svg";
import useAxiosInterceptors from "../axios/useAxios";
import { notifyError, notifySuccess } from "../utils/Notify";
import { mergeAccounts } from "../actions/userActions";
import { signInWithEmailAndPassword } from "firebase/auth";
import LoaderWithText from "./loader/LoaderWithText";
import pikkoProfile from "../styles/img/account/pikkoProfile.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
export const Synchronisation = ({auth,user,isVisible,setIsVisible}) => {
    const [synchroLoading, setSynchroLoading] = useState(false);
  const [synchroEmail, setSynchroEmail] = useState("");
    const [synchroPassword, setSynchroPassword] = useState("");
    const axiosInstance = useAxiosInterceptors();   
    const [showPassword, setShowPassword] = useState(false);
    const [isEmailSynchro, setIsEmailSynchro] = useState(true);
    let [number, setNumber] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [country, setCountry] = useState("fr");
    const [dialCode, setDialCode] = useState("33");
    const numberRef = useRef(null);
    const onNumberChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 20) {
            setNumber(value);
        }
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
        }
    const handleGoogle = async () => {
        setSynchroLoading(true);
        if(!user || !user._id){
            setSynchroLoading(false);
          return;}
        localStorage.setItem("fusionFirstAccount", JSON.stringify(user._id));
        if (localStorage.getItem("newuser")) {
          localStorage.removeItem("newuser");
        }
        try {
          await HandleGoogle(auth, user);
        } catch (e) {
            setSynchroLoading(false);
          alert("Une erreur s'est produite");
        }
      }

      const synchronise = async (type) => {
        try {
            setSynchroLoading(true);
            let firebaseUser = {};
            
            // Sauvegarde de l'ID de l'utilisateur pour la fusion
            localStorage.setItem("fusionFirstAccount", JSON.stringify(user._id));
            
            // Validation et construction de l'objet utilisateur selon le type
            if (type === "phoneNumber") {
                if (!number) {notifyError("Veuillez saisir un numéro de téléphone"); return;}
                if (synchroPassword.length !== 6) {notifyError("Veuillez saisir un code de 6 chiffres"); return;}
                if (!dialCode) {notifyError("Veuillez sélectionner un indicatif téléphonique"); return;}
                if(number[0] === "0") {
                    number = number.slice(1);
                }
                firebaseUser = { phoneNumber: `+${dialCode}${number}`, password: synchroPassword };
            
            } else {
                const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                if (!synchroEmail || !emailRegex.test(synchroEmail)) throw new Error("Veuillez saisir un email valide");
                if (!synchroPassword) throw new Error("Veuillez saisir un mot de passe");
    
                // Vérification de l'utilisateur via Firebase
                const checkUser = await signInWithEmailAndPassword(auth, synchroEmail, synchroPassword);
                if (!checkUser) {throw new Error("Email ou mot de passe incorrect");}
                console.log(checkUser);
                firebaseUser = checkUser._tokenResponse
            }
    
            // Fusion des comptes
            const result = await mergeAccounts(axiosInstance, user._id, firebaseUser, type,dispatch,user,navigate);
            
            if (!result?.success) {
                throw new Error(result.error);
            }
    
            // Fusion réussie
            notifySuccess("Fusion des comptes réussie");
            localStorage.removeItem("fusionFirstAccount");
            localStorage.removeItem("newuser");
            localStorage.setItem("popupUser", JSON.stringify(result));
            window.location.reload();
        } catch (error) {
            // Gestion des erreurs et affichage des messages d'erreur
            console.error(error);
            if(error.message.includes("auth/invalid")) {
                notifyError("Email ou mot de passe incorrect");
            } else {    
                notifyError("Une erreur s'est produite");
            }
        } finally {
            // S'assurer que le loading est désactivé
            setSynchroLoading(false);
            localStorage.removeItem("fusionFirstAccount");
        }
    };
    const checkIfIsNumber = (e) => {
        const { value } = e.target;
    // On autorise uniquement les chiffres dans le champ
    if (/^\d*$/.test(value)) {
      setSynchroPassword(value);
    }
    };
 return (
    <div className={`synchro_container bg-gradient2 ${isVisible ? "visible" : ""}`}>
        {synchroLoading && <LoaderWithText text={"Synchronisation en cours..."} />}
        <div className="close_arrow" onClick={() => setIsVisible(false)}>
            <img src={closeArrow} alt="close" />
        </div>
        <img src={pikkoProfile} alt="pikko" className="pikkoProfile" />
        <h3> Mon Pikko Compte </h3>
        <div className={!isEmailSynchro?"choice_synchro tel":"choice_synchro"}>
            <button
            onClick={() => {setIsEmailSynchro(true)}}>
            <img src={mailLogo} alt="telephone" className="h-[20px] w-auto" />
            </button>
            <button onClick={handleGoogle} className="border-none">
                <img
                className="h-[24px] w-auto"
                src={logoGoogle}
                alt="google"
                />
            </button>
            <button onClick={() => {setIsEmailSynchro(false)}}>
                <img
                className="h-[20px] w-auto"
                src={tel}
                alt="telephone"
                />
            </button>
        </div>
             {isEmailSynchro? <div className="groupinput w-full mb-[200px] flex flex-col">
                <div className="inputGroup w-full flex flex-col items-start">
                  <label className="label-synchro m-2 mb-4" htmlFor="emailSignIn">Email</label>
                  <input
                    className="user_mail_input w-full"
                    type="email"
                    id="emailSignIn"
                    placeholder="Ton email"
                    name="email"
                    value={synchroEmail}
                    onChange={(e) => setSynchroEmail(e.target.value)}
                    required
                  

                  />
                </div>
                <div className="inputGroup w-full flex flex-col items-start">
                  <label className="label-synchro m-2 mb-4" htmlFor="passwordSignIn">Mot de passe</label>
                  <input
                 
                    type={showPassword ? 'text' : 'password'}
                    id="passwordSignIn"
                    placeholder="Mot de passe"
                    value={synchroPassword}
                    onChange={(e) => setSynchroPassword(e.target.value)}
                  />
                  <button
                    className="absolute top-[72%] right-8 transform -translate-y-1/2"
                    onClick={handleTogglePasswordVisibility}
                  >
                    {showPassword ?
                      <Lottie
                        animationData={eye}
                        loop={false}
                        autoplay={true}
                        style={{ width: '20px', height: '20px' }} 
                      />
                      :
                      <Lottie
                        animationData={eyeOff}
                        loop={false}
                        autoplay={true}
                        style={{ width: '20px', height: '20px' }} 
                      />
                    }
                  </button>
            </div>
            </div>
            :
            <div className="phone-password-container synchro">
                <label className="label-synchro m-2 mt-4  mb-4" htmlFor="passwordSignIn">
                 Numéro de téléphone
                </label>  
                <div className="inputSms">   
              <div className="PhoneCountryCode">
                <CountrySelector
                  selectedCountry={country}
                  onSelect={({ iso2, dialCode }) => {
                    setCountry(iso2);
                    setDialCode(dialCode);
                  }}
                />
              </div>
              <div className="phoneInputGroup">
                <div>+{dialCode}</div>
                <input
                  autoComplete="tel"
                  value={number}
                  onChange={onNumberChange}
                  ref={numberRef}
                  type="tel"
                  name="tel"
                  className="inputNumber"
                  id="inputNumber"
                />
              </div>
            </div>
            <label className="label-synchro m-2 mb-4" htmlFor="passwordSignIn">Mot de passe</label>

                <div className="inputGroup w-full flex flex-col items-start">
                <input
                    className="user_mail_input w-full"
                    type={showPassword ? 'tel' : 'password'}
                    id="emailSignIn"
                    placeholder="Ton mot de passe de 6 chiffres"
                    name="password"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength={6}
                    value={synchroPassword}
                    onChange={checkIfIsNumber}
                    required
                  

                  />
                    <button
                    className="absolute top-[50%] right-8 transform -translate-y-1/2"
                    onClick={handleTogglePasswordVisibility}
                  >
                    {showPassword ?
                      <Lottie
                        animationData={eye}
                        loop={false}
                        autoplay={true}
                        style={{ width: '20px', height: '20px' }} 
                      />
                      :
                      <Lottie
                        animationData={eyeOff}
                        loop={false}
                        autoplay={true}
                        style={{ width: '20px', height: '20px' }} 
                      />
                    }
                  </button>
                </div>
              
            </div>
            
          
            }

       <div className={!isEmailSynchro?"btn_synchro bottom":"btn_synchro"}>
       <PrimaryButton label={"Synchroniser"} onClick={() => synchronise(isEmailSynchro ? "email" : "phoneNumber")} />
        </div> 
    </div>

 )

}