import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import closeArrow from '../../styles/img/account/closeArrow.svg';
import reload from '../../styles/img/reload.svg';

// Utilisation de forwardRef pour exposer la fonction resetTimer
export const ReloadPage = forwardRef(({ titre, message, onClick, position }, ref) => {
    const [showToaster, setShowToaster] = useState(true);
    const timerRef = useRef(null);
    const lastResetTimeRef = useRef(Date.now()); // Stocke le temps du dernier reset

    // Fonction pour cacher le toaster après 10 secondes
    const hideToasterAfterDelay = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setShowToaster(false);
        }, 10000);
    };

    // Fonction pour réinitialiser le toaster
    const resetTimer = () => {
        setShowToaster(true);
        hideToasterAfterDelay();
        lastResetTimeRef.current = Date.now() + 10000; // Met à jour le temps du dernier reset
    };
    const resetJustTimer = () => {
        clearTimeout(timerRef.current);
        lastResetTimeRef.current = Date.now(); // Met à jour le temps du dernier
        setShowToaster(false);
        console.log("resetJustTimer");
    }

    // Utilisation de useImperativeHandle pour exposer la fonction resetTimer
    useImperativeHandle(ref, () => ({
        resetJustTimer
    }));

    useEffect(() => {
        hideToasterAfterDelay();

        const checkElapsedTime = () => {
            const now = Date.now();
            const elapsedTime = now - lastResetTimeRef.current;

            if (elapsedTime > 7000) {
                console.log("Plus de 7 secondes se sont écoulées depuis le dernier appel à resetTimer");
                resetTimer();
            }
        };

        // Vérifier toutes les secondes si 10 secondes se sont écoulées depuis le dernier reset
        const interval = setInterval(checkElapsedTime, 1000);

        return () => {
            clearTimeout(timerRef.current);
            clearInterval(interval); // Nettoyer l'intervalle lors du démontage du composant
        };
    }, []);

    return (
        <div className={`ReloadPage ${position} ${showToaster ? 'show' : 'hide'}`}>
            <button onClick={() => setShowToaster(false)} className="close">
                <img src={closeArrow} alt="close" />
            </button>
            <div className="content">
                <span>{titre}</span>
                <button onClick={onClick}>
                    <img src={reload} alt="" />
                    {message}
                </button>
            </div>
        </div>
    );
});
