import React from 'react';
import pikkocoin from '../../styles/img/Pikkocoin.svg';

const BrandConversionLabel= ({ brand, fixed }) => {
  return (
    <div className={`brand-conversion-label ${fixed ? 'fixed' : ''}`}>
      <span>{`1€ = ${brand.fidelityConversionRate}`}</span>
      <img className="coin-img" src={pikkocoin} alt="coin" />
    </div>
  );
};

export default BrandConversionLabel;
