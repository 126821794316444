import {useContext, createContext, useEffect, useState, useCallback} from 'react';
import {toast} from "react-toastify";
import {ToastInternet} from "../components/Toast";


const NetworkContext = createContext(null)

export const NetworkProvider = ({children})=>{
  const [isOnline, setOnline] = useState(() =>{
    return navigator.onLine
  })

  const setOnlineToTrue = useCallback(() =>{
    toast.dismiss();
    ToastInternet("internet", "connexion rétablie");
    setTimeout(() => {
      toast.dismiss();
    }, 2000);
    setOnline(true)
  }, [])
  const setOnlineToFalse = useCallback(() =>{
    ToastInternet("noInternet", "Aucune connexion internet");
    setOnline(false)
  }, [])

  useEffect(()=>{
    window.addEventListener('online', setOnlineToTrue)
    window.addEventListener('offline', setOnlineToFalse)

    return ()=>{
      window.removeEventListener('online', setOnlineToTrue)
      window.removeEventListener('offline', setOnlineToFalse)
    }
  }, [setOnlineToTrue, setOnlineToFalse])

  return <NetworkContext.Provider value={{isOnline}}>{children}</NetworkContext.Provider>
}

// Use this hook to check network status in any inner component
export const useNetworkCheck = () => {
  const context = useContext(NetworkContext);
  if(!!!context){
    throw Error("useNetworkCheck must be used inside of NetworkProvider");
  }
  return context;
}
