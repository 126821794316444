import { CONECS_BALANCE_FAIL, CONECS_BALANCE_REQUEST, CONECS_BALANCE_SUCCESS, CONECS_REMOVE_FAIL, CONECS_REMOVE_REQUEST, CONECS_REMOVE_SUCCESS, CONECS_USERINFO_FAIL, CONECS_USERINFO_REQUEST, CONECS_USERINFO_SUCCESS } from "../constants/conecsConstants";


export const conecsReducer = (state = {}, action) => {

    switch (action.type) {

        case CONECS_USERINFO_REQUEST: 
            return { loading: true}
        case CONECS_USERINFO_SUCCESS: 
            action.payload.loading = false
            return action.payload
        case CONECS_USERINFO_FAIL: 
            return {loading: false}

        case CONECS_BALANCE_REQUEST: 
            return {loading: true}
        case CONECS_BALANCE_SUCCESS: 
            if(state.buyerId && state.buyerId.length > 0){
                return {...state, balance: action.payload, loading: false}
            }
            else{
                localStorage.removeItem('Conecs')
            }
        case CONECS_BALANCE_FAIL: 
            return {}

        case CONECS_REMOVE_REQUEST: 
            return{...state}
        case CONECS_REMOVE_SUCCESS: 
            return {}
        case CONECS_REMOVE_FAIL:
            return {}

        default:
            return state;
    }
  };

  