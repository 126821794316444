import React from 'react';
import chevronRight from '../../styles/img/arrow/vector.svg';
import { useSelector } from 'react-redux';
import { STORE_LIST } from '../../constants/routes';
import { useNavigate } from 'react-router';
import { isAllowToChangeStore } from '../../utils/Validator';
import { isAppPWA } from '../../utils/helper-functions';
import { useTheme } from 'styled-components';
const StoreSelectionButton = ({ originPath }) => {
  const navigate = useNavigate();
  const { store } = useSelector((state) => state.store);
  const theme = useTheme();

  const onClick = () => {
    if (isAllowToChangeStore(store)) navigate(STORE_LIST, { state: { prevPage: originPath } });

  };

  if (!store) {
    return (<></>);
  }
  return (
    <div className="store-selection-button relative" onClick={onClick}>
      {theme?.buttonDecorations?.changeStoreButtonDecoration && <img src={theme.buttonDecorations.changeStoreButtonDecoration?.image} alt="halloweee_tree" className="halloweee-tree" />}
      {isAllowToChangeStore(store) &&  (
        <div className="store-action">
          <div>
            Ton magasin favori
          </div>
        </div>
      )}
      <div className="store-detail">
        <div className="store-name-content">
          <img src={store.logo} alt="logo" className="store-img" />
          <div className="store-name">{store.name}</div>
        </div>
        <div className='container-city-chevron'>
        <div className="store-city">{store.city}</div>
        {isAllowToChangeStore(store) && (
          <img className='chevron-bas' src={chevronRight} alt="chevronBottom" />
          )}
        </div>
      </div>
    </div>
  );
};

export default StoreSelectionButton;
