import React, { createRef, useEffect, useRef, useState } from "react";
import * as SDCCore from "scandit-web-datacapture-core";
import * as SDCBarcode from "scandit-web-datacapture-barcode";
import { isAppPWA } from "../utils/helper-functions";

const contextRef = createRef(null);
const barcodeCaptureRef = createRef(null);
const cameraRef = createRef(null);
const viewRef = createRef(null);
const barcodeCaptureOverlayRef = createRef(null);
const viewFinderRef = createRef(null);

const licenseKey = process.env.REACT_APP_SCANDIT_KEY;

const ScannerTest = ({
  onClick,
  onScan,
  pause,
  destroy,
  isViewFinderSquare = false,
  addTopMargin = 0,
  size
}) => {
  // ref
  const scannerRef = useRef(null);
  // const contextRef = useRef(null);
  // const barcodeCaptureRef = useRef(null);
  // const cameraRef = useRef(null);

  // state
  const [isScanLoading, setIsScanLoading] = useState(false);

  // hooks
  const isPWA = isAppPWA();

  useEffect(() => {
    initializeScanner();

    return () => {
      // contextRef.current?.dispose();
      // console.log("---> this is camera", cameraRef.current);
      if (cameraRef.current) {
        cameraRef.current.switchToDesiredState(SDCCore.FrameSourceState.Off);
      }
    };
  }, []);

  useEffect(() => {
    barcodeCaptureRef.current?.setEnabled(!pause);
  }, [pause]);

  useEffect(() => {
    if (destroy && contextRef.current) {
      // contextRef.current.dispose();
      // contextRef.current = null;
    }
  }, [destroy]);

  useEffect(() => {
    if (viewFinderRef.current) {
      let aspectRatio = isViewFinderSquare ? 1.2 : 1.6;
      viewFinderRef.current?.setHeightAndAspectRatio(
        new SDCCore.NumberWithUnit(200, SDCCore.MeasureUnit.Pixel),
        aspectRatio
      );
    }
  }, [isViewFinderSquare]);
  const initializeScanner = async () => {
    setIsScanLoading(true);

    // load only if PWA and not configured
    if (isPWA && !window.isScanditConfigured) {
      await SDCCore.configure({
        licenseKey: licenseKey,
        libraryLocation: new URL(
          "https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.25.0/build/engine/",
          document.baseURI
        ).toString(),
        moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
      });

      window.isScanditConfigured = true;
    }

    if (
      contextRef.current &&
      cameraRef.current &&
      barcodeCaptureRef.current &&
      viewRef.current &&
      barcodeCaptureOverlayRef.current &&
      viewFinderRef.current
    ) {
      continueScanner();
    } else {
      initializeWithNewInstance();
    }
  };

  const initializeWithNewInstance = async () => {
    try {
      console.log("+++ initialize new scanner");

      const context = await SDCCore.DataCaptureContext.create(licenseKey);
      contextRef.current = context;

      const view = await SDCCore.DataCaptureView.forContext(context);
      viewRef.current = view;
      view.connectToElement(scannerRef.current);

      const camera = SDCCore.Camera.default;
      cameraRef.current = camera;
      const cameraSettings = new SDCCore.CameraSettings();
      cameraSettings.preferredResolution = "fullHd";
      await camera.applySettings(cameraSettings);
      context.setFrameSource(camera);
      await camera.switchToDesiredState(SDCCore.FrameSourceState.On);

      const barcodeCaptureSettings = new SDCBarcode.BarcodeCaptureSettings();
      barcodeCaptureSettings.enableSymbologies([
        SDCBarcode.Symbology.Code128,
        SDCBarcode.Symbology.QR,
        SDCBarcode.Symbology.EAN8,
        SDCBarcode.Symbology.EAN13UPCA,
      ]);
      barcodeCaptureSettings.codeDuplicateFilter = 2500;

      const barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(
        context,
        barcodeCaptureSettings
      );
      barcodeCaptureRef.current = barcodeCapture;
      await barcodeCapture.setEnabled(!pause);

      barcodeCapture.addListener({
        didScan: (barcodeCaptureMode, session) => {
          const recognizedBarcodes = session.newlyRecognizedBarcodes;
          if (onScan) {
            onScan({ barcodes: recognizedBarcodes });
          }
        },
      });

      const barcodeCaptureOverlay =
        await SDCBarcode.BarcodeCaptureOverlay.withBarcodeCaptureForView(
          barcodeCapture,
          view
        );
      barcodeCaptureOverlayRef.current = barcodeCaptureOverlay;

      const viewfinder = new SDCCore.RectangularViewfinder(
        SDCCore.RectangularViewfinderStyle.Rounded,
        SDCCore.RectangularViewfinderLineStyle.Bold
      );
      viewFinderRef.current = viewfinder;

      view.scanAreaMargins = new SDCCore.MarginsWithUnit(
        new SDCCore.NumberWithUnit(0, SDCCore.MeasureUnit.Pixel),
        new SDCCore.NumberWithUnit(addTopMargin, SDCCore.MeasureUnit.Pixel),
        new SDCCore.NumberWithUnit(0, SDCCore.MeasureUnit.Pixel),
        new SDCCore.NumberWithUnit(125, SDCCore.MeasureUnit.Pixel)
      );

      let aspectRatio = isViewFinderSquare ? 1.2 : 1.6;
      viewfinder.setHeightAndAspectRatio(
        new SDCCore.NumberWithUnit(size, SDCCore.MeasureUnit.Pixel),
        aspectRatio
      );
      viewfinder.dimming = 0.5;
      viewfinder.orientation = SDCCore.Orientation.PortraitUpsideDown;
      viewfinder.color = SDCCore.Color.fromHex("#F3E226FF");
      await barcodeCaptureOverlay.setViewfinder(viewfinder);
      await view.addOverlay(barcodeCaptureOverlay);

      setIsScanLoading(false);
    } catch (error) {
      console.error("Error initializing scanner:", error);
      setIsScanLoading(false);
    }
  };

  const continueScanner = async () => {
    try {
      console.log("+++++ continue scan using old");

      viewRef.current.connectToElement(scannerRef.current);

      await cameraRef.current.switchToDesiredState(SDCCore.FrameSourceState.On);
      await barcodeCaptureRef.current.setEnabled(!pause);

      barcodeCaptureRef.current.addListener({
        didScan: (barcodeCaptureMode, session) => {
          const recognizedBarcodes = session.newlyRecognizedBarcodes;
          if (onScan) {
            onScan({ barcodes: recognizedBarcodes });
          }
        },
      });

      viewRef.current.scanAreaMargins = new SDCCore.MarginsWithUnit(
        new SDCCore.NumberWithUnit(0, SDCCore.MeasureUnit.Pixel),
        new SDCCore.NumberWithUnit(addTopMargin, SDCCore.MeasureUnit.Pixel),
        new SDCCore.NumberWithUnit(0, SDCCore.MeasureUnit.Pixel),
        new SDCCore.NumberWithUnit(125, SDCCore.MeasureUnit.Pixel)
      );

      setIsScanLoading(false);
    } catch (error) {
      console.error("Error continue scanner:", error);
      setIsScanLoading(false);
    }
  };

  return (
    <>
      {isScanLoading && (
        <div
          className="absolute z-50 w-screen h-screen"
          style={{ backgroundColor: "rgba(0,0,0)" }}
        >
          <div className="z-50 absolute left-2/4 top-[33%] -translate-x-2/4">
            <div className="lds-spinner white">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      )}
      <div
        className="absolute"
        ref={scannerRef}
        style={{
          width: "100%",
          height: "100%",
          zIndex: isPWA && window.location.pathname === '/account/add/fid/scan' ? 0 : undefined
        }}
        onClick={onClick}
      ></div>
    </>
  );
};

export default ScannerTest;
