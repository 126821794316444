import React from 'react';
import { formatFidelityPoints } from '../../utils/StringUtils';
import { formatPrice } from '../../utils/StringUtils';
const PromoAndPointsDisplay = ({ user, item, pikkocoin }) => {
  return (
    <div className='flex gap-2'>
      {!!user._id && formatFidelityPoints(item.fidelity) >= 1 && (
        <div className="earn-point-container">
          <div className="earn-point">
            <img className="coin-img" src={pikkocoin} alt="coin" />
          </div>
          +{formatFidelityPoints(item.fidelity) * item.Qty}
        </div>
      )}
      {(item?.promoPrice >= 0) || item.promoLibelle ? (
        <div className='earn-point-container red-mode'>
          {item?.promoPrice === 0 ? (
            <>
              Offert
            </>
          ) : (
            <>
              - {formatPrice(item.promotion)} €
            </>
          )}
          {item.promoLibelle === "buy one get one" && (
            <>
              1 acheté = 1 offert
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default PromoAndPointsDisplay;
