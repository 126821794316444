import React, { useEffect, useState } from 'react';
import StripeCreditCard from './StripeCreditCard';
import { loadStripe } from '@stripe/stripe-js';
import loadingPayment from '../../styles/lottie/loadingPayment.json'
import { useDispatch } from 'react-redux';
import useAxiosInterceptors from '../../axios/useAxios';
import { removeCB } from '../../actions/userCbActions';
import dell from '../../styles/img/dell.svg'
import PaygreenConecs from '../../payments/Paygreen/PaygreenConecs';
import PaygreenConecsCard from './PaygreenConecsCard';
import { paygreenConecsRemove } from '../../actions/userConecsActions';
import { getEdenredUserinfo, removeEdenred, resetEdenredError } from '../../actions/userEdenredActions';
import { useNavigate } from 'react-router-dom/dist';
import PayzenCreditCard from './PayzenCreditCard';
import { updatePayzenCards } from '../../actions/userActions';
import configBackButton from '../../styles/img/configBackButton.svg';
import addPaymentIcon from '../../styles/img/account/addPayment.svg';
import { FaChevronUp } from 'react-icons/fa';
import PrimaryButton from '../../components/button/PrimaryButton';
import valideIcon from '../../styles/img/account/valide.svg';
import payemntScreenIcon from '../../styles/img/account/paymentScreenIcon.svg';
import Lottie from 'lottie-react';

const PaymentConfig = ({ user, store, handleTabChange }) => {
  let creditCard;

  if (store.paymentGateway === 'stripe') {
    creditCard = user?.creditCard;
  } else if (store.paymentGateway === 'payzen') {
    creditCard = user?.payzenCreditCards;
  } else {
    creditCard = user?.creditCard || [];
  }
  console.log(creditCard);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const axiosInstance = useAxiosInterceptors()
  const [selectedMethod, setSelectedMethod] = useState('selectPaymentMethod');
  const [loadingRemoveCb, setLoadingRemoveCb] = useState(false);
  const [loadingRemoveConecs, setLoadingRemoveConecs] = useState(false);
  const [loadingRemoveEdenred, setLoadingRemoveEdenred] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const redirect_uri = process.env.REACT_APP_EDENRED_REDIRECT_URI_CONFIG
  const edenred_url = process.env.REACT_APP_EDENRED_URL
  const edenred_clientId = process.env.REACT_APP_EDENRED_AUTH_CLIENT_ID

  const edenredUrl = `${edenred_url}/connect/authorize?response_type=code&client_id=${edenred_clientId}&scope=openid%20edg-xp-mealdelivery-api%20offline_access&redirect_uri=${redirect_uri}&state=abc123&nonce=456azerty&acr_values=tenant%3Afr-ctrtku&ui_locales=fr`;
  const openEdenred = () => {
    window.open(edenredUrl, '_blank');
  };
  const handleLocalStorageChange = (event) => {
    if (event.key === 'user') {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      if (currentUser?.edenred?.error) {
        // setEdenredErrorMessage('La connexion à ton compte Edenred a échoué.');
        // resetEdenredError();
      } else {
        dispatch(getEdenredUserinfo());
        // handleCheckboxChange();
        // setEdenredErrorMessage('');
        setIsOpen(false)
      }
    }
  };

  const deletePayzenCreditCard = (payzenCreditCards) => {
    dispatch(updatePayzenCards(payzenCreditCards));
    alert('Rafraichissement de la page pour mettre à jour les paiements');
    window.location.reload();
  };
  const handleDeletePayzenCreditCard = async () => {
    try {
      setLoadingRemoveCb(true)
      if (!user._id) {
        deletePayzenCreditCard([]);
        return;
      }
      await axiosInstance.put('/payzen/delete-card',
                              {
                                paymentMethodToken: user.payzenCreditCards[0].pm,
                                userId: user._id
                              })
                         .then((response) => {
                           deletePayzenCreditCard(response.data.payzenCreditCards);
                         })
                         .catch((error) => {
                           deletePayzenCreditCard(user.payzenCreditCards[0])
                           console.log({ error })
                         });
      setLoadingRemoveCb(false)
    } catch (e) {
      console.log('Erreur delete payzen card', e)
      setLoadingRemoveCb(false)
    }

  }
  useEffect(() => {
    window.addEventListener('storage', handleLocalStorageChange);

    // Nettoyage de l'écouteur d'événement lorsque le composant est démonté
    return () => {
      window.removeEventListener('storage', handleLocalStorageChange);
    };
  }, []);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const methodFromUrl = searchParams.get('method');
    if (methodFromUrl && methodFromUrl.length > 0) {
      setSelectedMethod(methodFromUrl);
    }
  }, [window.location.search]);

  const handleSelect = (method) => {
    setSelectedMethod(method);
    navigate(`?step=2&method=${method}`, { replace: false });
  };

  const handleGoBack = () => {
    if (selectedMethod === 'selectPaymentMethod') {
      handleTabChange(1)
    } else {
      setSelectedMethod('selectPaymentMethod')
      navigate(`?step=2&method=selectPaymentMethod`, { replace: false });
    }
  }

  return (
    <div className="config-container">
      <img className="config-back-button" onClick={handleGoBack} src={configBackButton} alt="back" />

      <div className="appear w-full flex justify-start items-center flex-col ">

        {
          (creditCard && creditCard?.length > 0 && creditCard?.[0].last4 || user.conecs && user.conecs.buyerId || user.edenred && user.edenred.balance) && selectedMethod === 'selectPaymentMethod' ? (
              <>

                <div className="config-title left">
                  Mes cartes ajoutées
                </div>
                <div className="config-subtitle left">
                  Choisis une carte enregistrée ou ajoute une nouvelle carte
                </div>
              </>
            )
            :
            (
              <>
                {selectedMethod === 'selectPaymentMethod' && (
                  <>
                    <div>
                      <img src={payemntScreenIcon} alt="" className="h-40 w-40" />
                    </div>
                    <div className="config-title">
                      Méthode de paiement
                    </div>
                    <div className="config-subtitle">
                      Ajoute une méthode de paiement
                    </div>
                  </>
                )}
              </>
            )
        }
        {user.payzenCreditCards && user.payzenCreditCards?.length > 0 && user.payzenCreditCards?.[0].last4 && selectedMethod === 'selectPaymentMethod' && store.paymentGateway === 'payzen' && (
          <div className="cb-saved-tile">
            <div className="flex w-full">
              <img src={valideIcon} alt="" className="h-14 mr-2" />
              <div className="flex flex-row gap-8 items-center">
                <img className="h-14 rounded-[5px] mr-2 " src={user.payzenCreditCards[0].img} />
                <div className="cb-saved-text">
                  <div className="cb-saved-card-number">.... .... .... {user.payzenCreditCards[0].last4} </div>
                </div>
              </div>
            </div>

            <button
              onClick={() => {
                handleDeletePayzenCreditCard()
              }}
              className="deletepayment "
              style={{ marginLeft: 'auto' }}
            >
              {loadingRemoveCb ? (
                <Lottie
                  animationData={loadingPayment}
                  loop={true}
                  autoplay={true}
                  style={{
                    width: '20px',
                    height: '20px',
                    top: '2.5px',
                    position: 'relative',
                    right: '10px'
                  }}
                />
              ) : (
                <img src={dell} alt="" />
              )}
            </button>
          </div>
        )}
        {creditCard && creditCard?.length > 0 && creditCard?.[0].last4 && selectedMethod === 'selectPaymentMethod' && store.paymentGateway === 'stripe' && (
          <div className="cb-saved-tile">
            <div className="flex w-full">
              <img src={valideIcon} alt="" className="h-14 mr-2" />
              <div className="flex flex-row  gap-8 items-center">
                <img className="h-14 rounded-[5px] mr-2 " src={creditCard[0].img} />
                <div className="cb-saved-text">
                  <div className="cb-saved-card-number">.... .... .... {creditCard[0].last4} </div>
                </div>
              </div>
            </div>

            <button
              onClick={async () => {
                setLoadingRemoveCb(true)
                const result = dispatch(removeCB(user._id, creditCard[0], axiosInstance));
                if (result) setLoadingRemoveCb(false)
              }}
              className="deletepayment"
              style={{ marginLeft: 'auto' }}
            >
              {loadingRemoveCb ? (
                <Lottie
                  animationData={loadingPayment}
                  loop={true}
                  autoplay={true}
                  style={{
                    width: '20px',
                    height: '20px',
                    top: '2.5px',
                    position: 'relative',
                    right: '10px'
                  }}
                />
              ) : (
                <img src={dell} alt="" />
              )}
            </button>
          </div>
        )}
        {user.conecs && user.conecs.buyerId && selectedMethod === 'selectPaymentMethod' && (
          <>
            <div id="paygreen-container" style={{ display: 'none' }} />
            <div className="cb-saved-tile">
              <img src={valideIcon} alt="" className="h-14 mr-2" />
              <div className="h-full flex items-center justify-between ">
                <img src={user.conecs.img} alt="" className="h-14 pr-8" />
                <span className=" text-lg font-semibold bg-red-100  p-1 px-2 rounded-[5px] text-red-600 ">
                    <span className="geomanistBold">{(user.conecs.balance) / 100}</span>€ max
                  </span>
              </div>
              <button
                onClick={async () => {
                  setLoadingRemoveConecs(true)
                  const result = await dispatch(paygreenConecsRemove(user, axiosInstance))
                  if (result) setLoadingRemoveConecs(false)
                }}
                className="deletepayment"
                style={{ marginLeft: 'auto' }}
              >
                {loadingRemoveConecs ? (
                  <Lottie
                    animationData={loadingPayment}
                    loop={true}
                    autoplay={true}
                    style={{
                      width: '20px',
                      height: '20px',
                      top: '2.5px',
                      position: 'relative',
                      right: '10px'
                    }}
                  />
                ) : (
                  <img src={dell} alt="" />
                )}
              </button>
            </div>
          </>
        )}
        {user.edenred && user.edenred.balance && selectedMethod === 'selectPaymentMethod' && (
          <>
            <div
              className="cb-saved-tile"
            >
              <img src={valideIcon} alt="" className="h-14 mr-2" />

              <div className="h-full flex items-center justify-between ">
                <img
                  className="h-14 w-22 mr-2"
                  src={user.edenred.img}
                />
                {console.log(user.edenred)}
                <span className=" text-lg font-semibold bg-red-100  p-1 px-2 rounded-[5px] text-red-600 ">
              <span className="geomanistBold">
                {(user.edenred.balance.amount / 100).toFixed(2).replace('.', ',')}
              </span>
              € max
            </span>
              </div>

              <button
                onClick={async () => {
                  setLoadingRemoveEdenred(true)
                  const result = await dispatch(removeEdenred(axiosInstance, user.edenred.refresh, user))
                  if (result) setLoadingRemoveEdenred(false)
                }}
                className="deletepayment"
                style={{ marginLeft: 'auto' }}
              >
                {loadingRemoveEdenred ? (
                  <Lottie
                    animationData={loadingPayment}
                    loop={true}
                    autoplay={true}
                    style={{
                      width: '20px',
                      height: '20px',
                      top: '2.5px',
                      position: 'relative',
                      right: '10px'
                    }}
                  />
                ) : (
                  <img src={dell} alt="" />
                )}

              </button>
            </div>

          </>
        )}
        {selectedMethod === 'Carte bancaire' && store.paymentGateway === 'stripe' && (
          <StripeCreditCard user={user} axiosInstance={axiosInstance} handleSelect={handleSelect} store={store}
                            openPaymentConfig={setIsOpen} />
        )}
        {selectedMethod === 'Carte bancaire' && store.paymentGateway === 'payzen' && (
          <PayzenCreditCard user={user} axiosInstance={axiosInstance} handleSelect={handleSelect} store={store}
                            openPaymentConfig={setIsOpen} />
        )}
        {selectedMethod === 'Carte restaurant' && (
          <PaygreenConecsCard user={user} store={store} axiosInstance={axiosInstance} handleSelect={handleSelect}
                              openPaymentConfig={setIsOpen} />
        )}
        {selectedMethod === 'selectPaymentMethod' && (
          <>
            <div
              className="payment-method-container ">
              <button className="payment-dropdown-button" onClick={toggleDropdown}>
                <div className="flex items-center">
                  <img src={addPaymentIcon} alt="" className="h-10 w-10 mr-2" />
                  <span className="opacity-50">
                {
                  creditCard && creditCard?.length > 0 && creditCard?.[0].last4 || user.conecs && user.conecs.buyerId || user.edenred && user.edenred.balance ? 'Ajouter un autre moyen de paiement' : 'Ajouter un moyen de paiement'
                }
              </span>
                </div>
                <FaChevronUp className={`transform transition-all ${isOpen ? 'rotate-180' : ''}`} />

              </button>
            </div>

            <div className="payment-options-card">
              <div className={`payment-options ${isOpen ? ' active' : ''}`}>
                {(!creditCard || !creditCard?.length > 0 || !creditCard?.[0].last4) && (
                  <div className="payment-tile" onClick={() => handleSelect('Carte bancaire')}>
              <span>
                Carte bancaire
              </span>
                    <div className="cbimg flex gap-2 no-wrap">
                      <img
                        className="h-14 rounded-[5px]"
                        src="https://storage.googleapis.com/pikkopay_images/webapp/payment/amex.png"
                      />
                      <img
                        className="h-16"
                        src="https://storage.googleapis.com/pikkopay_images/webapp/payment/visa.png"
                      />
                      <img
                        className="h-16"
                        src="https://storage.googleapis.com/pikkopay_images/webapp/payment/mastercard.png"
                      />
                      {store.paymentGateway === 'payzen' && (
                        <img
                          className="h-16 rounded-[5px]"
                          src="https://storage.googleapis.com/pikkopay_images/webapp/payment/swile.png"
                        />
                      )}
                    </div>
                  </div>
                )}
                {(!user.conecs || !user.conecs?.buyerId) && (

                  <div className="payment-tile" onClick={() => handleSelect('Carte restaurant')}>
              <span>
                Carte restaurant
              </span>
                    <div className="cbimg flex gap-2 no-wrap">
                      <img

                        src="https://storage.googleapis.com/pikkopay_images/webapp/payment/bimpli.png"
                      />
                      <img
                        src="https://storage.googleapis.com/pikkopay_images/webapp/payment/sodexo.png"
                      />
                      <img
                        src="https://storage.googleapis.com/pikkopay_images/webapp/payment/up.png"
                      />
                    </div>
                  </div>
                )}
                {(!user?.edenred || !user?.edenred?.balance) && (

                  <button
                    className="payment-tile"
                    onClick={() => openEdenred()}
                  >
              <span>
                Edenred
              </span>
                    <div>
                      <img
                        className="h-14 w-22 mr-2"
                        src="https://firebasestorage.googleapis.com/v0/b/pikkopay.appspot.com/o/Webapp%2Fcart%2FEdenred-Logo.png?alt=media&token=48042cb0-3bea-4bb5-948d-4e629a1ae046"
                      />
                    </div>
                  </button>
                )}
              </div>
            </div>
          </>
        )}


      </div>
      <div className="next-step-absolute-btn">
        {selectedMethod === 'selectPaymentMethod' && (
          <PrimaryButton onClick={() => handleTabChange(3)} label={'Continuer'} />
        )}
      </div>
    </div>
  );
};

export default PaymentConfig;