import { GoogleAuthProvider, signInWithRedirect } from 'firebase/auth';

const HandleGoogle = async(auth, user) => {
    const provider = new GoogleAuthProvider()
    try{
        const result = await signInWithRedirect(auth, provider)
    }
    catch(error){
        console.log(error);
    }
};

export default HandleGoogle;
