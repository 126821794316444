import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EdenredCancel,
  EdenredCapture,
  EdenredConfirm,
  getEdenredUserinfo,
  removeEdenred, resetEdenredError,
  updateToken
} from '../../actions/userEdenredActions';
import { successPaidOrder } from '../../actions/cartActions';
import { addUsedPromo } from "../../actions/userActions";
import dell from '../../styles/img/dell.svg'
import useAxiosInterceptors from '../../axios/useAxios';
import { getErrorMessage } from '../../utils/WsUtils';


const Edenred = ({ balance, onChange, onCheckboxChange, paymentMethod, order, stopPaymentLoading, setEdenredErrorMessage, edenredIsLoading, setIsPaid, resetPaymentMethod}, ref) => {
  "lucasismael@yopmail.com";
  "Edenred2021*";
  "5543";

  const axiosInstance = useAxiosInterceptors();
  const dispatch = useDispatch()
  const redirect_uri = process.env.REACT_APP_EDENRED_REDIRECT_URI
  const edenred_url = process.env.REACT_APP_EDENRED_URL
  const edenred_clientId = process.env.REACT_APP_EDENRED_AUTH_CLIENT_ID

  const url = `${edenred_url}/connect/authorize?response_type=code&client_id=${edenred_clientId}&scope=openid%20edg-xp-mealdelivery-api%20offline_access&redirect_uri=${redirect_uri}&state=abc123&nonce=456azerty&acr_values=tenant%3Afr-ctrtku&ui_locales=fr`;
  const open = () => {
    window.open(url, "_blank");
  };

  const user = useSelector((state) => state.user)
  const edenredInfo = useSelector((state) => state.user.edenred) || {}

  const isChecked = paymentMethod === 'edenred';

  const handleCheckboxChange = () => {
    onCheckboxChange();
  };

    const handleLocalStorageChange = (event) => {
      if (event.key === "user") {
        const currentUser = JSON.parse(localStorage.getItem('user'));
        if (currentUser?.edenred?.error) {
          setEdenredErrorMessage('La connexion à ton compte Edenred a échoué.');
          resetEdenredError();
        } else {
          dispatch(getEdenredUserinfo());
          handleCheckboxChange();
          setEdenredErrorMessage('');
        }
      }
    };

    useEffect(() => {
      if (edenredInfo && edenredInfo.balance &&!(user.conecs && user.conecs.buyerId) && !(user.creditCard && user.creditCard[0] && user.creditCard[0].last4)){
        onCheckboxChange();
      }

      if(edenredInfo && edenredInfo.access_token) {
        dispatch(getEdenredUserinfo());
      }
      window.addEventListener("storage", handleLocalStorageChange);

      // Nettoyage de l'écouteur d'événement lorsque le composant est démonté
      return () => {
        window.removeEventListener("storage", handleLocalStorageChange);
      };
    }, []);


  function parseBalance(amount) {
    const parsedAmout = (amount / 100).toFixed(2).replace(".", ",");
    return parsedAmout;
  }

  const handleSubmit = async(cartItems) => {
    const order_amount = order.promoPrice > 0 ? order.promoPrice : order.itemsPrice;
    const userInfo = {}
    userInfo.email = edenredInfo.username
    EdenredCapture(order_amount, cartItems, axiosInstance)
      .then((response) => {
        const { data } = response;
        updateToken(data);
        EdenredConfirm(
          data.content.authorized_amount,
          data.content.authorization_id,
          order._id,
          order.storeId,
          axiosInstance,
        )
        .then((currentOrder) => {
          setIsPaid(true);
          dispatch(successPaidOrder(currentOrder.data));
          dispatch(addUsedPromo(user, axiosInstance, cartItems));
        })
        .catch((error) => {
          console.error('EdenredConfirm ERROR', error);
          setEdenredErrorMessage(getErrorMessage(error));
          EdenredCancel(data.content.authorized_amount, data.content.authorization_id, axiosInstance)
          .catch((error) => console.error('EdenredCancel failed :', error));
        })
      })
      .catch((error) => {
        console.error('EdenredCapture ERROR', error);
        setEdenredErrorMessage(getErrorMessage(error));
      })
      .finally(stopPaymentLoading);
  }

  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  const handleRemoveEdenred = () => {
    dispatch(removeEdenred(axiosInstance, edenredInfo.refresh, user));
    resetPaymentMethod();
  };

  if (edenredIsLoading) {
    return (
      <div className="flex items-center formcarte text-black px-8 pr-14 w-full">
        <div className="flex text-m items-center text-[16px]" style={{fontFamily: 'Open Sans'}}>Chargement du solde...<img
          className="h-14 px-2"
          src="https://firebasestorage.googleapis.com/v0/b/pikkopay.appspot.com/o/Webapp%2Fcart%2FEdenred-Logo.png?alt=media&token=48042cb0-3bea-4bb5-948d-4e629a1ae046"
        />
        </div>
      </div>
    );
  }

  return (
    <>
      {edenredInfo && edenredInfo.balance ? (
        <>
          <div
            className="flex items-center formcarte text-black px-8 pr-14 w-full"
            onClick={handleCheckboxChange}
          >
            <input
              type="checkbox"
              id="edenredcheck"
              className="absolute"
              checked={isChecked}
              onChange={handleCheckboxChange}
              disabled
            />
             <label htmlFor="edenredcheck" className="edenredlabel mr-4"></label>
            <img
              className="h-14 w-22 mr-2"
              src="https://firebasestorage.googleapis.com/v0/b/pikkopay.appspot.com/o/Webapp%2Fcart%2FEdenred-Logo.png?alt=media&token=48042cb0-3bea-4bb5-948d-4e629a1ae046"
            />
            <span className=" text-lg font-semibold bg-red-100  p-1 px-2 rounded-[5px] text-red-600 ">
              <span className="geomanistBold">
                {parseBalance(edenredInfo.balance.amount)}
              </span>
              € max
            </span>
            <button
              onClick={handleRemoveEdenred}
              className="deletepayment"
              style={{ marginLeft: 'auto' }}
            >
                <img src={dell} alt="" />
            </button>
          </div>

        </>
      ) : (
        <>
          <div
            className="flex items-center formcarte text-black px-8 pr-14 w-full"
            onClick={open}
          >
            <input
              type="checkbox"
              id="edenredcheck"
              className="absolute"
              checked={isChecked}
              onChange={handleCheckboxChange}
              disabled
            />
            <label htmlFor="edenredcheck" className="edenredlabel mr-4"></label>
            <div className="flex text-m items-center text-[16px]" style={{fontFamily: 'Open Sans'}}>Edenred<img
              className="h-14 px-2"
              src="https://firebasestorage.googleapis.com/v0/b/pikkopay.appspot.com/o/Webapp%2Fcart%2FEdenred-Logo.png?alt=media&token=48042cb0-3bea-4bb5-948d-4e629a1ae046"
            />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default forwardRef(Edenred);
