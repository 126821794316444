import {
  USER_CONECS_BALANCE_SUCCESS,
  USER_CONECS_REMOVE_FAIL,
  USER_CONECS_REMOVE_REQUEST,
  USER_CONECS_REMOVE_SUCCESS,
  USER_CONECS_USERINFO_FAIL,
  USER_CONECS_USERINFO_REQUEST,
  USER_CONECS_USERINFO_SUCCESS
} from '../constants/userConstants'
import { WsError } from '../constants/wsError';

function imgConecs(brand) {
  switch (brand) {
    case 'sodexo-pass-france':
      return 'https://play-lh.googleusercontent.com/J1xT2yQyP8GLX1xEyq6EYm5TajAgAL_UnyJZMr2AOH6-FCQYXGZUQb-VI3Fooyneyg'
    case 'up-france-cheque-dejeuner':
      return 'https://www.mpa-pro.fr/resize/650x450/zc/2/f/0/src/sites/mpapro/files/products/d11173.png'
    case 'natixis-intertitres':
      return 'https://umih.fr/export/sites/default/.content/media/image-gallery/Visuels-page/2022/20220126-bimpli/200x200px-bimpli.png_1644223205.png'
    default:
      return
  }

}

export const getConecsUserinfo = (userConecs, user, apiInstance) => async (dispatch, getState) => {
  dispatch({ type: USER_CONECS_USERINFO_REQUEST })
  userConecs.img = imgConecs(userConecs.brand)
  try {
    await apiInstance.post('/user/addConecs', { user, conecs: userConecs });
    dispatch({ type: USER_CONECS_USERINFO_SUCCESS, payload: userConecs });
    localStorage.setItem('user', JSON.stringify(getState().user));
  } catch (err) {
    console.log(err)
    dispatch({ type: USER_CONECS_USERINFO_FAIL, payload: err.message })
  }
}

export const storePaygreenBankCardInfo = (userConecs, user, apiInstance) => async (dispatch, getState) => {
  dispatch({ type: USER_CONECS_USERINFO_REQUEST })
  userConecs.img = imgConecs(userConecs.brand)
  try {
    await apiInstance.post('/user/add-bank-card', { user, conecs: userConecs });
    dispatch({ type: USER_CONECS_USERINFO_SUCCESS, payload: userConecs });
    localStorage.setItem('user', JSON.stringify(getState().user));
  } catch (err) {
    console.log(err)
    dispatch({ type: USER_CONECS_USERINFO_FAIL, payload: err.message })
  }
}

export const getConecsInstrument = (apiInstance, conecs) => async (dispatch, getState) => {
  // getConecsBalance
  console.log(conecs)
  // dispatch({type: CONECS_BALANCE_REQUEST})
  try {
    const { data } = await apiInstance.put('/paygreen/instrument', {
      instrumentId: conecs.instrument
    })
    console.log(data.data.daily_balance)
    dispatch({ type: USER_CONECS_BALANCE_SUCCESS, payload: data.data.daily_balance })
    localStorage.setItem('Conecs', JSON.stringify(getState().conecs));

  } catch (err) {
    console.log(err)
    // dispatch({type: CONECS_BALANCE_FAIL, payload: err.message})
    // removeConecs(conecs, 'manual')
  }
}

export const successRemoveConecs = () => async (dispatch, getState) => {
  dispatch({ type: USER_CONECS_REMOVE_SUCCESS });
  localStorage.setItem('user', JSON.stringify(getState().user));
}

export const failRemoveConecs = () => async (dispatch) => {
  dispatch({ type: USER_CONECS_REMOVE_FAIL });
  localStorage.removeItem('Conecs');
}

export const paygreenCreateBuyer = async (user, apiInstance) => {

  const buyer = await apiInstance.post('/paygreen/create-buyer', {
    firstName: user.name.split(' ').slice(0, -1).join(' '),
    lastName: user.name.split(' ').slice(-1).join(' '),
    email: user.email
  })
  return buyer
}

export const paygreenCreateOrder = (user, amount, shopId, apiInstance) => {
  return apiInstance.post('/paygreen/create-order', {
    buyerId: user.buyerId,
    instrumentId: user.instrument,
    shopId,
    amount: parseFloat(amount).toFixed(2)
  });
}

export const paygreenCapture = async (apiInstance, conecsOrderId, orderId, storeId, userEmail) => {
  return apiInstance.post('/paygreen/capture', {
    conecsOrderId,
    orderId,
    storeId,
    userEmail
  });
}

export const paygreenConecsRemove = (user, axiosInstance) => async(dispatch, getState) => {
  dispatch({ type: USER_CONECS_REMOVE_REQUEST })
  if (!user._id) {
    dispatch({ type: USER_CONECS_REMOVE_SUCCESS });
    localStorage.setItem('user', JSON.stringify(getState().user));
    return true;
  }
  try {
    await axiosInstance.put(`/paygreen/instrument/${user.conecs.instrument}/remove`, { userId: user._id })
    dispatch({ type: USER_CONECS_REMOVE_SUCCESS });
    localStorage.setItem('user', JSON.stringify(getState().user));
    return true
  } catch (e) {
    console.error(WsError.PAYGREEN_REMOVE_ERRROR, e);
    dispatch({ type: USER_CONECS_REMOVE_FAIL });
    localStorage.setItem('user', JSON.stringify(getState().user));
    return true
  }

}

