export const checkButton = (payMethod, user, isPayzenModule) => {
    const stripeCbCheck = !isPayzenModule && user.creditCard && Object.keys(user.creditCard).length && user.creditCard[0] && user.creditCard[0].pm && user.creditCard[0].pm.length > 0;
    const payzenCbCheck = isPayzenModule && user.payzenCreditCards && Object.keys(user.payzenCreditCards).length && user.payzenCreditCards[0]?.pm && user.payzenCreditCards[0].pm.length > 0;

    switch(payMethod){
        case 'cb':
            return stripeCbCheck || payzenCbCheck;
        case 'conecs':
            return (user.conecs && Object.keys(user.conecs).length > 0);

        case 'edenred':
            return (user.edenred && Object.keys(user.edenred).length > 0);
        default :
            return false
    }
}
