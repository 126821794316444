import { FacebookAuthProvider, getAdditionalUserInfo, signInWithRedirect } from 'firebase/auth';
import React from 'react';

const HandleFacebook = async(auth, user) => {

    const provider = new FacebookAuthProvider()
    const result = await signInWithRedirect(auth, provider)
   
};

export default HandleFacebook;