import { EDENRED_BALANCE_FAIL, EDENRED_BALANCE_REQUEST, EDENRED_BALANCE_SUCCESS, EDENRED_REMOVE_FAIL, EDENRED_REMOVE_REQUEST, EDENRED_REMOVE_SUCCESS, EDENRED_USERINFO_FAIL, EDENRED_USERINFO_REQUEST, EDENRED_USERINFO_SUCCESS } from "../constants/edenredConstants";


export const edenredReducer = (state = {}, action) => {

    switch (action.type) {

        case EDENRED_USERINFO_REQUEST: 
            return { loading: true}
        case EDENRED_USERINFO_SUCCESS: 
            action.payload.loading = false
            return action.payload
        case EDENRED_USERINFO_FAIL: 
            return {loading: false}

        case EDENRED_REMOVE_REQUEST:
            return {loading: true}
        case EDENRED_REMOVE_SUCCESS: 
            return {}
        case EDENRED_REMOVE_FAIL:
            return {} 


        case EDENRED_BALANCE_REQUEST:
            return { ...state };
        case EDENRED_BALANCE_SUCCESS:
            return { ...state, balance : action.payload.balance};
        case EDENRED_BALANCE_FAIL:
            return {};
        default:
            return state;
    }
  };

  