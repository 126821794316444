import React from "react";

export const LoaderSize = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  BIG: 'BIG',
};

export const LoaderColor = {
  GREY: 'GREY',
  YELLOW: 'YELLOW',
  BLACK: 'BLACK',
  CLASSIC: 'CLASSIC',
};

const CustomLoader = ({ loaderSize, loaderColor }) => {
  const getSizeClassName = () => {
    if (loaderSize === LoaderSize.BIG) {
      return 'big';
    } else if (loaderSize === LoaderSize.SMALL) {
      return 'small';
    }
  }

  const getColorClassName = () => {
    if (loaderColor === LoaderColor.GREY) {
      return 'grey';
    }
    if (loaderColor === LoaderColor.YELLOW) {
      return 'yellow';
    }
    if (loaderColor === LoaderColor.BLACK) {
      return 'black';
    }
  }

  return (
    <div className="loader-container">
      <div className={`loader-custom ${getSizeClassName()} ${getColorClassName()}`} />
    </div>
  );
};

export default CustomLoader;
