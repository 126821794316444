import React from 'react';
import profileImage from '../../styles/img/account/profile-image.svg';
const ProfilBox = ({ click, user }) => {

    if (!user || !user._id) {
      return (<></>);
    }

    const hasFirstNameAndLastName = !!user.prenom && !!user.nom;
    const displayName = hasFirstNameAndLastName ? `${user.prenom} ${user.nom}` : user.email;

    return (
      <div className="profil-box ">
        <div className="profil-box-button " onClick={click}>
          <img className="" src={profileImage} />
          <div className='profil-text'>
            <div className='profil-box-second-text'>
              Mon pikko Compte
            </div>
            <div className={`profil-box-name ${hasFirstNameAndLastName ? '' : 'email'}`}>
              {displayName}
            </div>
          </div>

        </div>
      </div>
    );
  }
  export default ProfilBox;
