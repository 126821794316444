export const WsError = {
   // Stripe // TODO : metttre à jour avec le backend
  STRIPE_PAYMENT_INTENT_ERROR: 'Une erreur est survenue lors de ton paiement. Nous t\'invitons à réessayer.',
  STRIPE_PAYMENT_INTENT_STATUS_ERROR: 'La tentive de paiement a échouée.',
  STRIPE_PAYMENT_CONFIRM_ERROR: 'Une erreur est survenue lors de ton paiement. Nous t\'invitons à réessayer.',
  STRIPE_PAYMENT_CONFIRM_STATUS_ERROR: 'La confirmation du paiement a échoué.',
  STRIPE_ADD_PAYMENT_METHOD_ERROR: 'L\'ajout de la méthode de paiement a échoué.',
  ADD_CARD_ERROR: 'L\'ajout de carte n\'a pas fonctionné.',

  // Stripe payment error code
  STRIPE_DEFAULT_ERROR: 'Paiement échoué.', // not in stripe
  STRIPE_CARD_DECLINED_ERROR: 'Carte invalide.',
  STRIPE_EXPIRED_CARD_ERROR: 'Carte expirée.',
  STRIPE_INCORRECT_CVC_ERROR: 'Le CVC est incorrecte.',
  STRIPE_PROCESSING_ERROR: 'Paiement échoué.',
  STRIPE_INCORRECT_NUMBER_ERROR: 'Le numéro de carte est incorrecte.',

  // Stripe payment decline error code
  STRIPE_GENERIC_DECLINE_ERROR: 'Carte invalide.',
  STRIPE_INSUFFICIENT_FUNDS_ERROR: 'Fonds insuffisants.',
  STRIPE_LOST_CARD_ERROR: 'Carte notifiée comme perdue.',
  STRIPE_STOLEN_CARD_ERROR: 'Carte notifiée comme volée.',
  STRIPE_VELOCITY_EXCEEDED_ERROR: 'Paiement échoué.',
  STRIPE_TRANSACTION_NOT_ALLOWED: 'Paiement refusé par la banque. Vérifie les modalités de paiement de ta carte/banque',

  // Edenred
  EDENRED_BALANCE_ERROR: 'Une erreur est survenue durant le chargement de ton solde Edenred',
  EDENRED_CALL_ERROR: 'Une erreur est survenue durant le chargement d\'Edenred',
  EDENRED_SECOND_CALL_ERROR: 'Une erreur est survenue durant le chargement d\'Edenred',
  EDENRED_TIMEOUT: 'Le service est indisponible. Réessaye plus tard.',
  EDENRED_REFRESH_ERROR: 'EDENRED_REFRESH_ERROR',
  EDENRED_EXPIRED_SESSION: 'Ta session Edenred est expirée. Nous t\'invitons à te reconnecter.',

  // Paygreen
  PAYGREEN_CREATE_ORDER_ERROR: 'PAYGREEN_CREATE_ORDER_ERROR',
  PAYGREEN_CREATE_BUYER_ERROR: 'PAYGREEN_CREATE_BUYER_ERROR',
  PAYGREEN_CAPTURE_ERROR: 'PAYGREEN_CAPTURE_ERROR',
  PAYGREEN_PAYMENT_ERROR: 'PAYGREEN_PAYMENT_ERROR',
  PAYGREEN_PAYMENT_FAIL: 'PAYGREEN_PAYMENT_FAIL',
  PAYGREEN_TOKEN_FAIL: 'PAYGREEN_TOKEN_FAIL',
  PAYGREEN_GLOBAL_FAIL: 'PAYGREEN_GLOBAL_FAIL',
  PAYGREEN_INSTRUMENT_DELETE_ERROR: 'PAYGREEN_INSTRUMENT_DELETE_ERROR',
  PAYGREEN_INSTRUMENT_ERROR: 'PAYGREEN_INSTRUMENT_ERROR',
  PAYGREEN_CALL_ERROR: 'Paiement échoué',
  PAYGREEN_SECOND_CALL_ERROR: 'PAYGREEN_SECOND_CALL_ERROR',
  PAYGREEN_INSUFFICIENT_FUNDS_ERROR: 'Fonds insuffisants.',
  PAYGREEN_MINIMUM_AMOUNT_ERROR: 'Montant minimum non atteint (1€)',
  PAYGREEN_INFORMATION_MISSING: 'Des informations sont manquantes',
  PAYGREEN_INIT_ERRROR: 'Erreur à l\'initialisation de Paygreen.',
  PAYGREEN_REMOVE_ERRROR: 'PAYGREEN_REMOVE_ERRROR',

  //Payzen


  // Order
  ORDER_NOT_FOUND: 'La commande est introuvable.',
  ORDER_ALREADY_PAID: 'Ta commande a déjà été payée.',
  PAY_ORDER_ERROR: 'Ta commande ne s\'est pas mis à jour.',

  // Store
  STORE_NOT_FOUND: 'Le magasin est introuvable.',

  // API
  API_NOT_AVAILABLE: 'Serveur indisponible, nous t\'invitons à réessayer plus tard',
  API_CALL_ERROR: 'API_CALL_ERROR',
  API_SECOND_CALL_ERROR: 'API_SECOND_CALL_ERROR',
  API_REFRESH_ERROR: 'API_REFRESH_ERROR',
  API_NUM_FID_ERROR: 'Numéro de fidélité introuvable',

  // Recaptcha
  RECAPTCHA_INSUFFICIENT__SCORE: 'Activité suspecte détectée. Nous t\'invitons à recharger la page et à recommencer l\'opération.',
  RECAPTCHA_ASSESMENT_FAIL: 'La vérification ReCaptcha a échoué. Nous t\' invitions à recharger la page et à recommencer.',

  // Generic
  DEFAULT_ERROR: 'Une erreur est survenue',
  UNHANDLED_ERROR: 'Erreur non gérée',
};
