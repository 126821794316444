import React, { useEffect, useRef, useState } from "react";
import pikkodefault from "../styles/img/logo/pikkoAnimation.json";
import { useNavigate } from "react-router-dom";
import pikkoProfile from "../styles/img/pikko-profile.png";
import logoBrand from "../styles/img/logo-brand.png";
import questionMark from "../styles/img/question-mark.svg";
import arrow from "../styles/img/arrow/arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import PopupSample from "../components/Popup_sample";
import { CART_CREATE_PARTIAL_RESET } from "../constants/cartConstants";
import { createCart } from "../actions/cartActions";
import useAxiosInterceptors from "../axios/useAxios";
import {
  ACCOUNT,
  HOME,
  NUMBER_SCREEN,
  SCAN,
  STORE_LIST,
  TICKETS,
} from "../constants/routes";
import cocoLeaf from "../styles/img/feuillecocotier.svg";
import StoreSelectionButton from "../components/button/StoreSelectionButton";
import PrimaryButton from "../components/button/PrimaryButton";
import scanIcon from "../styles/img/Scan_icon.svg";
import packageInfo from "../../package.json";
import SwipperTuto from "../components/Swipper";
import step1 from "../styles/img/tuto/step1.svg";
import step2 from "../styles/img/tuto/step2.svg";
import step3 from "../styles/img/tuto/step3.svg";
import InvoiceButton from "../components/facture/dowloadPdf";
import { ReusePreviousOrder } from "../components/card/ReusePreviousOrder";
import { useUserLoginStatus } from '../utils/hooks';
import { isAppPWA } from "../utils/helper-functions";
import { FooterAccount } from "../components/footerAccount";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScanButton from '../components/button/ScanButton';
import { notifyInfo } from '../utils/Notify';
import Lottie from "lottie-react";
import confetti from "canvas-confetti";
import pikkoHalloween from "../styles/img/logo/pikko-halloween.json";
import toile1 from "../styles/img/halloween/toile1.svg";
import toile2 from "../styles/img/halloween/toile2.svg";
import { useTheme } from 'styled-components';
import { LottieLogo } from "../utils/LottieLogo";


const WelcomeScreen = () => {
  const [playAnimation, setPlayAnimation] = useState(false);
  const { store } = useSelector((state) => state.store);
  const { cartItems, success, loading } = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  // const { theme } = useSelector((state) => state.theme);
  const theme = useTheme()
  const axiosInstance = useAxiosInterceptors();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPopup, setShowPopup] = useState(false);
  const [reset, setReset] = useState(false);
  const localStorageOrder = localStorage.getItem("previousOrder")
    ? JSON.parse(localStorage.getItem("previousOrder"))
    : null;
  console.log(localStorageOrder);
  const isLogged = user?._id;
  const isPWA = isAppPWA();
  const isUser = useUserLoginStatus()
  const [selectedItem, setSelectedItem] = useState(2);

  useEffect(() => {
    dispatch({ type: CART_CREATE_PARTIAL_RESET });
    setReset(true);

    if (!store || !store.name) {
      navigate(STORE_LIST);
    }
  }, []);
  function lancerConfettis() {
    console.log("lancerConfettis");
    return new Promise((resolve, reject) => {
      let scalar = 5;
      let unicorn = confetti.shapeFromText({text: theme.confettis, scalar, fontFamily: 'Apple Color Emoji' });
      let defaults = {
        spread: 300,
        ticks: 400,
        gravity: 1,

        flat: false,
        drift: -1,
        particleCount: 120,
        origin: { y: -0.1, x: 0.8 },
        startVelocity: -35,
        shapes: [unicorn],
        scalar
      };
      confetti({

                 ...defaults,

               });

      setTimeout(resolve, 2000);
    });
  }
  useEffect(() => {
    if (success && !cartItems.isPaid && reset) {
      // navigate(SCAN);
    }
  }, [success]);
  useEffect(()=>{
    console.log(theme)
    if(theme && theme.confettis) lancerConfettis()
  },[theme])

  const deployScan = () => {
    if (cartItems) {
      if (cartItems.isPaid) {
        const orderCreate = { storeId: store.id, clientId: user?._id || "" };
        try {
          dispatch(createCart(orderCreate, axiosInstance));
        } catch (e) {
          console.log(e);
        }
      } else {
        navigate(SCAN);
      }
    } else {
      notifyInfo('Il faut d\'abord choisir un magasin 😉')
      navigate(STORE_LIST)
    }
  };

  const handleTicketbutton = () => {
    if (user && user._id && user._id.length > 0) {
      navigate(TICKETS);
    } else {
      setShowPopup(true);
    }
  };
  const tutoRef = useRef();
  const closepopup = () => {
    setShowPopup(false);
  };
  const [TutoCurrentIndex, setTutoCurrentIndex] = useState(0);
  const [view, setView] = useState(false);
  const onClickHelp = () => {
    setView(!view);
    setTutoCurrentIndex(0);
  };

  const onRedirectAccount = () => {
    localStorage.setItem('PREV_LOGIN_PAGE', HOME);
    navigate(NUMBER_SCREEN , { state: { prevPage: HOME } });
  };
 const pikkoAnim =useRef()
 const onClickNext = () => {
    if(isPWA) {
      navigate(SCAN);
    }
    else {
      navigate(ACCOUNT, { state: { prevPage: HOME } });
    }
  }

  return (
    <>
      {loading || !store || !store.name ? (
        <>
          <div
            className="absolute top-0 w-screen h-screen "
            style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
          >
            <div className="absolute  left-2/4 top-[25%]  -translate-x-2/4 ">
              <div className="lds-spinner white">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {/*{localStorageOrder &&*/}
          {/*  localStorageOrder?.orderItems &&*/}
          {/*  localStorageOrder?.orderItems[0]?.storeId ===*/}
          {/*    cartItems?.storeId && (*/}
          {/*    <ReusePreviousOrder*/}
          {/*      axiosInstance={axiosInstance}*/}
          {/*      order={localStorageOrder}*/}
          {/*      id={cartItems?._id}*/}
          {/*    />*/}
          {/*  )}*/}
          {showPopup ? (
            <PopupSample
              Title="Pikko Compte"
              Text="Crée ton compte PikkoPay et
          accède à de nombreux avantages !"
              List={[
                "Historique des tickets",
                "Points de fidélité",
                "promotions exclusives",
              ]}
              Button="Me connecter / M'inscrire"
              click={() => navigate(NUMBER_SCREEN)}
              close={closepopup}
            />
          ) : null}
          <div className={`welcome_screen_principal_container ${isUser ? 'bg_background' : 'bg-gradient2'}${isPWA ? ' pwa' : ''}`}          >
            <img src={theme?.screenDecorations?.homeDecorationTop?.image} alt="" className="coco-leaf-right" style={theme?.screenDecorations?.homeDecorationTop?.style} />
            <img src={theme?.screenDecorations?.homeDecorationTop?.image} alt="" className="coco-leaf-left" style={theme?.screenDecorations?.homeDecorationTop?.style} />
            <div className="question-mark" onClick={onClickHelp}>
              <img src={questionMark} alt="" />
            </div>
            <SwipperTuto
              ref={tutoRef}
              close={onClickHelp}
              TutoCurrentIndex={TutoCurrentIndex}
              setTutoCurrentIndex={setTutoCurrentIndex}
              view={view}
              slides={[
                <div key="1" className="tuto_box">
                  <div className="tuto-container">
                    <img src={step1} alt="step1" />
                    <p className="tuto-bold">Scanne</p>
                    <div className="tuto-text">
                      <span className="tuto-title">
                        Scanne les codes barres de tes articles pour les ajouter
                        au panier
                      </span>
                    </div>
                  </div>
                </div>,
                <div key="2" className="tuto_box">
                  <div className="tuto-container">
                    <img src={step2} alt="step2" />
                    <p className="tuto-bold">Paye</p>
                    <div className="tuto-text">
                      <span className="tuto-title !max-w-[300px]">
                      Payes ton Pikko achat directement
                      sur le smartphone sans faire de queue
                      </span>
                    </div>
                  </div>
                </div>,
                <div key="3" className="tuto_box">
                  <div className="tuto-container">
                    <img src={step3} alt="step3" />
                    <p className="tuto-bold">Valide</p>
                    <div className="tuto-text">
                      <span className="tuto-title !max-w-[300px]">
                      Valide et dirige-toi vers la sortie express pour scanner le QR code de sortie.
                      </span>
                    </div>
                  </div>
                </div>,
              ]}
            />

            <div className={isUser ? "bg-gradient2 welcome_screen_secondary_container" : "bg_background welcome_screen_secondary_container"}>
              <div>
                <div className="logo_and_welcome_text_container">
                  {/* {isLogged ? (
                    <div className="pikko-profile">
                      <img src={pikkoProfile} alt="pikko-profile" />
                      <span className="pikko-mail">{user.email}</span>
                    </div>
                  ) : ( */}
                    <>
                      {/* <object
                      className="pikko-animation"
                      data={pikkopay_logo}
                      type="image/svg+xml"
                    /> */}
                        <div className="flex-box" >

                        <Lottie
                          loop={LottieLogo[theme?.name]?.loop}
                          lottieRef={pikkoAnim}
                          autoPlay={true}
                          style={{ maxWidth: '100px', height: '30dvh', transform: "translateY(20%)",width:"100%"}}
                          animationData={LottieLogo[theme?.name]?.image}
                          onAnimationEnd={() => setPlayAnimation(false)}
                        />

                      </div>

                    </>
                  {/* )} */}

                  <div className="welcome_text_container">

                   {isLogged?
                    <span className="welcome_text_container_bold">
                    Ravis de te revoir
                    sur PikkoPay !
                    </span>
                   :
                    <span className="welcome_text_container_bold">
                      Bienvenue sur
                      PikkoPay
                    </span>}
                    {/* {!isLogged && (
                      <span className="welcome_text_container_light">
                        L’application qui te fait gagner du temps à la caisse
                      </span>
                    )} */}
                  </div>
                </div>
                <StoreSelectionButton originPath={HOME} />
              </div>
                  <FooterAccount selectedItem={selectedItem} setSelectedItem={setSelectedItem} />

              <div className={isPWA?"connection_or_invitation pwa":"connection_or_invitation"}>
                {" "}
                {user && user._id && user._id.length > 0 ? (
                  <>
                  {/*  <PrimaryButton*/}
                  {/*  onClick={onClickNext}*/}
                  {/*  label="Voir mes points et scanner"*/}
                  {/*  icon={scanIcon}*/}
                  {/*/>*/}
                    <ScanButton
                      onClick={onClickNext}
                      label={isAppPWA() ? 'Scanner' : 'Voir mes points et scanner'}
                      store={store}
                      editSelectedStore={() => navigate(STORE_LIST, { state: { prevPage: HOME } })}
                    />
                  <FooterAccount selectedItem={selectedItem} setSelectedItem={setSelectedItem} /></>


                ) : (
                  <>
                    {/*  {store?.id !== '65e6388eb6667e3400b5b8d8' && (*/}
                    {/*   <PrimaryButton*/}
                    {/*    onClick={onRedirectAccount}*/}
                    {/*    label="Me connecter"*/}
                    {/*    />*/}
                    {/*)}*/}
                    <span className="app-version">v{packageInfo.version}</span>

                    <PrimaryButton
                      onClick={onRedirectAccount}
                      label="Me connecter"
                    />
                    <button className="deploy_button" onClick={deployScan}>
                      Mode invité
                      <img src={arrow} />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

        </>
      )}

    </>
  );
};

export default WelcomeScreen;
