export const vidercache = async () => {
    // Efface dans le indexDB
     window.indexedDB.deleteDatabase('/scandit_sync_folder_preload');
     window.indexedDB.deleteDatabase('/scandit_sync_folder')
     localStorage.removeItem('scandit-device-id')
     //supprime les cookies
     
     document.cookie.split(";").forEach((c) => {
      //delete the cookie by deleting it from the browser
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
   });
    sessionStorage.clear();
    caches.keys().then(function(names) {
      for (let name of names)
          caches.delete(name);
  });
  window.location.reload()
 ;}