import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { formatPriceWithEuro } from '../../utils/StringUtils';
import { getOrderAmount } from '../../utils/PriceUtils';

const generatePDF = (invoiceData) => {
  function arrondirDeuxDecimales(nombre) {
    console.log(nombre)
    let nombreStr = nombre.toFixed(3);
    let partieEntiere = nombreStr.split(".")[0];
    let partieDecimale = nombreStr.split(".")[1] || '000';
    let entier = parseInt(partieEntiere);
    let decimale = parseInt(partieDecimale.slice(0, 2));
    let troisiemeDecimale = parseInt(partieDecimale[2]);
    if (troisiemeDecimale >= 5) {
        decimale += 1;
        if (decimale === 100) {
            entier += 1;
            decimale = 0;
        }
    }
    let resultat = `${entier}.${decimale.toString().padStart(2, '0')}`;
    return parseFloat(resultat).toFixed(2);
  }

// Exemple d'utilisation
console.log(arrondirDeuxDecimales(12.456)); // Affiche 12.46
  const store = invoiceData.store;
  const cartItems = invoiceData.cartItems
  const totalPrice = formatPriceWithEuro(getOrderAmount(cartItems))
  function calculateTvaTotals(items) {
    console.log('CALCUL TVA')
    const tvaTotals = {};

    items.forEach(item => {
      const itemPrice = item.promoPrice || item.price;

      const tvaKey = `SommetvaCode${item.tvaCode}`;
      const total = item.Qty * itemPrice;
      if (tvaTotals[tvaKey]) {
        tvaTotals[tvaKey] += total;
      } else {
        tvaTotals[tvaKey] = total;
      }
    });

    return tvaTotals

  };
  const tvaResult = calculateTvaTotals(cartItems.orderItems)
  const tva3 = tvaResult.SommetvaCode3 || 0 //5.5
  const tva5 = tvaResult.SommetvaCode5 || 0 //20
  const tva6 = tvaResult.SommetvaCode6 || 0 //10
  const tva7 = tvaResult.SommetvaCode7 || 0 //2.1
  const doc = new jsPDF();
  doc.setFontSize(18);
  doc.text(`Magasin: ${store.name}`, 14, 14);
  doc.setFontSize(11);
  doc.text(`Adresse: ${store.address}`, 14, 20);
  doc.setFontSize(18);
  doc.text('Facture', 14, 30);
  doc.setFontSize(11);
  doc.text(`Date: ${cartItems.paidAt.replace('T', ' ').replace('Z', '')}`, 14, 38);
  doc.text(`Facture N°: ${cartItems.externalInvoiceId}`, 14, 45);

  autoTable(doc, {
    startY: 50,
    head: [['Nom', 'Quantité', 'Prix Unitaire', 'Prix remisé']],
    body: cartItems.orderItems.map(item => [item.name, item.Qty, formatPriceWithEuro(item.price), item?.promoPrice ? formatPriceWithEuro(item.promoPrice) : formatPriceWithEuro(item.price)]),
  });
  doc.text(`Méthode de paiement: ${cartItems.paymentMethod}`, 14, doc.autoTable.previous.finalY + 10);
  // doc.text('Montant soummis à la TVA', 14, doc.autoTable.previous.finalY + 18);
  // doc.text(`TVA à 2,10%: ${formatPriceWithEuro(tva7)}`, 14, doc.autoTable.previous.finalY + 24);
  // doc.text(`TVA à 5,50%: ${formatPriceWithEuro(tva3)}`, 14, doc.autoTable.previous.finalY + 30);
  // doc.text(`TVA à 10,00%: ${formatPriceWithEuro(tva6)}`, 14, doc.autoTable.previous.finalY + 36);
  // doc.text(`TVA à 20,00%: ${formatPriceWithEuro(tva5)}`, 14, doc.autoTable.previous.finalY + 42);
  // doc.text('Calcul de la TVA', 100, doc.autoTable.previous.finalY + 18);
  // doc.text(`TVA à 2,10%: ${formatPriceWithEuro(arrondirDeuxDecimales((tva7*2.1)/100))}`, 100, doc.autoTable.previous.finalY + 24);
  // doc.text(`TVA à 5,50%: ${formatPriceWithEuro(arrondirDeuxDecimales((tva3*5.5)/100))}`, 100, doc.autoTable.previous.finalY + 30);
  // doc.text(`TVA à 10,00%: ${formatPriceWithEuro(arrondirDeuxDecimales((tva6*10)/100))}`, 100, doc.autoTable.previous.finalY + 36);
  // doc.text(`TVA à 20,00%: ${formatPriceWithEuro(arrondirDeuxDecimales((tva5*20)/100))}`, 100, doc.autoTable.previous.finalY + 42);
   doc.text('Calcul de la TVA', 14, doc.autoTable.previous.finalY + 18);
  doc.text(`TVA à 2,10%: ${formatPriceWithEuro(arrondirDeuxDecimales((tva7*2.1)/102.1))}`, 14, doc.autoTable.previous.finalY + 24);
  doc.text(`TVA à 5,50%: ${formatPriceWithEuro(arrondirDeuxDecimales((tva3*5.5)/105.5))}`, 14, doc.autoTable.previous.finalY + 30);
  doc.text(`TVA à 10,00%: ${formatPriceWithEuro(arrondirDeuxDecimales((tva6*10)/110))}`, 14, doc.autoTable.previous.finalY + 36);
  doc.text(`TVA à 20,00%: ${formatPriceWithEuro(arrondirDeuxDecimales((tva5*20)/120))}`, 14, doc.autoTable.previous.finalY + 42);
  doc.text(`Total TTC: ${totalPrice}`, 14, doc.autoTable.previous.finalY + 62);


  const blob = doc.output('blob');
  const url = URL.createObjectURL(blob);
  window.open(url, '_blank');
};

export default generatePDF;
